<app-mainscreen>
    <div class="view_box_container rounded-bottom1-0">
        <div class="">
            <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                    <div class="view_div">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="view_block">
                                    <ul>
                                        <li>
                                            <img src="../../../../assets/images/user.png" />
                                        </li>
                                        <li>
                                            <p><b>{{customerFormControl['firstName'].value}} {{customerFormControl['lastName'].value}}</b></p>
                                            <p>Customer ID : {{customerFormControl['userId'].value}} </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="view_block">
                                    <label><img src="../../../../assets/images/document.png" /> &nbsp; Contract Status : </label> <span class="status">{{customerFormControl['isActive'].value == '1' ? 'Active' : 'Inactive'}}</span> <br>
                                    <label><img src="../../../../assets/images/watch_later.png" /> &nbsp;Last Login : </label> <span>{{customerFormControl['lastLogin'].value }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <!-- <div class="view_buttons">
                        <button class="edit_button"><img src="../../../../assets/images/message.png" />&nbsp; Lorem </button>
                       
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <div class="tab_div bottom-shadow">
        <ul class="align-items-center p-3 list-item-container-view">
            <li class="list-item" [ngClass]="{'active': activeTab == 1}" (click)="changeTab(1)">
                Customer Details
            </li> 
            <li class="list-item" [ngClass]="{'active': activeTab == 2}" (click)="changeTab(2)">
                Policy Purchased
            </li>        
          </ul>
    </div>
    <div class="tab_content">
        <div id="comprehensive" class="w-100" [ngClass]="{'d-block': activeTab == 1,'d-none' : activeTab !=1}">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="tab_box_container">
                        <div class="row">
                            <div class="col-12">
                                <ul>
                                    <li><h4>Customer Information</h4></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> cake </span> &nbsp; Date of Birth</p><span class="label_13_grey2">{{customerFormControl['dob'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> transgender </span> &nbsp; Gender</p><span class="label_13_grey2">{{customerFormControl['gender'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> call </span> &nbsp; Contact Number</p><span class="label_13_grey2">{{customerFormControl['phoneNumber'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> mail </span> &nbsp; Email ID</p><span class="label_13_grey2">{{customerFormControl['userEmail'].value}}</span></li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                    <div class="tab_box_container">
                        <div class="row">
                            <div class="col-12">
                                <ul>
                                    <li><h4>Customer Details</h4></li>
                                </ul>
                            </div>
                            <div class="col-md-6 pr-0">
                                <ul>
                                    <!-- <li><p class="label_13_grey3 label_controls"><img src="../../../../assets/images/cake.png" /> &nbsp; Company Name</p><span class="label_13_grey2">{{otherDetailsFormControl['companyName'].value}}</span></li>                                
                                    <li><p class="label_13_grey3 label_controls"><img src="../../../../assets/images/cake.png" /> &nbsp; GSTIN</p><span class="label_13_grey2">{{otherDetailsFormControl['companyGSTIN'].value}}</span></li>                                
                                    <li><p class="label_13_grey3 label_controls"><img src="../../../../assets/images/cake.png" /> &nbsp; Contact Number</p><span class="label_13_grey2">{{otherDetailsFormControl['companyMobileNo'].value}}</span></li> -->
                                    <li><p class="label_13_grey3 label_controls">Street Address</p><span class="label_13_grey2">{{otherDetailsFormControl['streetAddress'].value}}</span></li>
                                    <!-- <li><p class="label_13_grey3 label_controls"><img src="../../../../assets/images/cake.png" /> &nbsp; State</p><span class="label_13_grey2">{{otherDetailsFormControl['companyState'].value}}</span></li> -->
                                </ul>
                            </div>
                            <div class="col-md-6 pl-0">
                                <ul>
                                    <!-- <li><p class="label_13_grey3 label_controls"><img src="../../../../assets/images/cake.png" /> &nbsp; License Number</p><span class="label_13_grey2">{{otherDetailsFormControl['companyLicenseNo'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><img src="../../../../assets/images/cake.png" /> &nbsp; Company PAN Card Number</p><span class="label_13_grey2">{{otherDetailsFormControl['companyPancard'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><img src="../../../../assets/images/cake.png" /> &nbsp; Email</p><span class="label_13_grey2">{{otherDetailsFormControl['companyEmail'].value}}</span></li> -->
                                    <li><p class="label_13_grey3 label_controls">PIN code</p><span class="label_13_grey2">{{otherDetailsFormControl['pincode'].value}}</span></li>
                                    <!-- <li><p class="label_13_grey3 label_controls"><img src="../../../../assets/images/cake.png" /> &nbsp; City</p><span class="label_13_grey2">{{otherDetailsFormControl['companyCity'].value}}</span></li> -->
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div id="comprehensive" class="tab-pane w-100" [ngClass]="{'d-block': activeTab == 2,'d-none' : activeTab !=2}">
            <div class="row">                
                <div class="col-12">
                    <div class="tab_box_container">
                        <div class="row">
                            <div class="col-12">
                                  <div style="height: 30px;"></div>
                                    <app-table-view
                                    [visibleColumnData] = "visibleColumnData"
                                    [visibleColumnsId] = "visibleColumnsId"
                                    [graphdata]="graphdata"
                                    [graphHeader]="graphHeader"
                                    title="Sales"
                                    tableBackground="var(--grey3)"
                                    buttonTitle=""
                                   [showIconButtonEnd]="true"
                                    [showPaginator]="true"  
                                    (onActionClick)="onActionClick($event)"
                                    [canCreate]="false"
                                    [canView]="true"
                                    [canDelete]="false"
                                    [canEdit]="false"
                                  ></app-table-view>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </app-mainscreen>