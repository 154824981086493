<div class="d-flex align-items-center justify-content-center">
    <app-notification-tooltip [items] ="items" (markedAsRead)="markedAsRead($event)"></app-notification-tooltip>
    <div class="mr-4 profile_img"
        style="background-image: url('../../../assets/images/profile_images/avatar_{{gender}}.svg'); background-size: cover; background-position: center;">
    </div>
    <div class="d-flex flex-column">
        <h6 class="m-0 p-0 profile_name">{{displayName}}</h6>
        <label class="m-0 p-0 mid_info">ID: {{displayId}}</label>
    </div>
    <span class="material-symbols-outlined ml-3">
        arrow_drop_down
    </span>
</div>