import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainscreenComponent } from './mainscreen/mainscreen.component';
import { SearchBarInputFieldComponent } from './search-bar-input-field/search-bar-input-field.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SimpleMenuButtonComponent } from './simple-menu-button/simple-menu-button.component';
import { AppbarProfileOptionsComponent } from './appbar-profile-options/appbar-profile-options.component';
import { AppbarComponent } from './appbar/appbar.component';
import { ExpandableMenuComponent } from './expandable-menu/expandable-menu.component';
import { CustomButtonComponent } from './custom-button/custom-button.component';
import { TextToggleComponent } from './text-toggle/text-toggle.component';
import { ToggleSwitchComponent } from './toggle-switch/toggle-switch.component';
import { StoreModule } from '@ngrx/store';
import { sidebarReducer } from './sidebar/state/sidebar.reducer';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MenuButtonSingleComponent } from './menu-button-single/menu-button-single.component';
import { MatPaginatorModule } from '@angular/material/paginator'; 
import { TableViewComponent } from './table-view/table-view.component';
import { SimplePageHeadingComponent } from './simple-page-heading/simple-page-heading.component';
import { TextFormFieldOutlinedComponent } from './text-form-field-outlined/text-form-field-outlined.component';
import { StepperDialogComponent } from './stepper-dialog/stepper-dialog.component'; 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomPaginatorComponent } from './custom-paginator/custom-paginator.component';
import { FooterComponent } from './footer/footer.component';
import { MultiDropdownComponent } from './multi-dropdown/multi-dropdown.component';
import { NotificationTooltipComponent } from './notification-tooltip/notification-tooltip.component';
import { LoginLogsComponent } from './login-logs/login-logs.component';
import { PdfGeneratorComponent } from './pdf-generator/pdf-generator.component';



@NgModule({
  declarations: [
    StepperDialogComponent,
    TextFormFieldOutlinedComponent,
    SidebarComponent,
    ExpandableMenuComponent,
    AppbarComponent,
    AppbarProfileOptionsComponent,
    SimpleMenuButtonComponent,
    SearchBarInputFieldComponent,
    MainscreenComponent,
    TextToggleComponent,
    ToggleSwitchComponent,
    CustomButtonComponent,
    MenuButtonSingleComponent,
    TableViewComponent,
    SimplePageHeadingComponent,
    MultiDropdownComponent,
    CustomPaginatorComponent,
    FooterComponent,
    NotificationTooltipComponent,
    PdfGeneratorComponent
    // LoginLogsComponent

  ],
  imports: [
    CommonModule,
    StoreModule.forRoot({
      sidebar: sidebarReducer,
    }),
    MatRippleModule,
    MatButtonModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports:[
    StepperDialogComponent,
    MultiDropdownComponent,
    SidebarComponent,
    ExpandableMenuComponent,
    AppbarComponent,
    AppbarProfileOptionsComponent,
    SimpleMenuButtonComponent,
    SearchBarInputFieldComponent,
    MainscreenComponent,
    TextToggleComponent,
    ToggleSwitchComponent,
    CustomButtonComponent,
    MenuButtonSingleComponent,
    TableViewComponent,
    SimplePageHeadingComponent,
    TextFormFieldOutlinedComponent,
    PdfGeneratorComponent,
    // LoginLogsComponent
  ]
})
export class ComponentsModule { }
