
import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import swal from 'sweetalert2';

@Component({
  selector: 'app-agent',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.css']
})
export class AgentComponent implements OnInit {
  agentForm!: FormGroup;
  resellerDropForm!: FormGroup;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;
  resellerData: any[] = [];
  distributorData: any[] = [];
  agentData: any[] = [];
  IsEdit: boolean = false;
  get commissionFee(): FormArray {
    return this.agentForm.get("commissionFee") as FormArray
  }

  newfee(): FormGroup {
    return this.fb.group({
      feeName: '',
      maxValue: '',
    })
  }

  constructor(private adminService: AdminService, private fb: FormBuilder) {
    this.agentForm = this.fb.group({
      "agentFirstName": ['', [Validators.required]],
      "agentLastName": ['', [Validators.required]],
      "agentMobile": ['', [Validators.required]],
      "agentCountryCode": [],
      "referralCode": ['', [Validators.minLength(6), Validators.maxLength(10)]],
      "agentEmail": ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      "agentStatus": ['', ''],
      "resellerId": [''],
      "distributorId": [''],
      "agentId": [''],
      "password": ['', [Validators.required]],
      "confirmpassword": ['', [Validators.required]],
      commissionType: [],
      commissionValue: [],
      commissionFrequency: [1],
      commissionFee: this.fb.array([])

    });
    this.resellerDropForm = this.fb.group({
      "resellerId": [''],
    });
  }

  ngOnInit(): void {
    this.addCommisionFee();
    this.getDistributorData();
    this.getResellerData();
    this.generateUniqueString();
  }

  onCountryChangeNew(e: any) {
    this.agentForm.get('agentMobile')?.setValue("");
  }

  generateUniqueString() {
    let length = 6;
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    this.agentForm.get('referralCode')?.setValue(result);
  }

  changeDistributor(value: any) {
    if (value) {
      this.agentForm.controls['resellerId'].clearValidators();
    }
    else {
      this.agentForm.controls['resellerId'].setValidators([
        Validators.required,
      ]);
    }
    this.agentForm.controls['resellerId'].updateValueAndValidity();
  }

  changeReseller(value: any) {
    if (value) {
      this.agentForm.controls['distributorId'].clearValidators();
    }
    else {
      this.agentForm.controls['distributorId'].setValidators([
        Validators.required,
      ]);
    }
    this.agentForm.controls['distributorId'].updateValueAndValidity();
  }

  changeGridReseller(value: any) {
    if (value)
      this.getAgentData(this.resellerDropForm.value.resellerId);
    else
      this.agentData = [];
  }

  clearData() {
    this.agentForm.reset();
  }

  async getResellerData() {

    const res = await this.adminService.ResellerDropDown();
    if (res != null && res.result) {
      this.resellerData = res.result;
    }

  }

  async getDistributorData() {
    const res = await this.adminService.DistributorDropDown();
    if (res != null && res.result) {
      this.distributorData = res.result;
    }

  }
  async getAgentData(resellerId: number) {
    const res = await this.adminService.AgentDetails(resellerId);
    if (res != null && res.result) {
      this.agentData = res.result;
    }

  }

  agentEdit(agentId: number) {
    let agent = this.agentData.filter((x: { agentId: any; }) => x.agentId == agentId)[0];
    if (agent.agentId > 0) {
      this.IsEdit = true;
      this.agentForm.patchValue(agent);
    }
  }



  async agentInfo() {
    if (this.agentForm.get('agentMobile')?.value != null && typeof (this.agentForm.get('agentMobile')?.value) == 'object') {
      let phone = this.agentForm.get('agentMobile')?.value;
      this.agentForm.get('agentMobile')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
      this.agentForm.get('agentCountryCode')?.setValue(phone.dialCode);
    }
    if (!this.IsEdit) {
      if (this.agentForm.get('password')?.value != this.agentForm.get('confirmpassword')?.value) {
        swal.fire({
          position: 'center',
          icon: 'error',
          title: 'Password and Confirm password should match.',
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }
      this.agentForm.get('password')?.setValue(window.btoa(this.agentForm.value.password));
    }


    this.agentForm.value.agentStatus = true;
    if (!this.agentForm.value.resellerId) this.agentForm.value.resellerId = null;
    if (!this.agentForm.value.distributorId) this.agentForm.value.distributorId = null;
    if (!this.agentForm.value.agentId) this.agentForm.value.agentId = null;

    const res = await this.adminService.AgentInfo(this.agentForm.value);
    if (res?.isSuccess) {
      let title = this.agentForm.value.agentId ? "updated" : "added"
      swal.fire({
        position: 'center',
        icon: 'success',
        title: 'agent data ' + title + ' successfully',
        showConfirmButton: false,
        timer: 3000,
      });
      // this.cityList = [];
      this.getAgentData(this.resellerDropForm.value.resellerId);
      this.clearData();
      this.generateUniqueString();
    }



  }


  addCommisionFee() {
    this.commissionFee.push(this.newfee());
  }

  removeCommisionFee(i: number) {
    this.commissionFee.removeAt(i);
  }

}
