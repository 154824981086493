<app-mainscreen>
    <app-simple-page-heading [breadcrumbs]="['Dashboard > Insurance Providers']" title="Insurance Providers" ></app-simple-page-heading>
    <div style="height: 15px;"></div>
    <app-table-view
    [visibleColumnData] = "visibleColumnData"
    [visibleColumnsId] = "visibleColumnsId"
    [graphdata]="graphdata"
    [graphHeader]="graphHeader"
    title="Insurance Providers"
    tableBackground="var(--grey3)"
    buttonTitle=""
    [showIconButtonEnd]="true"
    [showPaginator]="true"  
    (onActionClick)="onActionClick($event)"
    (onCreateBtnClick)="onCreateBtnClick($event)"
    [canCreate]="canCreate"
    [canView]="canView"
    [canDelete]="canDelete"
    [canEdit]="canEdit"
    className=""
  ></app-table-view>
  <app-stepper-dialog></app-stepper-dialog>
  </app-mainscreen>