<div class="login-container d-flex flex-root" id="kt_app_root">
  </div>
  <section>
    <div class="container-fluid">
      <img src="/assets/images/bg-1.png" class="bg-1" />
      <img src="/assets/images/bg-2.png" class="bg-2" />
      <img src="/assets/images/bg-3.png" class="bg-3" />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-5 col-md-5 col-sm-5 col-12">
          <div class="login_div">
            <!-- [formGroup]="forgotpasswordForm" -->
            <form class="form" novalidate="novalidate" id="kt_sign_in_form" >
              <div class="text-center mb-11">
                <h2>OTP <span style="color: var(--main1);">Verification</span></h2>
                <p>Enter 6 digit code that sent to your mobile Number. 9687337536 <a href="/forgot-password">Click here</a> to change your Mobile Number.</p>
              </div>
              <div class="form-group">
                <label class="input-label required" for="mobile">Enter OTP</label>
                <input type="number" class="form-control" name="phone" formControlName="PhoneNumber" placeholder="Enter OTP" minlength="6" onKeyPress="if(this.value.length==6) return false;" onfocus="this.placeholder=''"  />
              </div>
              <div class="passwords_div">
                <p class="login_para">Resend OTP in <span>00:30</span>
                <a class="link-primary forgot_password_link" >Resend OTP</a></p>
              </div>
              <div class="d-grid mb-5">
                <button class="btn btn-primary login_btn" type="submit" id="kt_sign_in_submit" (click)="verifyOTP()">
                  <span class="indicator-label">Verify</span>
                </button>
                <p class="login_para text-center">Back to login <a href="/">Click Here</a></p>
              </div>
              
            </form>
          </div>
        </div>
        <div class="col-lg-7 col-md-7 col-sm-7 col-12">
          <div class="slider_div text-center">
            
          </div>
        </div>
      </div>
    </div>
  </section>
  