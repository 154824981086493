import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StepperDialogComponent } from 'src/app/components/stepper-dialog/stepper-dialog.component';
import { Item } from 'src/app/models/item';
import { AdminService } from 'src/app/services/admin.service';
import { GeneralService } from 'src/app/services/general.service';
import { MenuService } from 'src/app/services/menu.service';
import * as XLSX from "xlsx";
import * as moment from 'moment';
import swal from 'sweetalert2';
@Component({
  selector: 'app-sales-list',
  templateUrl: './sales-list.component.html',
  styleUrls: ['./sales-list.component.css']
})
export class SalesListComponent {
  @ViewChild(StepperDialogComponent) stepperDialogComponent!: StepperDialogComponent;
  graphdata: any[] = [];
  visibleColumnData: Item[] = [];
  visibleColumnsId:any[] = [];
  graphHeader :any[]=[];
  canView:boolean = false;
  canEdit:boolean = false;
  canDelete:boolean = false;
  canCreate:boolean = false;
  viewUrl:string="";
  editUrl:string="";
  createUrl:string="";
  deleteUrl:string="";
  agentList:any;
  agentId:number=9999999;
  addBeneficiary() {
    this.stepperDialogComponent.show = true;
  }

  constructor(private adminService: AdminService, private router:Router, private menuService:MenuService,private generalService: GeneralService) {
    let obj = this.router.getCurrentNavigation()?.extras?.state;
    if(obj)
    {      
      this.getPermissions(parseInt(obj['id'])??0);
    }
    this.getSalesDetails();
  }

async getSalesDetails() {
    const res = await this.adminService.GetCustomerPolicies();    
    if (res != null && res.result) {
      this.graphdata = res.result.data;
      this.agentList =res.result.agentList;
      let index=3;
      let sort:string='asc';
      console.log(this.graphdata.sort( (a, b) => {
        if(sort == 'desc')
        {
          if (a[index].toString().toLowerCase() > b[index].toString().toLowerCase()) {
            return -1;
          }
          if (b[index].toString().toLowerCase() > a[index].toString().toLowerCase()) {
              return 1;
          }          
        }
        else
        {
          if (a[index].toString().toLowerCase() < b[index].toString().toLowerCase()) {
            return -1;
          }
          if (b[index].toString().toLowerCase() < a[index].toString().toLowerCase()) {
              return 1;
          }  
        }
        return index;
      }));
      this.graphHeader = res.result.header;
      this.visibleColumnData =  res.result.header.map((m:any,index:number)=>{return {id:index.toString(),name:m.key,checked:true}}).filter((f:any)=>{return !f.name.startsWith('Hidden_') });
      this.visibleColumnsId = this.visibleColumnData.map((m:any)=>{return m.id.toString()});
    }
  }

  async onActionClick(data:any)
  {
    if(data)
    {
      if(data.action=="View")
      {
        this.router.navigateByUrl(this.viewUrl,{ state: { id:data.id,userId:data.id1 }});
      }
      else if(data.action=="Edit")
      {
        this.router.navigateByUrl(this.editUrl,{ state: { id:data.id }});
      } 
      else if(data.action=="Download")
      {
        //this.router.navigateByUrl(this.editUrl,{ state: { id:data.id }});
          //this.spinner.show();
          const response = await this.adminService.DownloadPolicy(data.id?.toString());
          if (response?.isSuccess && response.result && response.result.code ==1)
          {
            const downloadLink = document.createElement('a');
            downloadLink.href = response.result.data;
            downloadLink.download = response.result.data2;
            downloadLink.target = "_blank";
            downloadLink.click();
            //this.spinner.hide();
          }
          else
          {
            //this.spinner.hide();
            swal.fire({
              position: 'center',
              imageUrl: "../../assets/alerts/warning.png",
              imageHeight: 89,
              title: "Policy document is not generated yet, please try after some time.",
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonText: 'Ok',
              cancelButtonColor: "#DF3A4D",
            }); 
          }
      } 
    }
  }
  
  onCreateBtnClick(data:any)
  {
    this.router.navigate([this.createUrl]);
  }

  async getPermissions(menuId:number)
  {
    let obj = {uniqueUserId:this.generalService.getUniqueuserid(),menuId:menuId};
    let res = await this.menuService.GetMenuPermissionsByUserId(obj);
    if(res && res.isSuccess)
    {
      if(res.result.userMenus.length > 0)
      {
        res.result.userMenus.forEach((element:any) => {
          if(element.permissionName.toLowerCase() =="create")
          {
            this.canCreate = true;
            this.createUrl = element.menuCommand;
          }
          else if(element.permissionName.toLowerCase() =="edit")
          {
            this.canEdit = true;
            this.editUrl = element.menuCommand;
          }
          else if(element.permissionName.toLowerCase() =="view")
          {
            this.canView = true;
            this.viewUrl = element.menuCommand;
          }
          else if(element.permissionName.toLowerCase() =="delete")
          {
            this.canDelete = true;
            this.deleteUrl = element.menuCommand;
          }
        });
      }      
    }
  }

  exportToExcel1() {
    let header:any = this.graphHeader.map((m:any)=> {return m.key});
    let body = this.graphdata;
    let excelData = body.map((_:any, row:any) =>
      header.reduce((json:any, key:any, col:any) => ({
        ...json,
        [key]: body[row][col]
      }), {}));
      debugger;
    excelData = excelData.filter((f:any)=> {return f.Hidden_Id2 == this.agentId});    
    excelData = excelData.map(item => {
      return Object.entries(item)
        .filter((_, index) => this.visibleColumnsId.includes(index?.toString()))
        .reduce((acc:any, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
    });

    excelData = excelData.filter(item => {
      let expiryDate = new Date(item['Expiry Date'])//item['Expiry Date']
      let end = new Date(new Date((new Date().setMonth(new Date().getMonth() + 1))));
      end = new Date(new Date(new Date(end.setHours(23)).setMinutes(59)).setSeconds(59));
      return expiryDate >= new Date() && expiryDate < end;
    }); 

    if(excelData?.length > 0){
      const fileName = moment().format('YYYYMMDDhmmss') +"_" + (this.agentList.filter((f:any) => f.id == this.agentId).map((m:any) => {return m.name})[0]).replace(" ","_")+".xlsx";
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");
      XLSX.writeFile(wb, fileName);
    }
    else
    {
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/images/alerts/warning.png",
        imageHeight: 89,
        title: "No policy data available for export.",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        cancelButtonColor: "#DF3A4D",
        customClass: {
          confirmButton: "error_btn",
        }
      });
    }

  }
}
