<app-mainscreen>
    <div class="dashboard_block">
        <div class="dashboard_block_header">
            <app-simple-page-heading title="Dashboard" titleDiscription="Welcome to the Distributor Dashboard "></app-simple-page-heading>
        </div>
        <div class="dashboard_block_body">
            <div class="tabs_div">
                <ul class="justify-content-between align-items-center mb-0 flex-row rounded-top-0">
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 1}" (click)="changeTab(1)">
                        Summary
                    </li> 
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 2}" (click)="changeTab(2)">
                        Agents
                    </li> 
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 3}" (click)="changeTab(3)">
                        Customers
                    </li> 
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 4}" (click)="changeTab(4)">
                        Policy
                    </li>            
                </ul>
            </div>
        </div>
    </div> 
    <div class="tabs_content">
        <div class="row mt-2">
            <div class="col d-flex align-items-center">
                <div class="title_div">
                    <h6 class="mb-0">Overview</h6>
                </div>
            </div>
            <div class="col">
                <div class="filter_div">
                    <ul class="d-flex align-items-center">
                        <li>
                            <img src="../../../../assets/images/calender.png" />
                        </li>
                        <li class="px-1">
                            <div class="dropdown">
                                <button class="dropbtn filter_btn">{{selectedDateRange}}</button>
                                <div class="dropdown-content">
                                <a [routerLink]="" (click)="setDateRange('7 Days')">Past 7 Days</a>
                                <a [routerLink]="" (click)="setDateRange('1 Months')">Past 1 Months</a>
                                <a [routerLink]="" (click)="setDateRange('3 Months')">Past 3 Months</a>
                                <a [routerLink]="" (click)="setDateRange('6 Months')">Past 6 Months</a>
                                <a [routerLink]="" (click)="setDateRange('9 Months')">Past 9 Months</a>
                                <a [routerLink]="" (click)="setDateRange('Year')">Last Year</a>
                                </div>
                            </div>
                        </li>
                        <li>
                            <mat-form-field class="example-form-field w-auto" appearance="fill">
                                <mat-date-range-input [rangePicker]="rangePicker"  >
                                    <input matStartDate placeholder="Start date" [(ngModel)]="selectedStartDate" appDateFormat (dateChange)="onStartDateChange($event)">
                                    <input matEndDate placeholder="End date" [(ngModel)]="selectedEndDate" appDateFormat (dateChange)="onEndDateChange($event)">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
                                <mat-date-range-picker #rangePicker>
                                    <mat-date-range-picker-actions>
                                        <!-- <button mat-button matDateRangePickerCancel (click)="resetDateRange()">Cancel</button> -->
                                        <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                                    </mat-date-range-picker-actions>
                                </mat-date-range-picker>
                                <mat-date-range-picker #rangePicker></mat-date-range-picker>
                            </mat-form-field>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div id="comprehensive" class="tab-pane" [ngClass]="{'d-block': activeTab == 1,'d-none' : activeTab !=1}">
            
            <div class="row"> 
                <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div class="dashboard_block">
                        <div class="dashboard_block_header">
                            <p><span>Total Distributors</span><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/rupee.png" /></span></p>
                            <h4>*{{totalDistributors}} <span class="material-symbols-outlined"> visibility </span></h4>
                            <p><a>Vew Details</a> <span class="dash_icon_img"><span class="material-symbols-outlined"> trending_up </span> +16.25%</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div class="dashboard_block">
                        <div class="dashboard_block_header">
                            <p><span>Total Resellers</span><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/rupee.png" /></span></p>
                            <h4>*{{totalResellers}} <span class="material-symbols-outlined"> visibility </span></h4>
                            <p><a>Vew Details</a><span class="dash_icon_img"><span class="material-symbols-outlined"> trending_up </span> &nbsp; 16.25%</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div class="dashboard_block">
                        <div class="dashboard_block_header">
                            <p><span>Total Agents</span><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/rupee.png" /></span></p>
                            <h4>*{{totalAgents}} <span class="material-symbols-outlined"> visibility </span></h4>
                            <p><a>Vew Details</a><span class="dash_icon_img"><span class="material-symbols-outlined"> trending_up </span> &nbsp; 16.25%</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div class="dashboard_block">
                        <div class="dashboard_block_header">
                            <p><span>total Active Customers</span><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/rupee.png" /></span></p>
                            <h4>*{{totalCustomers}} <span class="material-symbols-outlined"> visibility </span></h4>
                            <p><a>Vew Details</a><span class="dash_icon_img"><span class="material-symbols-outlined"> trending_up </span> &nbsp; 16.25%</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-12 mt-2">
                    <div class="dashboard_block">
                        <div class="dashboard_block_header">
                            <h5>Top 5 Performing Distributors</h5>
                            <p>Ranking according To 1 month</p>
                        </div>
                        <div class="dashboard_block_body">
                            <app-table-view
                            [visibleColumnData] = "visibleColumnData"
                            [visibleColumnsId] = "visibleColumnsId"
                            [graphdata]="graphdata"
                            [graphHeader]="graphHeader"
                            title=""
                            tableBackground="var(--grey3)"
                            buttonTitle=""
                            [showIconButtonEnd]="false"
                            [showPaginator]="false" 
                            [canCreate]="false"
                            [canView]="false"
                            [canDelete]="false"
                            [canEdit]="false"
                            [showFilters]="false"
                            [showPaginator]="false"
                            className="rounded-top-0"
                            ></app-table-view>                            
                        </div>    
                        <div class="w-100 p-2 text-center border-top" *ngIf="graphdata.length == 0">No Data available</div>                    
                    </div>                    
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-12 mt-2">
                    <div class="dashboard_block">
                        <div class="dashboard_block_header">
                            <h5>Recently Added Agent <a>view Details</a></h5>
                            <p>Data according To 1 month</p>
                        </div>
                        <div class="dashboard_block_body" *ngIf="topRecentDist.length > 0">
                            <div class="recently_added_div">
                                <table class="table">
                                    <tbody>
                                        <tr *ngFor="let item of topRecentDist">
                                            <td><img [src]="item.imgUrl"/></td>
                                            <td class="text-grey2">{{item.name}}<span class="text-grey3 d-block">ID: {{item.id}}</span></td>
                                            <td class="text-right"><a [href]="item.navigationUrl"><img src="../../../../assets/images/dashboard_icons/chevron_right.png" /></a> </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>  
                        <div class="w-100 p-2 text-center border-top" *ngIf="topRecentDist.length == 0">No Data available</div>                          
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-8 mt-1" [ngClass]="{'mt-3':topRecentDist.length == 0 && graphdata.length == 0}">
                    <div class="dashboard_block">
                        <div class="dashboard_block_header">
                            <div class="row">
                                <div class="col-12">
                                    <select class="form-select" [ngModel]="selectedDistributor">
                                        <ng-container *ngFor="let item of distributorDDLList; index as i">
                                            <option [value]="item.id">{{item.name}}</option>
                                        </ng-container>
                                    </select>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3 col-6 mt-2" (click)="loadChart('res')">
                                    <div class="dashboard_block">
                                        <div class="dashboard_block_header total_container">
                                            <span class="value_">{{totalResellers}}</span>
                                            <span class="value_label_">Total Resellers</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-6 mt-2" (click)="loadChart('agn')">
                                    <div class="dashboard_block">
                                        <div class="dashboard_block_header total_container">
                                            <span class="value_">{{totalAgents}}</span>
                                            <span class="value_label_">Total Agents</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-6 mt-2" (click)="loadChart('cus')">
                                    <div class="dashboard_block">
                                        <div class="dashboard_block_header total_container">
                                            <span class="value_">{{totalCustomers}}</span>
                                            <span class="value_label_">Total Customer</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-6 mt-2" (click)="loadChart('lds')">
                                    <div class="dashboard_block">
                                        <div class="dashboard_block_header total_container" >
                                            <span class="value_">0</span>
                                            <span class="value_label_">Total Leads</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="dashboard_block">
                        <div class="dashboard_block_header test">
                            <div style="width: 100%; height: auto;">
                                <canvas
                                    baseChart
                                    class="chart"
                                    [data]="barChartDataSummary"
                                    [options]="barChartOptionsSummary"
                                    [plugins]="barChartPluginsSummary"
                                    [type]="barChartTypeSummary">
                                </canvas>
                            </div>           
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mt-1" [ngClass]="{'mt-3':topRecentDist.length == 0 && graphdata.length == 0}">
                    <div class="commission_charts">
                        <div class="w-75 mx-auto">
                            <canvas baseChart height="10vw" width="75hw"
                                class="chart"
                                [data]="pieChartDataSummary"
                                [type]="pieChartTypeSummary"
                                [options]="pieChartOptionsSummary"
                                [plugins]="pieChartPluginsSummary"
                                [labels]="pieChartLabelsSummary"
                                >
                            </canvas>
                        </div>
                        <div class="px-3">
                            <div class="row">
                                <div class="col-12 rounded1 mt-2" *ngFor="let item of policySold; index as i" [ngStyle]="{'border': '2px solid ' + this.colorArray[i]}">
                                    <div class="row m-0">
                                        <div class="col-3">  
                                        </div>
                                        <div class="col">
                                            <div class="row">
                                                <div class="col-12 d-flex justify-content-between text-grey2 font-17">
                                                    <span>{{item.insurance}}</span>
                                                    <span>{{item.value}}</span>
                                                </div>
                                                <div class="col-12 d-flex justify-content-between text-grey3 font-13">
                                                    <span>{{item.amount}}</span>
                                                    <span>Policies</span>
                                                </div>
                                            </div>
                                            
                                        </div>                                
                                    </div>
                                </div>
                            </div> 
                        </div>               
                    </div>
                    
                </div>
            </div>
            <div class="title_div1 mt-4">
                <h6>Commission History</h6>
            </div>
            <div class="row mt-4">
                <div class="col-lg-5 col-md-5 col-sm-5 col-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12 com_div">
                            <div class="dashboard_block1">
                                <ul>
                                    <li><img src="../../../../assets/images/dashboard_icons/rupee1.png" /></li>
                                    <li>
                                        <p>Commission Earned <br> <span>₹ 10,250</span></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12 com_div1">
                            <div class="dashboard_block1">
                                <ul>
                                    <li><img src="../../../../assets/images/dashboard_icons/commission_pending.png" /></li>
                                    <li>
                                        <p>Commission Pending <br> <span style="color: var(--orange);">₹ 5,250</span></p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12 col-sm-12 col-12 com_div2 mt-4">
                            <div class="commission_charts">
                                <div style="width: 350px; height: 350px;">
                                    <canvas baseChart height="10vw" width="10vw"
                                        class="chart"
                                        [data]="pieChartData"
                                        [type]="pieChartType"
                                        [options]="pieChartOptions"
                                        [plugins]="pieChartPlugins"
                                        [labels]="pieChartLabels"
                                        >
                                    </canvas>

                                </div>                                  
                                                      
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="col-lg-7 col-md-7 col-sm-7 col-12">
                    <div class="commission_charts barchartsdiv">
                        <ul>
                            <li><img src="../../../../assets/images/dashboard_icons/rupee2.png" /></li>
                            <li>
                                <p>Total Commission (Monthly) <br> <span style="color: var(--grey2);">₹5,00,000</span></p>
                            </li>
                            <li>
                                <p><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/graph.png" /> &nbsp; 54% more then last day</span></p>
                            </li>
                        </ul>
                        <div style="width: 550px; height: 380px;">
                            <canvas
                                baseChart
                                class="chart"
                                [data]="barChartData"
                                [options]="barChartOptions"
                                [plugins]="barChartPlugins"
                                [type]="barChartType">
                            </canvas>

                        </div>           
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="title_div1 mt-4">
                        <h6>Commission History</h6>
                    </div>
                    <div class="tabs_div1 mt-4">
                        <ul class="justify-content-between align-items-center mb-0 flex-row">
                            <li class="list-item w-25" [ngClass]="{'active1': activeTabs == 5}" (click)="changeTabs(5)">
                                <span><img src="../../../../assets/images/dashboard_icons/royalsundaram.png" /></span>
                            </li> 
                            <li class="list-item w-25" [ngClass]="{'active1': activeTabs == 6}" (click)="changeTabs(6)">
                                <span><img src="../../../../assets/images/dashboard_icons/godigit.png" /></span>
                            </li>          
                        </ul>
                    </div>
                    <div id="comprehensive2" class="tab-pane w-100" [ngClass]="{'d-block': activeTabs == 5,'d-none' : activeTabs !=5}">
                        <div class="commission_charts barchartsdiv mt-4">
                            <ul>
                                <li><img src="../../../../assets/images/dashboard_icons/rupee2.png" /></li>
                                <li>
                                    <p>Total Commission (Monthly) <br> <span style="color: var(--grey2);">₹5,00,000</span></p>
                                </li>
                                <li>
                                    <p><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/graph.png" /> &nbsp; 54% more then last day</span></p>
                                </li>
                            </ul>
                            <div style="width: 450px; height: 380px;">
                                <canvas
                                    baseChart
                                    class="chart"
                                    [data]="barChartData"
                                    [options]="barChartOptions"
                                    [plugins]="barChartPlugins"
                                    [type]="barChartType">
                                </canvas>
    
                            </div>       
                        </div>
                    </div>
                    <div id="comprehensive2" class="tab-pane w-100" [ngClass]="{'d-block': activeTabs == 6,'d-none' : activeTabs !=6}">
                        <div class="commission_charts barchartsdiv mt-4">
                            <ul>
                                <li><img src="../../../../assets/images/dashboard_icons/rupee2.png" /></li>
                                <li>
                                    <p>Total Commission (Monthly) <br> <span style="color: var(--grey2);">₹5,00,000</span></p>
                                </li>
                                <li>
                                    <p><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/graph.png" /> &nbsp; 54% more then last day</span></p>
                                </li>
                            </ul>
                            <div style="width: 450px; height: 380px;">
                                <canvas
                                    baseChart
                                    class="chart"
                                    [data]="barChartData"
                                    [options]="barChartOptions"
                                    [plugins]="barChartPlugins"
                                    [type]="barChartType">
                                </canvas>
    
                            </div>       
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="title_div1 mt-4">
                        <h6>Policy Sold</h6>
                    </div>
                    <div class="tabs_div1 tabs_div2 mt-4">
                        <ul class="justify-content-between align-items-center mb-0 flex-row">
                            <li class="list-item w-auto" [ngClass]="{'active1': activeTabs1 == 1}" (click)="changeTabsone(1)">
                                <span><img src="../../../../assets/images/dashboard_icons/bike.png" /> &nbsp;Bike </span>
                            </li> 
                            <li class="list-item w-auto" [ngClass]="{'active1': activeTabs1 == 2}" (click)="changeTabsone(2)">
                                <span><img src="../../../../assets/images/dashboard_icons/car.png" /> &nbsp;Car</span>
                            </li>
                            <li class="list-item w-auto" [ngClass]="{'active1': activeTabs1 == 3}" (click)="changeTabsone(3)">
                                <span><img src="../../../../assets/images/dashboard_icons/health.png" /> &nbsp;Health</span>
                            </li>
                            <li class="list-item w-auto" [ngClass]="{'active1': activeTabs1 == 4}" (click)="changeTabsone(4)">
                                <span><img src="../../../../assets/images/dashboard_icons/life.png" /> &nbsp;Life</span>
                            </li>          
                        </ul>
                    </div>
                    <div id="comprehensive3" class="tab-pane w-100" [ngClass]="{'d-block': activeTabs1 == 1,'d-none' : activeTabs1 !=1}">
                        <div class="commission_charts barchartsdiv mt-4">
                            <ul>
                                <li><img src="../../../../assets/images/dashboard_icons/rupee2.png" /></li>
                                <li>
                                    <p>Total Revenue (Monthly) <br> <span style="color: var(--grey2);">₹5,00,000</span></p>
                                </li>
                                <li>
                                    <p><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/graph.png" /> &nbsp; 54% more then last day</span></p>
                                </li>
                            </ul>
                            <div style="width: 450px; height: 380px;">
                                <canvas
                                    baseChart
                                    class="chart"
                                    [data]="lineChartData"
                                    [options]="lineChartOptions"
                                    [type]="lineChartType">
                                </canvas>
    
                            </div>       
                        </div>
                    </div>
                    <div id="comprehensive3" class="tab-pane w-100" [ngClass]="{'d-block': activeTabs1 == 2,'d-none' : activeTabs1 !=2}">
                        <div class="commission_charts barchartsdiv mt-4">
                            <ul>
                                <li><img src="../../../../assets/images/dashboard_icons/rupee2.png" /></li>
                                <li>
                                    <p>Total Revenue (Monthly) <br> <span style="color: var(--grey2);">₹5,00,000</span></p>
                                </li>
                                <li>
                                    <p><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/graph.png" /> &nbsp; 54% more then last day</span></p>
                                </li>
                            </ul>
                            <div style="width: 450px; height: 380px;">
                                <canvas
                                    baseChart
                                    class="chart"
                                    [data]="lineChartData"
                                    [options]="lineChartOptions"
                                    [type]="lineChartType">
                                </canvas>
    
                            </div>       
                        </div>
                    </div>
                    <div id="comprehensive3" class="tab-pane w-100" [ngClass]="{'d-block': activeTabs1 == 3,'d-none' : activeTabs1 !=3}">
                        <div class="commission_charts barchartsdiv mt-4">
                            <ul>
                                <li><img src="../../../../assets/images/dashboard_icons/rupee2.png" /></li>
                                <li>
                                    <p>Total Revenue (Monthly) <br> <span style="color: var(--grey2);">₹5,00,000</span></p>
                                </li>
                                <li>
                                    <p><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/graph.png" /> &nbsp; 54% more then last day</span></p>
                                </li>
                            </ul>
                            <div style="width: 450px; height: 380px;">
                                <canvas
                                    baseChart
                                    class="chart"
                                    [data]="lineChartData"
                                    [options]="lineChartOptions"
                                    [type]="lineChartType">
                                </canvas>
    
                            </div>       
                        </div>
                    </div>
                    <div id="comprehensive3" class="tab-pane w-100" [ngClass]="{'d-block': activeTabs1 == 4,'d-none' : activeTabs1 !=4}">
                        <div class="commission_charts barchartsdiv mt-4">
                            <ul>
                                <li><img src="../../../../assets/images/dashboard_icons/rupee2.png" /></li>
                                <li>
                                    <p>Total Revenue (Monthly) <br> <span style="color: var(--grey2);">₹5,00,000</span></p>
                                </li>
                                <li>
                                    <p><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/graph.png" /> &nbsp; 54% more then last day</span></p>
                                </li>
                            </ul>
                            <div style="width: 450px; height: 380px;">
                                <canvas
                                    baseChart
                                    class="chart"
                                    [data]="lineChartData"
                                    [options]="lineChartOptions"
                                    [type]="lineChartType">
                                </canvas>
    
                            </div>       
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="comprehensive" class="tab-pane" [ngClass]="{'d-block': activeTab == 2,'d-none' : activeTab !=2}">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="dashboard_block">
                        <div class="dashboard_block_header">
                            <p><span>Onboarded Agents</span><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/onboarded_agents.png" /></span></p>
                            <h4>*56 <span><img src="../../../../assets/images/dashboard_icons/eye.png" /></span></h4>
                            <p><a>Vew Details</a><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/graph.png" /> &nbsp; 16.25%</span></p>
                        </div>                        
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="dashboard_block">
                        <div class="dashboard_block_header">
                            <p><span>InProgress Agents</span><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/commission_pending.png" /></span></p>
                            <h4>*56 <span><img src="../../../../assets/images/dashboard_icons/eye.png" /></span></h4>
                            <p><a>Vew Details</a><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/graph.png" /> &nbsp; 16.25%</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="dashboard_block">
                        <div class="dashboard_block_header">
                            <p><span>Blocked Agents</span><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/blocked_agents.png" /></span></p>
                            <h4>*56 <span><img src="../../../../assets/images/dashboard_icons/eye.png" /></span></h4>
                            <p><a>Vew Details</a><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/graph.png" /> &nbsp; 16.25%</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="dashboard_block">
                        <div class="dashboard_block_header">
                            <h5>Top 5 Performing Distributors</h5>
                            <p>Ranking according To 1 month</p>
                        </div>
                        <div class="dashboard_block_body">
                            <table class="table">
                                <thead style="background: #848893;">
                                    <tr>
                                        <th>Rank</th>
                                        <th>Agent</th>
                                        <th>No. of Policy Sold</th>
                                        <th>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1st</td>
                                        <td>Sudhakar Gajja <br>ID: 12345678</td>
                                        <td>50</td>
                                        <td>₹12,000</td>
                                    </tr>
                                    <tr>
                                        <td>1st</td>
                                        <td>Brijesh Meena <br>ID: 3457891</td>
                                        <td>45</td>
                                        <td>₹11,250</td>
                                    </tr>
                                    <tr>
                                        <td>1st</td>
                                        <td>Jignesh <br>ID: 789123</td>
                                        <td>40</td>
                                        <td>₹12,000</td>
                                    </tr>
                                    <tr>
                                        <td>4th</td>
                                        <td>Manoj <br>ID: 1357901</td>
                                        <td>39</td>
                                        <td>₹10,200</td>
                                    </tr>
                                    <tr>
                                        <td>5th</td>
                                        <td>Kalpana <br>ID: 246802</td>
                                        <td>25</td>
                                        <td>₹9,250</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>                        
                    </div>
                    
                </div>
                <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                    <div class="dashboard_block2">
                        <div class="header_part">
                            <h5>Recently Added Agent <a>view Details</a></h5>
                            <p>Data according To 1 month</p>
                        </div>
                        <div class="leads_div">
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td style="width: 5%;"><img src="../../../../assets/images/user.png" style="width: 40px;" /> </td>
                                        <td>Sudhakar Gajja <br>ID: 12345678</td>
                                        <td style="text-align: right;"><a href=""><img src="../../../../assets/images/dashboard_icons/chevron_right.png" /></a> </td>
                                    </tr>
                                    <tr>
                                        <td><img src="../../../../assets/images/user.png" style="width: 40px;" /> </td>
                                        <td>Sudhakar Gajja <br>ID: 12345678</td>
                                        <td style="text-align: right;"><a href=""><img src="../../../../assets/images/dashboard_icons/chevron_right.png" /></a> </td>
                                    </tr>
                                    <tr>
                                        <td><img src="../../../../assets/images/user.png" style="width: 40px;" /> </td>
                                        <td>Sudhakar Gajja <br>ID: 12345678</td>
                                        <td style="text-align: right;"><a href=""><img src="../../../../assets/images/dashboard_icons/chevron_right.png" /></a> </td>
                                    </tr>
                                    <tr>
                                        <td><img src="../../../../assets/images/user.png" style="width: 40px;" /> </td>
                                        <td>Sudhakar Gajja <br>ID: 12345678</td>
                                        <td style="text-align: right;"><a href=""><img src="../../../../assets/images/dashboard_icons/chevron_right.png" /></a> </td>
                                    </tr>
                                    <tr>
                                        <td><img src="../../../../assets/images/user.png" style="width: 40px;" /> </td>
                                        <td>Sudhakar Gajja <br>ID: 12345678</td>
                                        <td style="text-align: right;"><a href=""><img src="../../../../assets/images/dashboard_icons/chevron_right.png" /></a> </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="title_div1 mt-4">
                <h6>Commission History</h6>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="commission_charts barchartsdiv1 mt-4">
                        <ul>
                            <li><img src="../../../../assets/images/dashboard_icons/rupee2.png" /></li>
                            <li>
                                <p>Total Revenue (Monthly) <br> <span style="color: var(--grey2);">₹5,00,000</span></p>
                            </li>
                            <li>
                                <p><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/graph.png" /> &nbsp; 54% more then last day</span></p>
                            </li>
                        </ul>
                        <div style="width: 1300px; height: 400px;">
                            <canvas
                                baseChart
                                class="chart"
                                [data]="lineChartData"
                                [options]="lineChartOptions"
                                [type]="lineChartType">
                            </canvas>

                        </div>       
                    </div>
                </div>
            </div>       
        </div>
        <div id="comprehensive" class="tab-pane pb-4" [ngClass]="{'d-block': activeTab == 3,'d-none' : activeTab !=3}">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div class="dashboard_block">
                        <div class="dashboard_block_header">
                            <p><span>Total Active Customers</span><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/onboarded_agents.png" /></span></p>
                            <h4>*56 <span><img src="../../../../assets/images/dashboard_icons/eye.png" /></span></h4>
                            <p><a>Vew Details</a><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/graph.png" /> &nbsp; 16.25%</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div class="dashboard_block">
                        <div class="dashboard_block_header">
                            <p><span>Total Leads</span><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/leads.png" /></span></p>
                            <h4>*56 <span><img src="../../../../assets/images/dashboard_icons/eye.png" /></span></h4>
                            <p><a>Vew Details</a><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/graph.png" /> &nbsp; 16.25%</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div class="dashboard_block">
                        <div class="dashboard_block_header">
                            <p><span>Pending Leads</span><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/commission_pending.png" /></span></p>
                            <h4>*56 <span><img src="../../../../assets/images/dashboard_icons/eye.png" /></span></h4>
                            <p><a>Vew Details</a><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/graph.png" /> &nbsp; 16.25%</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-3 col-12">
                    <div class="dashboard_block">
                        <div class="dashboard_block_header">
                            <p><span>Failed Leads</span><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/failed.png" /></span></p>
                            <h4>*56 <span><img src="../../../../assets/images/dashboard_icons/eye.png" /></span></h4>
                            <p><a>Vew Details</a><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/graph.png" /> &nbsp; 16.25%</span></p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-12 mt-4">
                    <div class="title_div">
                        <h6>Customers <a>View Details</a></h6>
                    </div>
                    <div class="row agents_div">
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="customers_div">
                                <ul>
                                    <li><img src="../../../../assets/images/user.png" /> </li>
                                    <li>
                                        <p><b>Rahendra Yadav</b><br>rsyadv@gmail.com</p>
                                    </li>
                                    <li><p><span>31/05/2023</span></p></li>
                                </ul>
                                <div class="customers_block">
                                    <ul>
                                        <li><p>Total Policy <br><b>2</b></p> </li>
                                        <li>
                                            <p>Pending Policy <br><b>1</b></p>
                                        </li>
                                        <li><p>Location <br><b>Hyderabad</b></p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="customers_div">
                                <ul>
                                    <li><img src="../../../../assets/images/user.png" /> </li>
                                    <li>
                                        <p><b>Rahendra Yadav</b><br>rsyadv@gmail.com</p>
                                    </li>
                                    <li><p><span>31/05/2023</span></p></li>
                                </ul>
                                <div class="customers_block">
                                    <ul>
                                        <li><p>Total Policy <br><b>2</b></p> </li>
                                        <li>
                                            <p>Pending Policy <br><b>1</b></p>
                                        </li>
                                        <li><p>Location <br><b>Hyderabad</b></p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="customers_div">
                                <ul>
                                    <li><img src="../../../../assets/images/user.png" /> </li>
                                    <li>
                                        <p><b>Rahendra Yadav</b><br>rsyadv@gmail.com</p>
                                    </li>
                                    <li><p><span>31/05/2023</span></p></li>
                                </ul>
                                <div class="customers_block">
                                    <ul>
                                        <li><p>Total Policy <br><b>2</b></p> </li>
                                        <li>
                                            <p>Pending Policy <br><b>1</b></p>
                                        </li>
                                        <li><p>Location <br><b>Hyderabad</b></p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="customers_div">
                                <ul>
                                    <li><img src="../../../../assets/images/user.png" /> </li>
                                    <li>
                                        <p><b>Rahendra Yadav</b><br>rsyadv@gmail.com</p>
                                    </li>
                                    <li><p><span>31/05/2023</span></p></li>
                                </ul>
                                <div class="customers_block">
                                    <ul>
                                        <li><p>Total Policy <br><b>2</b></p> </li>
                                        <li>
                                            <p>Pending Policy <br><b>1</b></p>
                                        </li>
                                        <li><p>Location <br><b>Hyderabad</b></p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="customers_div">
                                <ul>
                                    <li><img src="../../../../assets/images/user.png" /> </li>
                                    <li>
                                        <p><b>Rahendra Yadav</b><br>rsyadv@gmail.com</p>
                                    </li>
                                    <li><p><span>31/05/2023</span></p></li>
                                </ul>
                                <div class="customers_block">
                                    <ul>
                                        <li><p>Total Policy <br><b>2</b></p> </li>
                                        <li>
                                            <p>Pending Policy <br><b>1</b></p>
                                        </li>
                                        <li><p>Location <br><b>Hyderabad</b></p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="customers_div">
                                <ul>
                                    <li><img src="../../../../assets/images/user.png" /> </li>
                                    <li>
                                        <p><b>Rahendra Yadav</b><br>rsyadv@gmail.com</p>
                                    </li>
                                    <li><p><span>31/05/2023</span></p></li>
                                </ul>
                                <div class="customers_block">
                                    <ul>
                                        <li><p>Total Policy <br><b>2</b></p> </li>
                                        <li>
                                            <p>Pending Policy <br><b>1</b></p>
                                        </li>
                                        <li><p>Location <br><b>Hyderabad</b></p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-12 mt-4">
                    <div class="title_div">
                        <h6>Reminders </h6>
                    </div>
                    <div class="reminders_div">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td style="width: 5%;"><img src="../../../../assets/images/user.png" style="width: 40px;" /> </td>
                                    <td>Sudhakar Gajja <br><span style="color: #DF3A4D;">Policy expiring in 2 days</span></td>
                                    <td style="text-align: right;"><a href=""><img src="../../../../assets/images/dashboard_icons/chevron_right.png" /></a> </td>
                                </tr>
                                <tr>
                                    <td><img src="../../../../assets/images/user.png" style="width: 40px;" /> </td>
                                    <td>Sudhakar Gajja <br><span style="color: #DF3A4D;">Policy expiring in 2 days</span></td>
                                    <td style="text-align: right;"><a href=""><img src="../../../../assets/images/dashboard_icons/chevron_right.png" /></a> </td>
                                </tr>
                                <tr>
                                    <td><img src="../../../../assets/images/user.png" style="width: 40px;" /> </td>
                                    <td>Sudhakar Gajja <br><span style="color: #DF3A4D;">Policy expiring in 3 days</span></td>
                                    <td style="text-align: right;"><a href=""><img src="../../../../assets/images/dashboard_icons/chevron_right.png" /></a> </td>
                                </tr>
                                <tr>
                                    <td><img src="../../../../assets/images/user.png" style="width: 40px;" /> </td>
                                    <td>Sudhakar Gajja <br><span>Policy expiring in 2 months</span></td>
                                    <td style="text-align: right;"><a href=""><img src="../../../../assets/images/dashboard_icons/chevron_right.png" /></a> </td>
                                </tr>
                                <tr>
                                    <td><img src="../../../../assets/images/user.png" style="width: 40px;" /> </td>
                                    <td>Sudhakar Gajja <br><span>Policy expiring in 3 months</span></td>
                                    <td style="text-align: right;"><a href=""><img src="../../../../assets/images/dashboard_icons/chevron_right.png" /></a> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div id="comprehensive" class="tab-pane w-100 pb-3" [ngClass]="{'d-block': activeTab == 4,'d-none' : activeTab !=4}">
            <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-8 col-12 mt-5">
                    <div class="title_div">
                        <h6>Policy </h6>
                    </div>
                    <div class="tabs_div1 tabs_div2 mt-4">
                        <ul class="justify-content-between align-items-center mb-0 flex-row">
                            <li class="list-item w-auto" [ngClass]="{'active1': activeTabs1 == 1}" (click)="changeTabsone(1)">
                                <span><img src="../../../../assets/images/dashboard_icons/bike.png" /> &nbsp;Bike </span>
                            </li> 
                            <li class="list-item w-auto" [ngClass]="{'active1': activeTabs1 == 2}" (click)="changeTabsone(2)">
                                <span><img src="../../../../assets/images/dashboard_icons/car.png" /> &nbsp;Car</span>
                            </li>
                            <li class="list-item w-auto" [ngClass]="{'active1': activeTabs1 == 3}" (click)="changeTabsone(3)">
                                <span><img src="../../../../assets/images/dashboard_icons/health.png" /> &nbsp;Health</span>
                            </li>
                            <li class="list-item w-auto" [ngClass]="{'active1': activeTabs1 == 4}" (click)="changeTabsone(4)">
                                <span><img src="../../../../assets/images/dashboard_icons/life.png" /> &nbsp;Life</span>
                            </li>          
                        </ul>
                    </div>
                    <div id="comprehensive3" class="tab-pane w-100" [ngClass]="{'d-block': activeTabs1 == 1,'d-none' : activeTabs1 !=1}">
                        <div class="commission_charts barchartsdiv4 mt-4">
                            <ul>
                                <li><img src="../../../../assets/images/dashboard_icons/rupee2.png" /></li>
                                <li>
                                    <p>Total Revenue (Monthly) <br> <span style="color: var(--grey2);">₹5,00,000</span></p>
                                </li>
                                <li>
                                    <p><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/graph.png" /> &nbsp; 54% more then last day</span></p>
                                </li>
                            </ul>
                            <div style="width: 650px; height: 380px;">
                                <canvas
                                baseChart
                                class="chart"
                                [data]="barChartPolicyData"
                                [options]="barChartPolicyOptions"
                                [plugins]="barChartPlugins"
                                [type]="barChartType"
                                >
                                </canvas>
    
                            </div>       
                        </div>
                    </div>
                    <div id="comprehensive3" class="tab-pane w-100" [ngClass]="{'d-block': activeTabs1 == 2,'d-none' : activeTabs1 !=2}">
                        <div class="commission_charts barchartsdiv4 mt-4">
                            <ul>
                                <li><img src="../../../../assets/images/dashboard_icons/rupee2.png" /></li>
                                <li>
                                    <p>Total Revenue (Monthly) <br> <span style="color: var(--grey2);">₹5,00,000</span></p>
                                </li>
                                <li>
                                    <p><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/graph.png" /> &nbsp; 54% more then last day</span></p>
                                </li>
                            </ul>
                            <div style="width: 450px; height: 380px;">
                                <canvas
                                baseChart
                                class="chart"
                                [data]="barChartPolicyData"
                                [options]="barChartPolicyOptions"
                                [plugins]="barChartPlugins"
                                [type]="barChartType"
                                >
                                </canvas>
    
                            </div>       
                        </div>
                    </div>
                    <div id="comprehensive3" class="tab-pane w-100" [ngClass]="{'d-block': activeTabs1 == 3,'d-none' : activeTabs1 !=3}">
                        <div class="commission_charts barchartsdiv4 mt-4">
                            <ul>
                                <li><img src="../../../../assets/images/dashboard_icons/rupee2.png" /></li>
                                <li>
                                    <p>Total Revenue (Monthly) <br> <span style="color: var(--grey2);">₹5,00,000</span></p>
                                </li>
                                <li>
                                    <p><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/graph.png" /> &nbsp; 54% more then last day</span></p>
                                </li>
                            </ul>
                            <div style="width: 450px; height: 380px;">
                                <canvas
                                baseChart
                                class="chart"
                                [data]="barChartPolicyData"
                                [options]="barChartPolicyOptions"
                                [plugins]="barChartPlugins"
                                [type]="barChartType"
                                >
                                </canvas>
    
                            </div>       
                        </div>
                    </div>
                    <div id="comprehensive3" class="tab-pane w-100" [ngClass]="{'d-block': activeTabs1 == 4,'d-none' : activeTabs1 !=4}">
                        <div class="commission_charts barchartsdiv4 mt-4">
                            <ul>
                                <li><img src="../../../../assets/images/dashboard_icons/rupee2.png" /></li>
                                <li>
                                    <p>Total Revenue (Monthly) <br> <span style="color: var(--grey2);">₹5,00,000</span></p>
                                </li>
                                <li>
                                    <p><span class="dash_icon_img"><img src="../../../../assets/images/dashboard_icons/graph.png" /> &nbsp; 54% more then last day</span></p>
                                </li>
                            </ul>
                            <div style="width: 450px; height: 380px;">
                                <canvas
                                baseChart
                                class="chart"
                                [data]="barChartPolicyData"
                                [options]="barChartPolicyOptions"
                                [plugins]="barChartPlugins"
                                [type]="barChartType"
                                >
                                </canvas>
    
                            </div>       
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-12 mt-5">
                    <div class="title_div">
                        <h6>Premium Company Wise </h6>
                    </div>
                    <div class="company_div">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td><img src="../../../../assets/images/dashboard_icons/royalsundaram.png" style="width: 80px;" /> </td>
                                    <td><b>₹ 12,560</b></td>
                                    <td style="text-align: right;"><a href=""><img src="../../../../assets/images/dashboard_icons/chevron_right.png" /></a> </td>
                                </tr>
                                <tr>
                                    <td><img src="../../../../assets/images/dashboard_icons/godigit.png" style="width: 80px;" /> </td>
                                    <td><b>₹ 12,560</b></td>
                                    <td style="text-align: right;"><a href=""><img src="../../../../assets/images/dashboard_icons/chevron_right.png" /></a> </td>
                                </tr>
                                <tr>
                                    <td><img src="../../../../assets/images/dashboard_icons/royalsundaram.png" style="width: 80px;" /> </td>
                                    <td><b>₹ 12,560</b></td>
                                    <td style="text-align: right;"><a href=""><img src="../../../../assets/images/dashboard_icons/chevron_right.png" /></a> </td>
                                </tr>
                                <tr>
                                    <td><img src="../../../../assets/images/dashboard_icons/royalsundaram.png" style="width: 80px;" /> </td>
                                    <td><b>₹ 12,560</b></td>
                                    <td style="text-align: right;"><a href=""><img src="../../../../assets/images/dashboard_icons/chevron_right.png" /></a> </td>
                                </tr>
                                <tr>
                                    <td><img src="../../../../assets/images/dashboard_icons/royalsundaram.png" style="width: 80px;" /> </td>
                                    <td><b>₹ 12,560</b></td>
                                    <td style="text-align: right;"><a href=""><img src="../../../../assets/images/dashboard_icons/chevron_right.png" /></a> </td>
                                </tr>
                                <tr>
                                    <td><img src="../../../../assets/images/dashboard_icons/godigit.png" style="width: 80px;" /> </td>
                                    <td><b>₹ 12,560</b></td>
                                    <td style="text-align: right;"><a href=""><img src="../../../../assets/images/dashboard_icons/chevron_right.png" /></a> </td>
                                </tr>
                                <tr>
                                    <td><img src="../../../../assets/images/dashboard_icons/royalsundaram.png" style="width: 80px;" /> </td>
                                    <td><b>₹ 12,560</b></td>
                                    <td style="text-align: right;"><a href=""><img src="../../../../assets/images/dashboard_icons/chevron_right.png" /></a> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-mainscreen>