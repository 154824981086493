import { Component, EventEmitter, Input, Output } from '@angular/core';
// s

@Component({
  selector: 'app-toggle-switch',
  templateUrl: './toggle-switch.component.html',
  styleUrls: ['./toggle-switch.component.css'],
})
export class ToggleSwitchComponent {
  @Input() switchon: boolean = false;
  @Input() showPopup: boolean = false;
  @Input() popupTitle: string = '';
  @Input() popupSubTitle: string = '';
  @Input() isCommentRequired: boolean = false;
  @Input() data:any;
  @Input() dot_height: string = "17px";
  @Input() dot_width: string = "17px";
  @Input() dot_bgColor: string = "var(--white)";
  @Input() dot_color: string = "var(--white)";
  @Input() dot_font_size: string = "13px";
  @Input() dot_on_margin: string = "23px";
  @Input() dot_off_margin: string = "1px";
  @Input() dot_on_text: string = "";
  @Input() dot_off_text: string = "";
  @Input() dot_border_radius: string = "100px";


  @Input() dot_Cont_height: string = "22px";
  @Input() dot_Cont_width: string = "44px";
  @Input() dot_Cont_bgColor_on: string = "var(--green)";
  @Input() dot_Cont_bgColor_off: string = "var(--grey4)";
  @Input() dot_Cont_border_radius: string = "100%";

  @Input() comment:string='';
  @Input() isDisabled:boolean=false;

  formSubmitted:boolean=false;
  isShowPopup:boolean=false;
  
  @Output() toggleChanged  = new EventEmitter<any>(); 
  toggleSwitch() {
    if(this.isDisabled == false){
      if(this.showPopup){
        this.isShowPopup=true;
      }
      else
      {
        this.doToggle();
      }
    }
  }
  
  doToggle(){
    if(this.isCommentRequired == true)
    {
      this.formSubmitted = true;
      if(this.comment.length > 0){
        this.switchon = !this.switchon;
        this.toggleChanged.emit({status:this.switchon,comment:this.comment,data:this.data});
        this.closePopup();
      }
    }
    else
    {
      this.switchon = !this.switchon;
      this.toggleChanged.emit({status:this.switchon,comment:this.comment,data:this.data});
      this.closePopup();
    }
    
  }

  closePopup(){
    this.isShowPopup=false;
  }
}
