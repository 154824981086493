import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { PasswordType } from 'src/app/models/login.component.model';
import { LoginService } from 'src/app/services/login.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import swal from 'sweetalert2';
import { OtpVerificationComponent } from '../otp-verification/otp-verification.component';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent {
  otp: number = 0;
  userOTP: number = 0;
  otpExpiryTime: number = 0;
  otpExpired: boolean = false;
  timeRemaining: Date | null = null;
  randOTP:string='';
  resetPassForm: FormGroup;
  loginForm:FormGroup;
  mobileNo: string = '';
  isOtp:boolean= false;
  isForgot:boolean=true;
  isReset:boolean=false;
  passwordShow: boolean = false;
  passwordShowConfirm: boolean = false;
  submitted:boolean = false;
  submittedOtp:boolean = false;
  submittedPass:boolean = false;
  isPassSame:boolean=true;
  public passwordType: string = PasswordType.pwd;
  public passwordTypeConfirm : string = PasswordType.pwd;
  isValidLoginForm:boolean= false;
  timerValue: number = 30; // Start at 30 seconds
  timer: any; // To store the interval reference
  constructor( public router: Router, private fb:FormBuilder,private loginService: LoginService, private spinner: NgxSpinnerService) {
    this.resetPassForm =  this.fb.group({
      "PhoneNumber": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      "otpNumber":['']
    });
    this.loginForm = this.fb.group({
      "PhoneNumber": [''],
      "password": ['',Validators.required],
      "confirm_password": ['',Validators.required],
    });
  }
  
  get resetPassFormControl() {
    return this.resetPassForm.controls;
  }
  ngOnInit() {
    // Generate OTP and set the expiry time
    this.otp = Math.floor(1000 + Math.random() * 9000);
    // this.otpExpiryTime = new Date().getTime() + 5 * 60 * 1000; // 5 minutes

    // // Start the timer
    // const timer = setInterval(() => {
    //   const currentTime = new Date().getTime();
    //   if (currentTime >= this.otpExpiryTime) {
    //     // Timer expired
    //     this.otpExpired = true;
    //     clearInterval(timer);
    //   } else {
    //     // Update the time remaining
    //     this.timeRemaining = new Date(this.otpExpiryTime - currentTime);
    //   }
    // }, 1000); // Check every second
  }
  get loginFormControl() {
    return this.loginForm.controls;
  }

  async resendOTP()
  {
      let request = { phoneNumber: this.resetPassForm?.get('PhoneNumber')?.value?.toString() ,OTP :this.randOTP  }
      const response = await this.loginService.SendOtp(request);
      if (response?.code == 1) {
        this.startTimer();
        swal.fire({
          position: 'center',
          icon: 'success',
          title: 'Resend OTP successfully',
          showConfirmButton: false,
          timer: 3000,
        });        
      }
      this.spinner.hide();
  }

  async sendOTP() {
    if(this.resetPassForm.valid == true)
    {
      this.spinner.show();
      this.submitted=true;

      this.GenerateRandomNo();
      if (this.resetPassForm.get('PhoneNumber')?.value != null && typeof (this.resetPassForm.get('PhoneNumber')?.value) == 'object') {
        let phone = this.resetPassForm.get('PhoneNumber')?.value;
        this.resetPassForm.get('PhoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
      }
      this.loginForm?.get('PhoneNumber')?.setValue(this.resetPassForm?.get('PhoneNumber')?.value)
      let request = { phoneNumber: this.resetPassForm?.get('PhoneNumber')?.value?.toString() ,OTP :this.randOTP  }
        const response = await this.loginService.SendOtp(request);
        if (response?.code == 1) {
          this.startTimer();
          this.isOtp = true;
          this.isForgot = false;
          this.spinner.hide();
          this.resetPassForm.get('otpNumber')?.addValidators([Validators.required, Validators.pattern('[0-9]{6}')]);
          this.resetPassForm.get('otpNumber')?.updateValueAndValidity();
        }
        else{
          this.spinner.hide();
          swal.fire({
            position: 'center',
            icon: 'error',
            title: response?.message??'Phone number not exists',
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }
    }

    async validateOtp() {
      if(this.resetPassForm.valid)
      {
        this.spinner.show();          
      
        if (this.resetPassForm.get('PhoneNumber')?.value != null && typeof (this.resetPassForm.get('PhoneNumber')?.value) == 'object') {
          let phone = this.resetPassForm.get('PhoneNumber')?.value;
          this.resetPassForm.get('PhoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
        }
    
        let request = { PhoneNumber: this.resetPassForm.get('PhoneNumber')?.value.toString(), OTP: this.resetPassForm.get('otpNumber')?.value.toString() ,GeneratedOTP:this.randOTP.toString()};
        const response = await this.loginService.ValidateOTP(request);
        if (response?.code == 1) {
          this.isForgot=false;
          this.isOtp=false;
          this.isReset=true;
          this.spinner.hide(); 
        }
        else {
          this.spinner.hide(); 
          swal.fire({
            position: 'center',
            icon: 'error',
            title: response?.message ?? 'Invalid OTP',
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }
    }

    async resetPassword() {
      if(this.loginForm.valid == true && this.isPassSame == true)
      {
        let obj={
          PhoneNumber:this.loginForm.value.PhoneNumber.toString(),
          password:window.btoa(this.loginForm.value.password.toString()),
          confirm_password:window.btoa(this.loginForm.value.confirm_password.toString())
        }
        const response = await this.loginService.ResetPassword(obj);
        if (response?.isSuccess) {
          swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Reset password successfully',
            showConfirmButton: false,
            timer: 3000,
          });
          this.router.navigate(['/login']);
        }
        else
        {
          swal.fire({
            position: 'center',
            icon: 'error',
            title: 'Something went wrong',
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }
    }

    GenerateRandomNo()
    {
        this.randOTP = "";
        let range = {min: 100000, max: 999999}
        let delta = range.max - range.min
        this.randOTP = Math.round(range.min + Math.random() * delta).toString();
    }

    ShowPassword(isConfirm:boolean) {
      if(isConfirm)
      {
        if (!this.passwordShowConfirm) {
          this.passwordTypeConfirm = PasswordType.txt;
          this.passwordShowConfirm = true;
        }
        else {
          this.passwordTypeConfirm = PasswordType.pwd;
          this.passwordShowConfirm = false;
        }
      }
      else
      {
        if (!this.passwordShow) {
          this.passwordType = PasswordType.txt;
          this.passwordShow = true;
        }
        else {
          this.passwordType = PasswordType.pwd;
          this.passwordShow = false;
        }
      }      
    }

    onCountryChangeNew(e: any) {
      this.loginForm.get('phoneNumber')?.setValue("");
    }

    passwordChange()
    {
      if(this?.loginForm?.get('password')?.value != "" && this?.loginForm?.get('confirm_password')?.value != "" && this?.loginForm?.get('password')?.value != this?.loginForm?.get('confirm_password')?.value)
      {
        this.isPassSame = false;
        this.isValidLoginForm = false;
      }
      else
      {
        this.isPassSame = true;
        this.isValidLoginForm = true;
      }
    }

    changeNumber()
    {
      this.isForgot =true;
      this.isOtp=false;
      this.isReset = false;
      this.resetPassForm.get('otpNumber')?.clearValidators();
      this.resetPassForm.get('otpNumber')?.updateValueAndValidity();
    }

    startTimer() {
      this.timerValue = 30;
      this.timer = setInterval(() => {
        if (this.timerValue > 0) {
          this.timerValue--;
        } else {
          this.stopTimer();
          
        }
      }, 1000); // Update every 1 second (1000 milliseconds)
    }
  
    stopTimer() {
      clearInterval(this.timer);
    }
}
