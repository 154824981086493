<app-mainscreen>
    <div class="box_container">
        <div class="box_header flex-column">
            <label class="box_title_18 border_bottom_grey5 w-100 mb-0 pb-2">Edit Reseller</label>
            <div class="row w-100">
                <div class="col-12">
                  <ul class="d-flex justify-content-between align-items-center mb-0 flex-row p-3 text-center list-item-container">
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 1}" (click)="changeTab(1)">
                        <img src="/assets/svgIcon/25_.svg" class="logo" />
                        Step 1 : Representative Details
                    </li> 
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 2}" (click)="changeTab(2)">
                        <img src="/assets/svgIcon/50_.svg" class="logo" />
                        Step 2 : Company Details
                    </li> 
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 3}" (click)="changeTab(3)">
                        <img src="/assets/svgIcon/75_.svg" class="logo" />
                        Step 3 : Upload Documents
                    </li> 
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 4}" (click)="changeTab(4)">
                        <img src="/assets/svgIcon/100_.svg" class="logo" />
                        Step 4 : Summary
                    </li>            
                  </ul>
                </div>
              </div> 
        </div>       
    </div>
    <div class="box_container mt-3 overflow-initial"> 
        <div id="comprehensive" class="w-100" [ngClass]="{'d-block': activeTab == 1,'d-none' : activeTab !=1}">
            <form id="kt_account_profile_details_form" class="form" [formGroup]="resellerForm" novalidate="novalidate">
                <div class="box_header pb-0">
                    <span class="box_title_18">
                        Representative Details
                    </span>                
                    <app-custom-button text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(2)">
                    </app-custom-button>
                </div>
                <div class="p-lr-10">
                    <label class="box_title_18 border_top_grey5 w-100 mb-0 pb-2"></label>                
                        <div class="row">
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((resellerFormControl['resellerFirstName'].touched  || submittedDisForm) && !resellerFormControl['resellerFirstName'].valid)}">
                                <input type="text" id="resellerFirstName" name="resellerFirstName" placeholder=" " class="form-control input_" formControlName="resellerFirstName" (keypress)="alphabetOnly($event)"
                                [ngClass]="{'border border-danger': ((resellerFormControl['resellerFirstName'].touched  || submittedDisForm) && resellerFormControl['resellerFirstName'].errors?.['required'])}">
                                <label for="resellerFirstName" class="label_ required">First Name</label>
                                <Span class="text-danger error_span" *ngIf="((resellerFormControl['resellerFirstName'].touched  || submittedDisForm) && resellerFormControl['resellerFirstName'].errors?.['required'])">
                                    First name is required.
                                </Span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((resellerFormControl['resellerLastName'].touched  || submittedDisForm) && !resellerFormControl['resellerLastName'].valid)}">
                                <input type="text" id="resellerLastName" name="resellerLastName" placeholder=" " class="form-control input_" formControlName="resellerLastName" (keypress)="alphabetOnly($event)"
                                [ngClass]="{'border border-danger': ((resellerFormControl['resellerLastName'].touched  || submittedDisForm) && !resellerFormControl['resellerLastName'].valid)}">
                                <label for="resellerLastName" class="label_ required">Last Name</label>
                                <Span class="text-danger error_span" *ngIf="((resellerFormControl['resellerLastName'].touched  || submittedDisForm) && !resellerFormControl['resellerLastName'].valid)">
                                    Last name is required.
                                </Span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((resellerFormControl['resellerEmail'].touched  || submittedDisForm) && !resellerFormControl['resellerEmail'].valid)}">
                                <input type="text" id="resellerEmail" name="resellerEmail" placeholder=" " class="form-control input_" formControlName="resellerEmail"
                                [ngClass]="{'border border-danger': ((resellerFormControl['resellerEmail'].touched  || submittedDisForm) && !resellerFormControl['resellerEmail'].valid)}">
                                <label for="resellerEmail" class="label_ required">Email</label>
                                <Span class="text-danger error_span" *ngIf="((resellerFormControl['resellerEmail'].touched  || submittedDisForm) && resellerFormControl['resellerEmail'].errors?.['required'])">
                                    Email is required.
                                </Span>
                                <Span class="text-danger error_span" *ngIf="((resellerFormControl['resellerEmail'].touched  || submittedDisForm) && resellerFormControl['resellerEmail'].errors?.['pattern'])">
                                    Please provide valid email address.
                                </Span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((resellerFormControl['resellerMobile'].touched  || submittedDisForm) && !resellerFormControl['resellerMobile'].valid)}">
                                <input type="text" id="resellerMobile" name="resellerMobile" placeholder=" " class="form-control input_" formControlName="resellerMobile" (keypress)="numberOnly($event)" maxlength="10" minlength="10" onKeyPress="if(this.value.length==10) return false;"
                                [ngClass]="{'border border-danger': ((resellerFormControl['resellerMobile'].touched  || submittedDisForm) && !resellerFormControl['resellerMobile'].valid)}" [readOnly]="'readOnly'" [disabled]="true">
                                <label for="resellerMobile" class="label_ required">Mobile Number</label>
                                <Span class="text-danger error_span" *ngIf="((resellerFormControl['resellerMobile'].touched  || submittedDisForm) && resellerFormControl['resellerMobile'].errors?.['required'])">
                                    Mobile number is required.
                                </Span>
                                <Span class="text-danger error_span" *ngIf="((resellerFormControl['resellerMobile'].touched  || submittedDisForm) && resellerFormControl['resellerMobile'].errors?.['pattern'])">
                                    Please provide valid mobile number.
                                </Span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((resellerFormControl['resellerDOB'].touched  || submittedDisForm) && !resellerFormControl['resellerDOB'].valid)}">
                                <input type="date" id="resellerDOB" name="resellerDOB" placeholder=" " class="form-control input_" formControlName="resellerDOB" [max]="maxDOB" onkeydown="return false"
                                [ngClass]="{'border border-danger': ((resellerFormControl['resellerDOB'].touched  || submittedDisForm) && !resellerFormControl['resellerDOB'].valid)}">
                                <label for="resellerDOB" class="label_ required">Date of Birth</label>
                                <Span class="text-danger error_span" *ngIf="((resellerFormControl['resellerDOB'].touched  || submittedDisForm) && !resellerFormControl['resellerDOB'].valid)">
                                    Date of birth is required.
                                </Span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((resellerFormControl['resellerGender'].touched  || submittedDisForm) && !resellerFormControl['resellerGender'].valid)}">
                                <select type="text" id="resellerGender" name="resellerGender" placeholder=" " class="form-control input_" formControlName="resellerGender"
                                [ngClass]="{'border border-danger': ((resellerFormControl['resellerGender'].touched  || submittedDisForm) && !resellerFormControl['resellerGender'].valid)}">
                                <option *ngFor="let gen of GenderList" [value]="gen.value">
                                    {{gen.text}}
                                </option>
                                </select>
                                <label for="resellerGender" class="label_ required">Gender</label>
                                <Span class="text-danger error_span" *ngIf="((resellerFormControl['resellerGender'].touched  || submittedDisForm) && !resellerFormControl['resellerGender'].valid)">
                                    Gender is required.
                                </Span>
                            </div>
                            <ng-container *ngIf="userType =='2'">
                                <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((resellerFormControl['distributorId'].touched  || submittedDisForm) && !resellerFormControl['distributorId'].valid)}">
                                    <select type="text" id="distributorId" name="distributorId" placeholder=" " class="form-control input_" formControlName="distributorId"
                                    [ngClass]="{'border border-danger': ((resellerFormControl['distributorId'].touched  || submittedDisForm) && !resellerFormControl['distributorId'].valid)}">
                                    <option value="">Select Distributor</option>
                                    <option *ngFor="let dis of distributorList" [value]="dis.id">
                                        {{dis.name}}
                                    </option>
                                    </select>
                                    <label for="distributorId" class="label_ required">Distributor</label>
                                    <Span class="text-danger error_span" *ngIf="((resellerFormControl['distributorId'].touched  || submittedDisForm) && !resellerFormControl['distributorId'].valid)">
                                        Distributor is required.
                                    </Span>
                                </div>
                            </ng-container>
                        </div>
                </div>        
            </form>
        </div>
        <div id="comprehensive" class="tab-pane w-100" [ngClass]="{'d-block': activeTab == 2,'d-none' : activeTab !=2}">
            
                <div class="box_header pb-0">
                    <span class="box_title_18">
                        Company Details
                    </span>
                    <div class="d-flex">
                        <app-custom-button className="btn_outline mr-2" text="Previous" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(1,true)"></app-custom-button> 
                        <app-custom-button text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(3)"></app-custom-button>
                    </div>
                </div>
                <div class="p-lr-10">
                    <label class="box_title_18 border_top_grey5 w-100 mb-0 pb-2"></label>  
                    <form id="kt_account_profile_details_form" class="form" [formGroup]="companyForm" novalidate="novalidate">             
                        <div class="row">                        
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyName'].touched  || submittedCompForm) && !companyDetailsFormControl['companyName'].valid)}">
                                <input type="text" id="companyName" name="companyName" placeholder=" " class="form-control input_" formControlName="companyName"
                                [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyName'].touched  || submittedCompForm) && !companyDetailsFormControl['companyName'].valid)}">
                                <label for="resellerEmail" class="label_ required">Company Name</label>
                                <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyName'].touched  || submittedCompForm) && companyDetailsFormControl['companyName'].errors?.['required'])">
                                    Company name is required.
                                </Span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyGSTIN'].touched  || submittedCompForm) && !companyDetailsFormControl['companyGSTIN'].valid)}">
                                <input type="text" id="companyGSTIN" name="companyGSTIN" placeholder=" " class="form-control input_ text-uppercase" formControlName="companyGSTIN" formControlName="companyGSTIN" (keypress)="alphaNumericOnly($event)" oninput="this.value = this.value.toUpperCase()" onkeypress="if(this.value.length == '15') return false;" 
                                [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyGSTIN'].touched  || submittedCompForm) && companyDetailsFormControl['companyGSTIN'].errors?.['required'])}">
                                <label for="companyGSTIN" class="label_ required">Company GSTIN</label>
                                <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyGSTIN'].touched  || submittedCompForm) && companyDetailsFormControl['companyGSTIN'].errors?.['required'])">
                                    Company GSTIN is required.
                                </Span>
                                <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyGSTIN'].touched  || submittedCompForm) && companyDetailsFormControl['companyGSTIN'].errors?.['pattern'])">
                                    Please provide valid GSTIN number (Eg. 22AAAAA0000A1Z5).
                                </Span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyPancard'].touched  || submittedCompForm) && !companyDetailsFormControl['companyPancard'].valid)}">
                                <input type="text" id="companyPancard" name="companyPancard" placeholder=" " class="form-control input_ text-uppercase" formControlName="companyPancard" oninput="this.value = this.value.toUpperCase()"
                                [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyPancard'].touched  || submittedCompForm) && !companyDetailsFormControl['companyPancard'].valid)}">
                                <label for="companyPancard" class="label_ required">Company PAN Card Number</label>
                                <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyPancard'].touched  || submittedCompForm) && companyDetailsFormControl['companyPancard'].errors?.['required'])">
                                    Company PAN card number is required.
                                </Span>
                                <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyPancard'].touched  || submittedCompForm) && companyDetailsFormControl['companyPancard'].errors?.['pattern'])">
                                    Please provide valid PAN card number.
                                </Span>
                            </div>
                            
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyLicenseNo'].touched  || submittedCompForm) && !companyDetailsFormControl['companyLicenseNo'].valid)}">
                                <input type="text" id="companyLicenseNo" name="companyLicenseNo" placeholder=" " class="form-control input_" formControlName="companyLicenseNo" [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyLicenseNo'].touched  || submittedCompForm) && !companyDetailsFormControl['companyLicenseNo'].valid)}">
                                <label for="companyLicenseNo" class="label_ required">Company License Number</label>
                                <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyLicenseNo'].touched  || submittedCompForm) && !companyDetailsFormControl['companyLicenseNo'].valid)">
                                    Company license number is required.
                                </Span>
                                <span class="error_span right-12">License Number issued by IRDAI</span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyMobileNo'].touched  || submittedCompForm) && !companyDetailsFormControl['companyMobileNo'].valid)}">
                                <input type="text" id="companyMobileNo" name="companyMobileNo" placeholder=" " class="form-control input_" formControlName="companyMobileNo" (keypress)="numberOnly($event)" maxlength="10" minlength="10" onKeyPress="if(this.value.length==10) return false;" [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyMobileNo'].touched  || submittedCompForm) && !companyDetailsFormControl['companyMobileNo'].valid)}">
                                <label for="companyMobileNo" class="label_ required">Company Mobile Number</label>
                                <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyMobileNo'].touched  || submittedCompForm) && companyDetailsFormControl['companyMobileNo'].errors?.['required'])">
                                    Company mobile number is required.
                                </Span>
                                <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyMobileNo'].touched  || submittedCompForm) && companyDetailsFormControl['companyMobileNo'].errors?.['pattern'])">
                                    Please provide company mobile number.
                                </Span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyEmail'].touched  || submittedCompForm) && !companyDetailsFormControl['companyEmail'].valid)}">
                                <input type="text" id="companyEmail" name="companyEmail" placeholder=" " class="form-control input_" formControlName="companyEmail"
                                [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyEmail'].touched  || submittedCompForm) && !companyDetailsFormControl['companyEmail'].valid)}">
                                <label for="companyEmail" class="label_ required">Company Email</label>
                                <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyEmail'].touched  || submittedCompForm) && companyDetailsFormControl['companyEmail'].errors?.['required'])">
                                    Email is required.
                                </Span>
                                <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyEmail'].touched  || submittedCompForm) && companyDetailsFormControl['companyEmail'].errors?.['pattern'])">
                                    Please provide valid email address.
                                </Span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyStreetAddress'].touched  || submittedCompForm) && !companyDetailsFormControl['companyStreetAddress'].valid)}">
                                <input type="text" id="companyStreetAddress" name="companyStreetAddress" placeholder=" " class="form-control input_" formControlName="companyStreetAddress"
                                [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyStreetAddress'].touched  || submittedCompForm) && !companyDetailsFormControl['companyStreetAddress'].valid)}">
                                <label for="companyStreetAddress" class="label_ required">Street Address</label>
                                <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyStreetAddress'].touched  || submittedCompForm) && companyDetailsFormControl['companyStreetAddress'].errors?.['required'])">
                                    Street address is required.
                                </Span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyPincode'].touched  || submittedCompForm) && !companyDetailsFormControl['companyPincode'].valid)}">
                                <app-autocomplete class="w-100" [userInput]="companyDetailsFormControl['companyPincode'].value" className="input_" [items]="pincodeList" placeholder="" labelText="Pincode" (onBlurEvent)="onPincodeBlur($event)" (onKeyUpEvent)="onPincodeChange($event)" (itemSelected)="onPincodeSelection($event)" [supportingText]="true" 
                                [showError]="((companyDetailsFormControl['companyPincode'].touched  || submittedCompForm) && !companyDetailsFormControl['companyPincode'].valid)" [isRequired]="true" 
                                [showErrorRequired]="((companyDetailsFormControl['companyPincode'].touched  || submittedCompForm) && companyDetailsFormControl['companyPincode'].errors?.['required'])"></app-autocomplete> 
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyState'].touched  || submittedCompForm) && !companyDetailsFormControl['companyState'].valid)}">
                                <app-autocomplete class="w-100" [userInput]="companyDetailsFormControl['companyState'].value" className="input_" [items]="stateList" placeholder="" labelText="State" (onBlurEvent)="onStateBlur($event)" (itemSelected)="onStateSelection($event);" 
                                [showError]="((companyDetailsFormControl['companyState'].touched  || submittedCompForm) &&!companyDetailsFormControl['companyState'].valid)" [isRequired]="true" [readonly]="'readonly'"
                                [showErrorRequired]="((companyDetailsFormControl['companyState'].touched  || submittedCompForm) &&!companyDetailsFormControl['companyState'].valid)"></app-autocomplete> 
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyCity'].touched  || submittedCompForm) && !companyDetailsFormControl['companyCity'].valid)}">
                                <app-autocomplete class="w-100" [userInput]="companyDetailsFormControl['companyCity'].value"className="input_" [items]="districtList" placeholder="" labelText="District/City" (onBlurEvent)="onCityBlur($event)" (itemSelected)="onCitySelection($event)" [supportingText]="true" 
                                [showError]="((companyDetailsFormControl['companyCity'].touched  || submittedCompForm) &&!companyDetailsFormControl['companyCity'].valid)" [isRequired]="true" [readonly]="'readonly'"
                                [showErrorRequired]="((companyDetailsFormControl['companyCity'].touched  || submittedCompForm) &&!companyDetailsFormControl['companyCity'].valid)"></app-autocomplete> 
                            </div>                            
                        </div>
                    </form>
                </div>  
        </div>
        <div id="comprehensive" class="tab-pane w-100 pb-4" [ngClass]="{'d-block': activeTab == 3,'d-none' : activeTab !=3}">
            <form id="kt_account_profile_details_form" class="form"  novalidate="novalidate">
                <div class="box_header pb-0">
                    <span class="box_title_18">
                        Upload Documents
                    </span>
                    <div class="d-flex">
                        <app-custom-button className="btn_outline mr-2" text="Previous" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(2,true)"></app-custom-button> 
                        <app-custom-button text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(4)"></app-custom-button>
                    </div>
                </div>
                <div class="p-lr-10">
                    <label class="box_title_18 border_top_grey5 w-100 mb-0 pb-2"></label>                
                        <div class="row">
                            <div class="col-12 position-relative">
                                <label>Upload PAN Card</label>
                                <app-file-upload id="pancard_file" (deletedFile)="deleteFile($event)" (selectedFile)="pancardSelected($event)" [docObj]="panDocObj" acceptType=".png, .jpg, .jpeg"></app-file-upload>
                                <Span class="text-danger error_span" *ngIf="(submittedDocForm) && !isPANCardUploaded()">
                                    PAN card document is required.
                                </Span>
                            </div>
                            <div class="col-12 position-relative mt-4">
                                <label>Upload Company GSTIN</label>
                                <app-file-upload id="gstin_file" (deletedFile)="deleteFile($event)" (selectedFile)="gstinSelected($event)" [docObj]="gstinDocObj" acceptType=".png, .jpg, .jpeg"></app-file-upload>
                                <Span class="text-danger error_span" *ngIf="(submittedDocForm) && !isGSTNDocUploaded()">
                                    GSTIN document is required.
                                </Span>	
                            </div> 
                        </div>
                </div>        
            </form>
            
        </div>
        <div id="comprehensive" class="tab-pane w-100 pb-3" [ngClass]="{'d-block': activeTab == 4,'d-none' : activeTab !=4}">
            <div class="box_header pb-0">
                <span class="box_title_18">
                    Summary
                </span>
                <app-custom-button text="Edit" [width]="'auto'" [height]="'34px'" leadingIconName="edit" (click)="saveNext(1)">
                    
                </app-custom-button>
            </div>
            <div class="p-lr-10">
                <label class="box_title_18 border_top_grey5 w-100 mb-0 pb-2"></label> 
                <div class="box_container mt-2">
                    <div class="box_header">
                        <label class="box_title_14 border_bottom_grey5 w-100 mb-0 pb-2">1. Representative Details</label>   
                    </div>
                    <div class="row w-100">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    First Name
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{resellerFormControl['resellerFirstName'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Last Name
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{resellerFormControl['resellerLastName'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Email
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{resellerFormControl['resellerEmail'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Mobile Number
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{resellerFormControl['resellerMobile'].value}}
                                </div>
                            </div>
                        </div>
    
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Date of Birth
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{resellerFormControl['resellerDOB'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Gender
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{resellerFormControl['resellerGender'].value}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>     
                <div class="box_container mt-4">
                    <div class="box_header">
                        <label class="box_title_14 border_bottom_grey5 w-100 mb-0 pb-2">2. Company Details</label>   
                    </div>
                    <div class="row w-100">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Company Name
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{companyDetailsFormControl['companyName'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Company PAN Card Number
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{companyDetailsFormControl['companyPancard'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Company License No
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{companyDetailsFormControl['companyLicenseNo'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Company GSTIN
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{companyDetailsFormControl['companyGSTIN'].value}}
                                </div>
                            </div>
                        </div>
    
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Company Contact No
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{companyDetailsFormControl['companyMobileNo'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Email ID
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{companyDetailsFormControl['companyEmail'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Street Address
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{companyDetailsFormControl['companyStreetAddress'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    State
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{companyDetailsFormControl['companyState'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    District/City
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{companyDetailsFormControl['companyCity'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Pincode
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{companyDetailsFormControl['companyPincode'].value}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box_container mt-4 pb-2">
                    <div class="box_header">
                        <label class="box_title_14 border_bottom_grey5 w-100 mb-0 pb-2">3. Document Uploaded</label>   
                    </div>
                    <div class="row w-100">
                        <div class="col-md-12 d-flex">
                            <span class="d-flex flex-column w-auto" *ngFor="let docObj of documentsUploadForm">
                                <img class="img_preview mr-3" src="{{docObj.documentBase64}}" title="{{docObj.documentName}}">
                                <span class="label_13_grey3">{{docObj.documentName}}</span>
                            </span>
                        </div> 
                    </div>
                </div>  
                <app-custom-button className="mt-3" text="Confirm" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveResellerData()"></app-custom-button>           
            </div> 
        </div>
    </div>
    </app-mainscreen>