<!-- <ul>
    <li *ngFor="let node of _nodes">
      <input type="checkbox" [(ngModel)]="node.checked" (change)="onNodeChecked(node)">
      {{ node.label }}
      <app-tree-table [nodes]="node.children" *ngIf="!node.collapsed && node.children.length > 0"></app-tree-table>
    </li>
  </ul> -->
  

  <ul class="categories">
    <ng-container *ngFor="let node of _nodes; let i = index">
      <li>
        <input
          [checked]="node.isSelected"
          type="checkbox"
          [value]="node.menuId"
          (change)="onChange($event,node)"
          [disabled]="disableAll"
        />
        {{ node.menuTitle.indexOf('Dashboard') != -1 ? node.permissionName : node.menuTitle }}
        <!-- first Child -->
        <ul *ngIf="!node.collapsed && node.children.length > 0" class="categories">
            <ng-container *ngFor="let sub1 of node.children;">
                <li [ngClass]="{'d-inline-flex mr-4':sub1.isPermission}">
                    <input
                    [checked]="sub1.isSelected"
                    (change)="onChange($event,sub1)"
                    type="checkbox"
                    [value]="sub1.menuId"
                    [disabled]="disableAll"
                    /> {{ sub1.isPermission ? sub1.permissionName :sub1.menuTitle }}
                    <!-- Second child -->
                    <ul *ngIf="!sub1.collapsed && sub1.children.length > 0" class="categories">
                        <ng-container *ngFor="let sub2 of sub1.children;">
                            <li [ngClass]="{'d-inline-flex mr-4':sub2.isPermission}">
                                <input
                                [checked]="sub2.isSelected"
                                (change)="onChange($event,sub2)"
                                type="checkbox"
                                [value]="sub2.menuId"
                                [disabled]="disableAll"
                                /> {{ sub2.isPermission ? sub2.permissionName :sub2.menuTitle }}
                                <!-- third child -->
                                <ul *ngIf="!sub2.collapsed && sub2.children.length > 0" class="categories">
                                    <ng-container *ngFor="let sub3 of sub2.children;">
                                        <li [ngClass]="{'d-inline-flex mr-4':sub3.isPermission}">
                                            <input
                                            [checked]="sub3.isSelected"
                                            (change)="onChange($event,sub3)"
                                            type="checkbox"
                                            [value]="sub3.menuId"
                                            [disabled]="disableAll"
                                            /> {{ sub3.menuTitle }}
                                        </li>
                                    </ng-container>
                                </ul>
                            </li>
                        </ng-container>
                    </ul>                    
                </li>
            </ng-container>
            </ul>
      </li>
    </ng-container>
  </ul> 