<div class="footer_div">
    <div class="row justify-content-between">
        <div class="col-lg-6 col-md-6 col-sm-6 col-12 d-flex align-items-center flex-wrap">
            <div class="copy_right_div">
                <p>© Copyright {{curretnYear}} Click4bima. All Rights Reserved</p>
            </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-6 col-12">
            <div class="copy_right_div1">
                <p>Hand crafted & Proudly made in &nbsp; <img src="../../../assets/images/flag.png" height="24px" /></p>
            </div>
        </div>
    </div>
</div>