import { Component, OnInit } from '@angular/core';
import { USerNotification } from 'src/app/models/SelectItems';
import { AdminService } from 'src/app/services/admin.service';
import { GeneralService } from 'src/app/services/general.service';
import swal from 'sweetalert2';

@Component({
  selector: 'app-appbar-profile-options',
  templateUrl: './appbar-profile-options.component.html',
  styleUrls: ['./appbar-profile-options.component.css']
})
export class AppbarProfileOptionsComponent implements OnInit {
  displayName:string="";
  gender:string = "";
  displayId:string = "";
  items:USerNotification[]=[];
  constructor(private generalService: GeneralService,private adminService:AdminService) {
    // this.items=[{"id":1,"title":"Reseller Created","description":"Reseller Created","reason":"Reseller Created","priorityLevel":"Normal","priorityLevelId":1,"isRead":false,"whenCreated":"Jan 26 2024 10:14AM"},
    // {"id":2,"title":"Distributor Created","description":"Distributor Created","reason":"Distributor Created","priorityLevel":"Normal","priorityLevelId":1,"isRead":false,"whenCreated":"Jan 26 2024 10:14AM"},
    // {"id":2,"title":"Distributor Created","description":"Distributor Created","reason":"Distributor Created","priorityLevel":"Normal","priorityLevelId":1,"isRead":false,"whenCreated":"Jan 26 2024 10:14AM"},
    // {"id":2,"title":"Distributor Created","description":"Distributor Created","reason":"Distributor Created","priorityLevel":"Normal","priorityLevelId":1,"isRead":false,"whenCreated":"Jan 26 2024 10:14AM"},
    // {"id":2,"title":"Distributor Created","description":"Distributor Created","reason":"Distributor Created","priorityLevel":"Normal","priorityLevelId":1,"isRead":false,"whenCreated":"Jan 26 2024 10:14AM"},
    // {"id":2,"title":"Distributor Created","description":"Distributor Created","reason":"Distributor Created","priorityLevel":"Normal","priorityLevelId":1,"isRead":false,"whenCreated":"Jan 26 2024 10:14AM"},
    // {"id":2,"title":"Distributor Created","description":"Distributor Created","reason":"Distributor Created","priorityLevel":"Normal","priorityLevelId":1,"isRead":false,"whenCreated":"Jan 26 2024 10:14AM"},
    // {"id":2,"title":"Distributor Created","description":"Distributor Created","reason":"Distributor Created","priorityLevel":"Normal","priorityLevelId":1,"isRead":false,"whenCreated":"Jan 26 2024 10:14AM"}];
    this.displayName = generalService.getUserDisplayName();
    this.gender = generalService.getDataFromLocalStorage('gender').toLowerCase() == 'female' ? 'f' : 'm';
    this.displayId = generalService.getDataFromLocalStorage('displayid');
    this.GetUserNotifications();
   }

  ngOnInit(): void {
  }

  async markedAsRead(ids:any){
    const res = await this.adminService.UpdateUserNotifications({ids:ids.toString()});    
    debugger;
    if (res != null && res.result && res.result.code == 1) {
      this.GetUserNotifications();
      swal.fire({
        position: 'center',
        icon: 'success',
        title: res.result.message,
        showConfirmButton: false,
        timer: 3000,
      });
    }
    else
    {
      swal.fire({
        position: 'center',
        icon: 'error',
        title: res.result.message,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  }

  async GetUserNotifications(){
    const res = await this.adminService.GetUserNotificationsByUserId(0);  
    debugger;  
    if (res != null && res.result) {
      this.items = res.result;
    }
    // else
    // {
    //   swal.fire({
    //     position: 'center',
    //     icon: 'error',
    //     title: 'Something went wrong!',
    //     showConfirmButton: false,
    //     timer: 3000,
    //   });
    // }
  }

}
