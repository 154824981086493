<div class="login-container d-flex flex-root" id="kt_app_root">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"
                 type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
  </div>
  <section>
    <div class="container-fluid">
      <img src="/assets/images/bg-1.png" class="bg-1" />
      <img src="/assets/images/bg-2.png" class="bg-2" />
      <img src="/assets/images/bg-3.png" class="bg-3" />
    </div>
    <div class="container h-100vh d-flex">
      <div class="row justify-content-between w-100 mx-0">
        <div class="col-lg-5 col-md-5 col-sm-5 col-12 align-self-center">
          <div class="login_div">
            <form class="form" novalidate="novalidate" id="kt_sign_in_form" [formGroup]="resetPassForm">
              <ng-container *ngIf="isForgot">
                <div class="text-center mb-11">
                  <h2>Forgot <span style="color: var(--main1);">Password?</span></h2>
                  <p>Change your current password</p>
                </div>
                <div class="form-group mb-0">
                  <label class="input-label required" for="mobile">Mobile Number</label>
                  <input type="number" class="form-control" name="phone" formControlName="PhoneNumber" placeholder="Enter your number" minlength="10" onKeyPress="if(this.value.length==10) return false;" onfocus="this.placeholder=''" [(ngModel)]="mobileNo" 
                  [ngClass]="{'border border-danger': ((resetPassFormControl['PhoneNumber'].touched || submitted) && !resetPassFormControl['PhoneNumber'].valid)}">
                  <Span class="text-danger" *ngIf="((resetPassFormControl['PhoneNumber'].touched || submitted) && resetPassFormControl['PhoneNumber'].errors?.['required'])">
                      Mobile number is required.
                  </Span>
                  <Span class="text-danger" *ngIf="((resetPassFormControl['PhoneNumber'].touched || submitted) && resetPassFormControl['PhoneNumber'].errors?.['pattern'])">
                      Provide valid Mobile number.
                  </Span>
                </div>
                
                <div class="d-grid mt-3">
                  <button class="btn btn-primary login_btn" type="submit" id="kt_sign_in_submit" (click)="sendOTP()" [disabled]="!resetPassFormControl['PhoneNumber'].valid">
                    <span class="indicator-label">Send OTP</span>
                  </button>
                  <p class="mt-3 text-center">Back to login? <a href="/" class="text-main ml-2">Click Here</a></p>
                </div>
              </ng-container>
              
              <ng-container *ngIf="isOtp">
                <div class="text-center mb-11">
                  <h2>OTP <span style="color: var(--main1);">Verification</span></h2>
                  <p>Enter 6 digit code that sent to your Mobile Number +91-{{mobileNo}}. <a class="text-primary cursor-pointer text-main" (click)="changeNumber()">Click here</a> to change your Mobile Number.</p>
                </div>
                <div class="form-group mb-0">
                  <label class="input-label required" for="mobile">Enter OTP</label>
                  <input type="number" class="form-control" name="phone" formControlName="otpNumber" placeholder="Enter OTP" minlength="6" onKeyPress="if(this.value.length==6) return false;" onfocus="this.placeholder=''"  
                  [ngClass]="{'border border-danger': ((resetPassFormControl['otpNumber'].touched || submittedOtp) && !resetPassFormControl['otpNumber'].valid)}">
                      <Span class="text-danger" *ngIf="((resetPassFormControl['otpNumber'].touched || submittedOtp) && resetPassFormControl['otpNumber'].errors?.['required'])">
                          OTP is required.
                      </Span>
                      <Span class="text-danger" *ngIf="((resetPassFormControl['otpNumber'].touched || submittedOtp) && resetPassFormControl['otpNumber'].errors?.['pattern'])">
                          Provide valid OTP.
                      </Span>
                </div>
                <div class="passwords_div">
                  <p class=""> <span *ngIf="timerValue > 0">Resend OTP in <span class="text-green">00:{{ timerValue }}</span> </span>
                  <button class="forgot_password_link cursor-pointer" [disabled]="timerValue > 0" (click)="resendOTP()">Resend OTP</button></p>
                </div>
                <div class="d-grid mb-5">
                  <button class="btn btn-primary login_btn" type="button" (click)="validateOtp()" [disabled]="!resetPassFormControl['otpNumber'].valid">
                    <span class="indicator-label">Verify</span>
                  </button>
                  <p class="mt-3 text-center">Back to login? <a href="/" class="text-main">Click Here</a></p>
                </div>
              </ng-container>              
            </form>
            <form class="form" novalidate="novalidate" [formGroup]="loginForm" *ngIf="isReset">
              <div class="text-center mb-11">
                <h2>Reset <span style="color: var(--main1);">Password</span></h2>
              </div>
              <div class="form-group mb-0">
                <label class="input-label required" for="mobile">Password</label>
                <input [type]="this.passwordType" class="form-control" name="phone" formControlName="password" placeholder="Enter your Password" onfocus="this.placeholder=''" 
                [ngClass]="{'border border-danger': ((loginFormControl['password'].touched || submittedPass) && !loginFormControl['password'].valid)}" (keyup)="passwordChange()"/>
                <i class="text-grey3" [class]="passwordShow === false ? 'fa fa-eye-slash field-icon fs-4' : 'fa fa-eye field-icon fs-4'" (click)="ShowPassword(false)"></i>
                <Span class="text-danger" *ngIf="((loginFormControl['password'].touched || submittedPass) && !loginFormControl['password'].valid)">
                  Password is required.
              </Span>
              </div>
              <div class="form-group">
                <label class="input-label required" for="confirm_password">Confirm Password</label>
                <input [type]="this.passwordTypeConfirm" placeholder="Enter Confirm Password" name="confirm_password" autocomplete="off" class="form-control bg-transparent" formControlName="confirm_password" onfocus="this.placeholder=''"  
                [ngClass]="{'border border-danger': ((loginFormControl['confirm_password'].touched || submittedPass) && !loginFormControl['confirm_password'].valid)}" (keyup)="passwordChange()"/>
                <i class="text-grey3" [class]="passwordShowConfirm === false ? 'fa fa-eye-slash field-icon fs-4' : 'fa fa-eye field-icon fs-4'" (click)="ShowPassword(true)"></i>
                <Span class="text-danger" *ngIf="((loginFormControl['confirm_password'].touched || submittedPass) && !loginFormControl['confirm_password'].valid)">
                  Confirm password is required.
                </Span>
                <Span class="text-danger" *ngIf="!isPassSame">
                  Password and confirmed password must be same.
                </Span>
              </div>
              <div class="fv-row mb-8">
              </div>
              <!-- <div class="passwords_div">
                <input type="checkbox">&nbsp;<span class="remember mt-1">Remember me</span>
                <a href="/forgot-password" class="link-primary forgot_password_link" >Forgot Password ?</a>
              </div> -->
              <div class="d-grid mb-5" >
                <button class="btn btn-primary login_btn" type="submit" id="kt_sign_in_submit"  (click)="resetPassword()" [disabled]="!isValidLoginForm">
                  <span class="indicator-label">Confirm Password</span>
                </button>
                <p class="mt-3 text-center">Back to login? <a href="/" class="text-main">Click Here</a></p> 
              </div>
            </form>
          </div>
        </div>        
        <div class="col-lg-5 col-md-5 col-sm-5 col-12 align-self-center">
          <app-carousel></app-carousel>
        </div>
      </div>
    </div>
  </section>