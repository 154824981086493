import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-text-form-field-outlined',
  templateUrl: './text-form-field-outlined.component.html',
  styleUrls: ['./text-form-field-outlined.component.css'],
})
export class TextFormFieldOutlinedComponent implements OnInit {
  @Input() title: string = '';
  @Input() description: string = '';
  @Input() backgroundColor: string = 'var(--grey6)';
  @Input() borderColor: string = 'var(--grey5)';
  @Input() leadingImageURL: string = '';
  @Input() placeHolder: string = '';
  @Input() innerLeadingIconWithDarkBackgroundName: string = '';
  @Input() innerLeadingIconName: string = '';
  @Input() leadingIconName: string = '';
  @Input() leadingIconOpacity: string = '1';
  @Input() leadingIconCursorStyle: string = 'pointer';
  @Input() suffixIconName: string = '';
  @Input() value: string = '';
  @Input() id: string = '';
  @Input() trailingButtonTitle: string = '';
  @Input() trailingButtonIconName: string = '';
  @Input() trailingButtonWidth: string = '100px';
  @Input() trailingButtonColor: string = 'var(--main1)';
  @Input() trailingButtonColorHovered: string = 'var(--main2)';
  @Input() trailingButtonDisabled: boolean = false;
  @Input() type: string = 'text';
  @Input() name: string = '';
  @Input() errorText: string = '';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() readonly: boolean = false;
  @Input() imagePickerEnabled: boolean = false;
  @Input() compulsaryKeepSpaceIfNoDeleteButton: boolean = false;
  @Input() maxLength: number = 100;
  @Input() height: number = 40;
  @Input() titleOpacity: number = 1;
  @Input() minLength: number = 0;
  @Input() borderWidth: number = 1.5;
  @Input() deleteEnabled: boolean = false;
  @Input() infoText: string = '';
  @Output() onDelete = new EventEmitter<void>();
  @Output() onFileChange = new EventEmitter();
  @Output() onValueChange = new EventEmitter<string>();
  @Output() trailingButtonClick = new EventEmitter<string>();

  imagePreview: string = '';

  constructor() {}

  ngOnInit(): void {}

  handleValueChange(event: Event) {
    let target = event.target as HTMLInputElement;
    this.onValueChange.emit(target.value);
  }

  handleInputFileInput(target: EventTarget | null) {
    let inputElement = target as HTMLInputElement;
    let files = inputElement.files;

    if (files && files.length > 0) {
      let fileItem = files.item(0);

      if (fileItem && fileItem.type.startsWith('image/')) {
        let reader = new FileReader();
        reader.readAsDataURL(fileItem);
        reader.onload = () => {
          this.imagePreview = reader.result?.toString() || '';
          this.onFileChange.emit(this.imagePreview);
        };
      } else {
        alert('Please select an image file');
      }
    }
  }
}
