<app-mainscreen>
    <div class="view_box_container rounded-bottom1-0">
        <div class="">
            <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                    <div class="view_div">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="view_block">
                                    <ul>
                                        <li>
                                            <img src="../../../../assets/images/user.png" />
                                        </li>
                                        <li>
                                            <p><b>{{distributorFormControl['distributorFirstName'].value}} {{distributorFormControl['distributorLastName'].value}}</b></p>
                                            <p>Distributor ID : {{distributorFormControl['distributorId'].value}} </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="view_block">
                                    <label class="label_13_grey3 d-inline-flex align-items-center mb-0"><span class="material-symbols-outlined font-18 "> contact_page </span>&nbsp; Distributor Status : </label> <span class="status"> {{distributorFormControl['distributorStatus'].value == true ? 'Active' : 'Inactive'}}</span> <br>
                                    <label class="label_13_grey3 d-inline-flex align-items-center mb-0"><span class="material-symbols-outlined font-18"> calendar_today </span> &nbsp; Last Login : </label> <span class="label_13_grey2">2 hrs Ago</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="view_buttons">
                        <!-- <button class="edit_button"><img src="../../../../assets/images/message.png" />&nbsp; Lorem </button> -->
                        <!-- <app-custom-button className="mt-3" text="Edit" [width]="'auto'" [height]="'34px'" leadingIconName="edit" (click)="backBtClick()">Edit</app-custom-button>  -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tab_div bottom-shadow">
        <ul class="align-items-center p-3 list-item-container-view">
            <li class="list-item" [ngClass]="{'active': activeTab == 1}" (click)="changeTab(1)">
                Company Details
            </li> 
            <li class="list-item" [ngClass]="{'active': activeTab == 2}" (click)="changeTab(2)">
                Documents
            </li> 
            <li class="list-item" [ngClass]="{'active': activeTab == 3}" (click)="changeTab(3)">
                Notifications Settings
            </li>
            <li class="list-item" [ngClass]="{'active': activeTab == 4}" (click)="changeTab(4)">
                Login Logs
            </li>         
          </ul>
    </div>
    <div class="tab_content">
        <div id="comprehensive" class="w-100" [ngClass]="{'d-block': activeTab == 1,'d-none' : activeTab !=1}">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="tab_box_container">
                        <div class="row">
                            <div class="col-12">
                                <ul>
                                    <li><h4>Personal Information</h4></li>
                                    <form id="kt_account_profile_details_form" class="form" [formGroup]="distributorForm" novalidate="novalidate">
                                        <div class="px-4 mt-3 pb-3">
                                            <div class="row">
                                                <div class="col-12 position-relative" [ngClass]="{'mb-2':((distributorFormControl['distributorFirstName'].touched  || submittedDisForm) && !distributorFormControl['distributorFirstName'].valid)}">
                                                    <input type="text" id="distributorFirstName" name="distributorFirstName" placeholder=" " class="form-control input_" formControlName="distributorFirstName" (keypress)="alphabetOnly($event)"
                                                    [ngClass]="{'border border-danger': ((distributorFormControl['distributorFirstName'].touched  || submittedDisForm) && distributorFormControl['distributorFirstName'].errors?.['required'])}">
                                                    <label for="distributorFirstName" class="label_">First Name</label>
                                                    <Span class="text-danger error_span" *ngIf="((distributorFormControl['distributorFirstName'].touched  || submittedDisForm) && distributorFormControl['distributorFirstName'].errors?.['required'])">
                                                        First name is required.
                                                    </Span>
                                                </div>
                                                <div class="col-12 position-relative" [ngClass]="{'mb-2':((distributorFormControl['distributorLastName'].touched  || submittedDisForm) && !distributorFormControl['distributorLastName'].valid)}">
                                                    <input type="text" id="distributorLastName" name="distributorLastName" placeholder=" " class="form-control input_" formControlName="distributorLastName" (keypress)="alphabetOnly($event)"
                                                    [ngClass]="{'border border-danger': ((distributorFormControl['distributorLastName'].touched  || submittedDisForm) && !distributorFormControl['distributorLastName'].valid)}">
                                                    <label for="distributorLastName" class="label_">Last Name</label>
                                                    <Span class="text-danger error_span" *ngIf="((distributorFormControl['distributorLastName'].touched  || submittedDisForm) && !distributorFormControl['distributorLastName'].valid)">
                                                        Last name is required.
                                                    </Span>
                                                </div>
                                                <div class="col-12 position-relative" [ngClass]="{'mb-2':((distributorFormControl['distributorEmail'].touched  || submittedDisForm) && !distributorFormControl['distributorEmail'].valid)}">
                                                    <input type="text" id="distributorEmail" name="distributorEmail" placeholder=" " class="form-control input_" formControlName="distributorEmail"
                                                    [ngClass]="{'border border-danger': ((distributorFormControl['distributorEmail'].touched  || submittedDisForm) && !distributorFormControl['distributorEmail'].valid)}">
                                                    <label for="distributorEmail" class="label_">Email</label>
                                                    <Span class="text-danger error_span" *ngIf="((distributorFormControl['distributorEmail'].touched  || submittedDisForm) && distributorFormControl['distributorEmail'].errors?.['required'])">
                                                        Email is required.
                                                    </Span>
                                                    <Span class="text-danger error_span" *ngIf="((distributorFormControl['distributorEmail'].touched  || submittedDisForm) && distributorFormControl['distributorEmail'].errors?.['pattern'])">
                                                        Please provide valid email address.
                                                    </Span>
                                                </div>
                                                <div class="col-12 position-relative" [ngClass]="{'mb-2':((distributorFormControl['distributorMobile'].touched  || submittedDisForm) && !distributorFormControl['distributorMobile'].valid)}">
                                                    <input type="text" id="distributorMobile" name="distributorMobile" placeholder=" " class="form-control input_" formControlName="distributorMobile" (keypress)="numberOnly($event)" maxlength="10" minlength="10" onKeyPress="if(this.value.length==10) return false;"
                                                    [ngClass]="{'border border-danger': ((distributorFormControl['distributorMobile'].touched  || submittedDisForm) && !distributorFormControl['distributorMobile'].valid)}">
                                                    <label for="distributorMobile" class="label_">Mobile Number</label>
                                                    <Span class="text-danger error_span" *ngIf="((distributorFormControl['distributorMobile'].touched  || submittedDisForm) && !distributorFormControl['distributorMobile'].valid)">
                                                        Please provide valid mobile number.
                                                    </Span>
                                                </div>
                                                <div class="col-12 position-relative" [ngClass]="{'mb-2':((distributorFormControl['distributorDOB'].touched  || submittedDisForm) && !distributorFormControl['distributorDOB'].valid)}">
                                                    <input type="date" id="distributorDOB" name="distributorDOB" placeholder=" " class="form-control input_" formControlName="distributorDOB"
                                                    [ngClass]="{'border border-danger': ((distributorFormControl['distributorDOB'].touched  || submittedDisForm) && !distributorFormControl['distributorDOB'].valid)}">
                                                    <label for="distributorDOB" class="label_">Date of Birth</label>
                                                    <Span class="text-danger error_span" *ngIf="((distributorFormControl['distributorDOB'].touched  || submittedDisForm) && !distributorFormControl['distributorDOB'].valid)">
                                                        Date of birth is required.
                                                    </Span>
                                                </div>
                                                <div class="col-12 position-relative" [ngClass]="{'mb-2':((distributorFormControl['distributorGender'].touched  || submittedDisForm) && !distributorFormControl['distributorGender'].valid)}">
                                                    <select type="text" id="distributorGender" name="distributorGender" placeholder=" " class="form-control input_" formControlName="distributorGender"
                                                    [ngClass]="{'border border-danger': ((distributorFormControl['distributorGender'].touched  || submittedDisForm) && !distributorFormControl['distributorGender'].valid)}">
                                                    <option *ngFor="let gen of GenderList" [value]="gen.value">
                                                        {{gen.text}}
                                                    </option>
                                                    </select>
                                                    <label for="distributorGender" class="label_">Gender</label>
                                                    <Span class="text-danger error_span" *ngIf="((distributorFormControl['distributorGender'].touched  || submittedDisForm) && !distributorFormControl['distributorGender'].valid)">
                                                        Gender is required.
                                                    </Span>
                                                </div>
                                                <div class="col-12 text-right">
                                                    <app-custom-button className="" text="Update" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveDistributorData('PROF')"></app-custom-button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                    <div class="tab_box_container">
                        <div class="row">
                            <div class="col-12">
                                <ul>
                                    <li><h4>Company Details</h4></li>
                                    <div class="px-4 mt-3 pb-3">
                                        <form id="kt_account_profile_details_form" class="form" [formGroup]="companyForm" novalidate="novalidate">             
                                            <div class="row">                        
                                                <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyName'].touched  || submittedCompForm) && !companyDetailsFormControl['companyName'].valid)}">
                                                    <input type="text" id="companyName" name="companyName" placeholder=" " class="form-control input_" formControlName="companyName"
                                                    [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyName'].touched  || submittedCompForm) && !companyDetailsFormControl['companyName'].valid)}">
                                                    <label for="distributorEmail" class="label_">Company Name</label>
                                                    <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyName'].touched  || submittedCompForm) && companyDetailsFormControl['companyName'].errors?.['required'])">
                                                        Company name is required.
                                                    </Span>
                                                </div>
                                                <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyGSTIN'].touched  || submittedCompForm) && !companyDetailsFormControl['companyGSTIN'].valid)}">
                                                    <input type="text" id="companyGSTIN" name="companyGSTIN" placeholder=" " class="form-control input_" formControlName="companyGSTIN" formControlName="companyGSTIN" (keypress)="alphaNumericOnly($event)" oninput="this.value = this.value.toUpperCase()" onkeypress="if(this.value.length == '15') return false;" 
                                                    [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyGSTIN'].touched  || submittedCompForm) && companyDetailsFormControl['companyGSTIN'].errors?.['required'])}">
                                                    <label for="companyGSTIN" class="label_">Company GSTIN</label>
                                                    <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyGSTIN'].touched  || submittedCompForm) && companyDetailsFormControl['companyGSTIN'].errors?.['required'])">
                                                        Company GSTIN is required.
                                                    </Span>
                                                    <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyGSTIN'].touched  || submittedCompForm) && companyDetailsFormControl['companyGSTIN'].errors?.['pattern'])">
                                                        Please provide valid GSTIN number (Eg. 22AAAAA0000A1Z5).
                                                    </Span>
                                                </div>
                                                <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyPancard'].touched  || submittedCompForm) && !companyDetailsFormControl['companyPancard'].valid)}">
                                                    <input type="text" id="companyPancard" name="companyPancard" placeholder=" " class="form-control input_" formControlName="companyPancard" oninput="this.value = this.value.toUpperCase()"
                                                    [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyPancard'].touched  || submittedCompForm) && !companyDetailsFormControl['companyPancard'].valid)}">
                                                    <label for="companyPancard" class="label_">Company PAN Card Number</label>
                                                    <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyPancard'].touched  || submittedCompForm) && companyDetailsFormControl['companyPancard'].errors?.['required'])">
                                                        Company PAN card number is required.
                                                    </Span>
                                                    <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyPancard'].touched  || submittedCompForm) && companyDetailsFormControl['companyPancard'].errors?.['pattern'])">
                                                        Please provide valid PAN card number.
                                                    </Span>
                                                </div>
                                                
                                                <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyLicenseNo'].touched  || submittedCompForm) && !companyDetailsFormControl['companyLicenseNo'].valid)}">
                                                    <input type="text" id="companyLicenseNo" name="companyLicenseNo" placeholder=" " class="form-control input_" formControlName="companyLicenseNo" [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyLicenseNo'].touched  || submittedCompForm) && !companyDetailsFormControl['companyLicenseNo'].valid)}">
                                                    <label for="companyLicenseNo" class="label_">Company License Number</label>
                                                    <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyLicenseNo'].touched  || submittedCompForm) && !companyDetailsFormControl['companyLicenseNo'].valid)">
                                                        Company license number is required.
                                                    </Span>
                                                    <span class="error_span right-12">License Number issued by IRDAI</span>
                                                </div>
                                                <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyMobileNo'].touched  || submittedCompForm) && !companyDetailsFormControl['companyMobileNo'].valid)}">
                                                    <input type="text" id="companyMobileNo" name="companyMobileNo" placeholder=" " class="form-control input_" formControlName="companyMobileNo" (keypress)="numberOnly($event)" maxlength="10" minlength="10" onKeyPress="if(this.value.length==10) return false;" [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyMobileNo'].touched  || submittedCompForm) && !companyDetailsFormControl['companyMobileNo'].valid)}">
                                                    <label for="companyMobileNo" class="label_">Company Mobile Number</label>
                                                    <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyMobileNo'].touched  || submittedCompForm) && !companyDetailsFormControl['companyMobileNo'].valid)">
                                                        Please provide valid company mobile number.
                                                    </Span>
                                                </div>
                                                <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyEmail'].touched  || submittedCompForm) && !companyDetailsFormControl['companyEmail'].valid)}">
                                                    <input type="text" id="companyEmail" name="companyEmail" placeholder=" " class="form-control input_" formControlName="companyEmail"
                                                    [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyEmail'].touched  || submittedCompForm) && !companyDetailsFormControl['companyEmail'].valid)}">
                                                    <label for="companyEmail" class="label_">Company Email</label>
                                                    <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyEmail'].touched  || submittedCompForm) && companyDetailsFormControl['companyEmail'].errors?.['required'])">
                                                        Email is required.
                                                    </Span>
                                                    <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyEmail'].touched  || submittedCompForm) && companyDetailsFormControl['companyEmail'].errors?.['pattern'])">
                                                        Please provide valid email address.
                                                    </Span>
                                                </div>
                                                <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyStreetAddress'].touched  || submittedCompForm) && !companyDetailsFormControl['companyStreetAddress'].valid)}">
                                                    <input type="text" id="companyStreetAddress" name="companyStreetAddress" placeholder=" " class="form-control input_" formControlName="companyStreetAddress"
                                                    [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyStreetAddress'].touched  || submittedCompForm) && !companyDetailsFormControl['companyStreetAddress'].valid)}">
                                                    <label for="companyStreetAddress" class="label_">Street Address</label>
                                                    <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyStreetAddress'].touched  || submittedCompForm) && companyDetailsFormControl['companyStreetAddress'].errors?.['required'])">
                                                        Street address is required.
                                                    </Span>
                                                </div>
                                                <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyState'].touched  || submittedCompForm) && !companyDetailsFormControl['companyState'].valid)}">
                                                    <app-autocomplete class="w-100" [userInput]="state" className="input_" [items]="stateList" placeholder="" labelText="State" (onBlurEvent)="onStateBlur($event)" (itemSelected)="onStateSelection($event);" 
                                                    [showError]="((companyDetailsFormControl['companyState'].touched  || submittedCompForm) &&!companyDetailsFormControl['companyState'].valid)" 
                                                    [showErrorRequired]="((companyDetailsFormControl['companyState'].touched  || submittedCompForm) &&!companyDetailsFormControl['companyState'].valid)"></app-autocomplete> 
                                                </div>
                                                <div class="col-md-6 position-relative">
                                                    <app-autocomplete class="w-100" [userInput]="district" className="input_" [items]="districtList" placeholder="" labelText="District" (onBlurEvent)="onDistrictBlur($event)" (itemSelected)="onDistrictSelection($event)" ></app-autocomplete> 
                                                </div>
                                                <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyCity'].touched  || submittedCompForm) && !companyDetailsFormControl['companyCity'].valid)}">
                                                    <app-autocomplete class="w-100" [userInput]="city"className="input_" [items]="cityList" placeholder="" labelText="City/Village/Talluka" (onBlurEvent)="onCityBlur($event)" (itemSelected)="onCitySelection($event)" [supportingText]="true" 
                                                    [showError]="((companyDetailsFormControl['companyCity'].touched  || submittedCompForm) &&!companyDetailsFormControl['companyCity'].valid)" 
                                                    [showErrorRequired]="((companyDetailsFormControl['companyCity'].touched  || submittedCompForm) &&!companyDetailsFormControl['companyCity'].valid)"></app-autocomplete> 
                                                </div>
                                                <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyPincode'].touched  || submittedCompForm) && !companyDetailsFormControl['companyPincode'].valid)}">
                                                    <app-autocomplete class="w-100" [userInput]="pincode" className="input_" [items]="pincodeList" placeholder="" labelText="Pincode" (onBlurEvent)="onPincodeBlur($event)" (itemSelected)="onPincodeSelection($event)" [supportingText]="true" 
                                                    [showError]="((companyDetailsFormControl['companyPincode'].touched  || submittedCompForm) &&!companyDetailsFormControl['companyPincode'].valid)" 
                                                    [showErrorRequired]="((companyDetailsFormControl['companyPincode'].touched  || submittedCompForm) && companyDetailsFormControl['companyPincode'].errors?.['required'])" 
                                                    [showErrorRequired]="((companyDetailsFormControl['companyPincode'].touched  || submittedCompForm) && companyDetailsFormControl['companyPincode'].errors?.['pattern'])"></app-autocomplete> 
                                                </div>
                                                <div class="col-12 text-right">
                                                    <app-custom-button className="" text="Update" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveDistributorData('COMP')"></app-custom-button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div id="comprehensive" class="tab-pane w-100" [ngClass]="{'d-block': activeTab == 2,'d-none' : activeTab !=2}">
            <div class="row">
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="tab_box_container">
                        <div class="row">
                            <div class="col-12">
                                <ul>
                                    <li><h4>Personal Information</h4></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> cake </span> &nbsp; Date of Birth</p><span class="label_13_grey2">{{distributorFormControl['distributorDOB'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> transgender </span> &nbsp; Gender</p><span class="label_13_grey2">{{distributorFormControl['distributorGender'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> call </span> &nbsp; Contact Number</p><span class="label_13_grey2">{{distributorFormControl['distributorMobile'].value}}</span></li>
                                    <li><p class="label_13_grey3 label_controls"><span class="material-symbols-outlined font-18"> mail </span> &nbsp; Email ID</p><span class="label_13_grey2">{{distributorFormControl['distributorEmail'].value}}</span></li>
                                </ul>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                    <div class="tab_box_container">
                        <ul>
                            <li><h4>Documents</h4></li>
                            <div class="px-4 mt-3 pb-3">
                                <div class="row">
                                    <div class="col-12 position-relative">
                                        <label>Upload PAN Card</label>
                                        <app-file-upload id="pancard_file" [LableclassName]="'w-100'" (deletedFile)="deleteFile($event)" (selectedFile)="pancardSelected($event)" [docObj]="panDocObj" acceptType=".png, .jpg, .jpeg"></app-file-upload>
                                        <Span class="text-danger error_span" *ngIf="(submittedDocForm) && !isPANCardUploaded()">
                                            PAN card document is required.
                                        </Span>
                                    </div>
                                    <div class="col-12 position-relative mt-4">
                                        <label>Upload Company GSTIN</label>
                                        <app-file-upload id="gstin_file" [LableclassName]="'w-100'" (deletedFile)="deleteFile($event)" (selectedFile)="gstinSelected($event)" [docObj]="gstinDocObj" acceptType=".png, .jpg, .jpeg"></app-file-upload>
                                        <Span class="text-danger error_span" *ngIf="(submittedDocForm) && !isGSTNDocUploaded()">
                                            GSTIN document is required.
                                        </Span>	
                                    </div> 
                                    <div class="col-12 text-right mt-3">
                                        <app-custom-button className="" text="Update" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveDistributorData('DOCU')"></app-custom-button>
                                    </div>
                                </div> 
                            </div>          
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div id="comprehensive" class="tab-pane w-100" [ngClass]="{'d-block': activeTab == 3,'d-none' : activeTab !=3}">
            <div class="row">
                <div class="col-12">
                    <div class="tab_box_container">
                        
                            <div class="px-4 mt-1 pb-2">
                                <div class="row">
                                    <div class="col-12">
                                        <h4>Notifications Settings</h4>
                                        <ul class="card-item card d-flex justify-content-between align-items-center mb-0 flex-row p-1 text-center categories bg-grey6">
                                            <li class="tab-list-item col border-bottom-0 " [ngClass]="{'active': activeTabItem == 1}" (click)="changeTabItem(1)">
                                                Push Notification
                                            </li>
                                            <li class="tab-list-item col border-bottom-0 " [ngClass]="{'active': activeTabItem == 2}" (click)="changeTabItem(2)">
                                                Email
                                            </li>
                                            <li class="tab-list-item  col border-bottom-0" [ngClass]="{'active': activeTabItem == 3}" (click)="changeTabItem(3)">
                                                SMS
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="tab_box_container mt-3">
                                <ul>
                                    <div class="tab-content clearfix">
                                        <div id="push" class="tab-pane active c-pl c-pr" [ngClass]="{'active': activeTabItem == 1}">
                                            <ng-container >
                                                <div class="row br-top-0">
                                                    <div class="col-12">
                                                        <ul class="categories px-0">
                                                            <li><h5 class="mb-0">What Notifications you receive</h5></li>
                                                            <li  class="d-flex justify-content-between align-items-center">
                                                                <span>
                                                                    <span class="label_13_grey2">Turn off all Notifications</span>
                                                                    <p class="label_13_grey3 label_controls">If you toggle off the Notification switch you will not get any notification unless it is critical notification  </p>
                                                                </span>
                                                                <span>
                                                                    <app-toggle-switch [data]="'PUSH'" (toggleChanged)="toggleChanged($event)" [switchon]="distributorFormControl['notificationEnabled'].value??false" class="toggle-switch"></app-toggle-switch>
                                                                </span>
                                                            </li>
                                                            <!-- <li>
                                                                <span class="label_13_grey2">Current Active Email Id for Notifications</span>
                                                                <p class="label_13_grey3 label_controls">Receive notifications from IMS via Email on  <span class="label_13_grey2 ml-1">{{distributorFormControl['distributorEmail'].value}}</span></p>
                                                            </li> -->
                                                        </ul>
                                                    </div>                                  
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div id="email" class="tab-pane active c-pl c-pr" [ngClass]="{'active': activeTabItem == 2}">
                                            <ng-container >
                                                <div class="row br-top-0">
                                                    <div class="col-12">
                                                        <ul class="categories px-0">
                                                            <li><h5 class="mb-0">Email Notifications</h5></li>
                                                            <li  class="d-flex justify-content-between align-items-center">
                                                                <span>
                                                                    <span class="label_13_grey2">Turn off all Emails</span>
                                                                    <p class="label_13_grey3 label_controls">If you toggle off the Email Notification switch you will not get any notification unless it is critical notification </p>
                                                                </span>
                                                                <span>
                                                                    <app-toggle-switch [data]="'EMAIL'" (toggleChanged)="toggleChanged($event)" [switchon]="distributorFormControl['emailEnabled'].value??false" class="toggle-switch"></app-toggle-switch>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span class="label_13_grey2">Current Active Email Id for Notifications</span>
                                                                <p class="label_13_grey3 label_controls">Receive notifications from IMS via Email on  <span class="label_13_grey2 ml-1">{{distributorFormControl['distributorEmail'].value}}</span></p>
                                                            </li>
                                                        </ul>
                                                    </div>                                  
                                                </div>
                                            </ng-container>
                                        </div>
                                        <div id="sms" class="tab-pane c-pl c-pr" [ngClass]="{'active': activeTabItem == 3}">
                                            <ng-container >
                                                <div class="row br-top-0">
                                                    <div class="col-12">
                                                        <ul class="categories px-0">
                                                            <li><h5 class="mb-0">SMS Notifications</h5></li>
                                                            <li class="d-flex justify-content-between align-items-center">
                                                                <span>
                                                                    <span class="label_13_grey2">Turn off all SMS</span>
                                                                    <p class="label_13_grey3 label_controls">If you toggle off the SMS Notification switch you will not get any notification unless it is critical notification  </p>
                                                                </span>
                                                                <span>
                                                                    <app-toggle-switch [data]="'SMS'" (toggleChanged)="toggleChanged($event)" [switchon]="distributorFormControl['smsEnabled'].value??false" class="toggle-switch"></app-toggle-switch>
                                                                </span>
                                                            </li>
                                                            <li>
                                                                <span class="label_13_grey2">Current Active Mobile Number for Notifications</span>
                                                                <p class="label_13_grey3 label_controls">Receive notifications from IMS via SMS on  <span class="label_13_grey2 ml-1"> {{distributorFormControl['distributorMobile'].value}}</span></p>
                                                            </li>
                                                        </ul>
                                                    </div>  
                                                </div>
                                            </ng-container>
                                        </div>                                    
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="comprehensive" class="tab-pane w-100" [ngClass]="{'d-block': activeTab == 4,'d-none' : activeTab !=4}">
            <div class="row">
                <div class="col-12">
                    <app-login-logs></app-login-logs>
                </div>
            </div>
        </div>
    </div>
</app-mainscreen>