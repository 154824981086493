import { Component } from '@angular/core';

@Component({
  selector: 'app-agent-commission-edit',
  templateUrl: './agent-commission-edit.component.html',
  styleUrls: ['./agent-commission-edit.component.css']
})
export class AgentCommissionEditComponent {

}
