import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-stepper-dialog',
  templateUrl: './stepper-dialog.component.html',
  styleUrls: ['./stepper-dialog.component.css']
})
export class StepperDialogComponent implements OnInit {

  @Input() show: boolean = false;

  @Input() showCreatePayout: boolean = false;



  @Output() dialogClosed = new EventEmitter<void>();

  urls: string[] = [];

  isInputDisabled = false;



  onSelectFile(event: Event) {

    const inputElement = event.target as HTMLInputElement;

    if (inputElement.files && inputElement.files[0]) {

      const filesAmount = inputElement.files.length;

      for (let i = 0; i < filesAmount; i++) {

        const reader = new FileReader();



        reader.onload = (e: Event) => {

          const readerResult = (e.target as FileReader).result;

          if (typeof readerResult === 'string') {

            this.urls.push(readerResult);

            if (this.urls.length >= 3) {

              this.isInputDisabled = true;

            }

          }

        };



        reader.readAsDataURL(inputElement.files[i]);

      }

    }

  }



  onDeleteImage(index: number) {

    if (index >= 0 && index < this.urls.length) {

      this.urls.splice(index, 1);

      this.isInputDisabled = false; // Enable the input when an image is deleted

    }

  }

  BulkCreateSubmit: boolean = false;

  currentStep: number = 1;

  stepOneForm: FormGroup;

  stepTwoForm: FormGroup;

  BulkBeneficiarySubmit: boolean = false;

  states: string[] = [

    'Alabama',

    'Alaska',

    'Arizona',

    'Arkansas',

    'California',

    'Colorado',

    'Connecticut',

    'Delaware',

    'Florida',

    'Georgia',

    'Hawaii',

    'Idaho',

    'Illinois',

    'Indiana',

    'Iowa',

    'Kansas',

    'Kentucky',

    'Louisiana',

    'Maine',

    'Maryland',

    'Massachusetts',

    'Michigan',

    'Minnesota',

    'Mississippi',

    'Missouri',

    'Montana',

    'Nebraska',

    'Nevada',

    'New Hampshire',

    'New Jersey',

    'New Mexico',

    'New York',

    'North Carolina',

    'North Dakota',

    'Ohio',

    'Oklahoma',

    'Oregon',

    'Pennsylvania',

    'Rhode Island',

    'South Carolina',

    'South Dakota',

    'Tennessee',

    'Texas',

    'Utah',

    'Vermont',

    'Virginia',

    'Washington',

    'West Virginia',

    'Wisconsin',

    'Wyoming',

  ];



  constructor(private formBuilder: FormBuilder) {

    this.stepOneForm = this.formBuilder.group({

      beneficiaryId: ['', Validators.required],

      beneficiaryName: ['', Validators.required],

      category: ['', Validators.required],

      employee: ['', Validators.required],

      contact: ['', Validators.required],

      email: ['', [Validators.required, Validators.email]],

      address: ['', Validators.required]

    });



    this.stepTwoForm = this.formBuilder.group({

      accountNumber: ['', Validators.required],

      reenterAccountNumber: ['', Validators.required],

      ifscCode: ['', Validators.required],

      beneficiaryName: ['', Validators.required],

      bankName: ['', Validators.required],

      city: ['', Validators.required]

    });

  }

  ngOnInit(): void {



  }

  showBulkBeneficiarySubmit() {

    this.showBulkBeneficiary = false;

    this.BulkBeneficiarySubmit = true;



  }

  nextStep() {

    this.currentStep++;

  }



  previousStep() {

    this.currentStep--;

  }



  addBulkBeneficiaries() {
    this.showBulkBeneficiary = false;
    this.show = true;
  }



  cancel() {

    this.show = false;

    this.BulkBeneficiarySubmit = false;
    this.showBulkBeneficiary = false;
    this.showCreatePayout = false;

    this.dialogClosed.emit();

  }



  submitForm() {

    // Logic for submitting the form

  }



  skipStep() {

    this.show = false;
this.currentStep=1;
    this.dialogClosed.emit();

  }



  verifyNow() {

    // Logic for verifying the account number

  }

  showBulkBeneficiary: boolean = false;

  showBulkPayout: boolean = false;



  toggleBulkBeneficiary(): void {

    this.showBulkBeneficiary = !this.showBulkBeneficiary;

    this.show = false;



  }

  toggleBulkPayout(): void {

    this.showBulkPayout = true;







  }

  toggleSinglePayout(): void {

    this.showBulkPayout = false;



  }

  bulkCreateSubmit(): void {



    this.BulkCreateSubmit = true;



  }



}