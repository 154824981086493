<app-mainscreen>
  <app-simple-page-heading [breadcrumbs]="['Dashboard > SMS Logs']" title="SMS Logs" ></app-simple-page-heading>
  <div class="box_container mt-4 pt-2 rounded-bottom1-0 position-relative justify-content-start overflow-initial">
    <div class="box-header mb-4 w-100 pl-2">
        <label class="m-0 box_title_22 pl-1"> SMS Logs </label>
    </div>
    <div class="row w-100 position-relative">
      <div class="col-2 position-relative">
        <input type="text" id="resellerFirstName" name="resellerFirstName" placeholder=" " class="form-control input_" [(ngModel)]="phoneNumber" onkeypress="if(this.value.length=='10') return false"  (keypress)="numberOnly($event)">
        <label for="resellerFirstName" class="label_">Phone Number</label>
      </div>
      <div class="col-auto tabs_content">
        <div class="filter_div p-0 pull-left">
          <ul class="d-flex align-items-center justify-content-between">
              <li>
                  <img src="../../../../assets/images/calender.png" />
              </li>
              <li class="px-1">
                  <div class="dropdown">
                      <button class="dropbtn filter_btn">{{selectedDateRange}}</button>
                      <div class="dropdown-content">
                      <a [routerLink]="" (click)="setDateRange('2 Days')">Past 2 Days</a>
                      <a [routerLink]="" (click)="setDateRange('7 Days')">Past 7 Days</a>
                      <a [routerLink]="" (click)="setDateRange('1 Months')">Past 1 Months</a>
                      <a [routerLink]="" (click)="setDateRange('3 Months')">Past 3 Months</a>
                      <a [routerLink]="" (click)="setDateRange('6 Months')">Past 6 Months</a>
                      <a [routerLink]="" (click)="setDateRange('9 Months')">Past 9 Months</a>
                      <a [routerLink]="" (click)="setDateRange('Year')">Last Year</a>
                      </div>
                  </div>
              </li>
              <li>
                  <mat-form-field class="example-form-field w-auto" appearance="fill">
                      <mat-date-range-input [rangePicker]="rangePicker"  >
                          <input matStartDate placeholder="Start date" [(ngModel)]="selectedStartDate" appDateFormat (dateChange)="onStartDateChange($event)">
                          <input matEndDate placeholder="End date" [(ngModel)]="selectedEndDate" appDateFormat (dateChange)="onEndDateChange($event)">
                      </mat-date-range-input>
                      <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
                      <mat-date-range-picker #rangePicker>
                          <mat-date-range-picker-actions>
                              <!-- <button mat-button matDateRangePickerCancel (click)="resetDateRange()">Cancel</button> -->
                              <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                          </mat-date-range-picker-actions>
                      </mat-date-range-picker>
                      <mat-date-range-picker #rangePicker></mat-date-range-picker>
                  </mat-form-field>
              </li>
          </ul>
        </div>
      </div>
      <div class="col-md-3">
        <app-custom-button text="Filter" [width]="'auto'" [height]="'34px'" leadingIconName="" (onClick)="applyFilter()">                    
        </app-custom-button>
        <app-custom-button className="btn_outline ml-2" text="Reset Filter" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="resetFilter()"></app-custom-button> 
      </div>     
    </div>
  </div>
  <div class="position-relative">
    <app-table-view
    [visibleColumnData] = "visibleColumnData"
    [visibleColumnsId] = "visibleColumnsId"
    [graphdata]="graphdata"
    [graphHeader]="graphHeader"
    title=""
    tableBackground="var(--grey3)"
   [showIconButtonEnd]="true"
    [showPaginator]="true"  
    [canCreate]="false"
    [canView]="false"
    [canDelete]="false"
    [canEdit]="false"
    className="rounded-top1-0"
    ></app-table-view>
  </div>
  <app-stepper-dialog></app-stepper-dialog>
  </app-mainscreen>