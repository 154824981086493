import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import swal from 'sweetalert2';
import { DistributorService } from '../services/distributor.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  url: string = '';
  theme: string = '';
  insurancelist: any = [];
  distributorId: any;
  distributorForm!: FormGroup;
  isCommisionFlag:boolean =true;

  get commissionFee(): FormArray {
    return this.distributorForm.get("commissionFee") as FormArray
  }

  newFee(): FormGroup {
    return this.fb.group({
      feeName: '',
      maxValue: '',
      from:'',
      to:''
    })
  }


  constructor(private distributorService: DistributorService, private snapshpot: ActivatedRoute,
    private fb: FormBuilder) {
      this.distributorForm = this.fb.group({
        commissionType: [],
        commissionValue: [],
        commissionFrequency: ['1'],
        commissionFee: this.fb.array([])
      });


  }

  ngOnInit(): void {
    this.distributorId = this.snapshpot.snapshot.params["DistributorId"];
    this.GetDistributorSettings();
    this.addCommisionFee();

  }

  async GetDistributorSettings() {
    try {
      const response = await this.distributorService.GetDistributorSettings(parseInt(this.distributorId));

      if (response.isSuccess) {
        let settings = JSON.parse(response.result.settingValue);
        this.url = response.result.url;
        this.theme = settings.theme;
        this.insurancelist = settings.insurancelist;
      } else {
        this.GetInsurancPartners();
      }
    } catch (error) {
      console.error('Error in GetDistributorSettings:', error);
    }
  }

  async save() {
    var data = this.distributorForm.value;
    let insuranceObj = this.insurancelist.map((obj: any) => ({ ...obj,
      CommisionSettings:{
     Commisiontype:this.distributorForm.value.commissionType,
     Commissionvalue:this.distributorForm.value.commissionValue,
     CommisionFrequency:this.distributorForm.value.commissionFrequency,
     Tiers:this.distributorForm.value?.commissionFee?.map((x:any)=>({
                                   from:x.from,
                                   to:x.to,
                                   feeName:x.feeName,
                                   price:x.maxValue
                                })),
      }
     }))
    const distributorSettings = {
      theme: this.theme,
      insurancelist:insuranceObj 
    };

    const request = {
      distributorId: this.distributorId,
      url: this.url,
      SettingStatus: 1,
      SettingValue: JSON.stringify(distributorSettings),
      Url:this.url
    };

    try {
      const response = await this.distributorService.ManageDistributorSettings(request);

      if (response.isSuccess) {
        this.showSuccessMessage('Settings Updated');
      }
    } catch (error) {
      console.error('Error in save:', error);
    }
  }

  showSuccessMessage(message: string) {
    swal.fire({
      position: 'center',
      icon: 'success',
      title: message,
      showConfirmButton: false,
      timer: 3000,
    });
  }

  async GetInsurancPartners() {
    const response = await this.distributorService.GetInsurancePartners();
    if (response?.isSuccess) {
      this.insurancelist = response.result;
    }
  }



  productChange(productId: number, event: any) {
    const isActive = event.target.checked;
    this.insurancelist = this.insurancelist.map((obj: any) => {
      obj.insuranceProducts = obj.insuranceProducts.map((product: any) => {
        if (product.insuranceProductId === productId) {
          return { ...product, is_active: isActive };
        }
        return product;
      })
      return obj;
    });
  }


  gatewayChange(authority_id: number, event: any) {
    const isActive = event.target.checked;
    this.insurancelist = this.insurancelist.map((obj: { authority_id: number; }) => {
      if (obj.authority_id === authority_id) {
        return { ...obj, is_active: isActive };
      }
      return obj;
    });
  }

  addCommisionFee() {
    this.commissionFee.push(this.newFee());
  }

  removeCommisionFee(i: number) {
    this.commissionFee.removeAt(i);
  }
  commisionTypeChange(value:any){
  if(value != "3")
  this.isCommisionFlag = true;
  else
  this.isCommisionFlag = false;

  }
}
