import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-page-heading',
  templateUrl: './simple-page-heading.component.html',
  styleUrls: ['./simple-page-heading.component.css'],
})
export class SimplePageHeadingComponent implements OnInit {
  @Input() breadcrumbs: string[] = [];
  @Input() title: string = '';
  @Input() titleDiscription: string = '';

  constructor() {}

  ngOnInit(): void {}
}
