export function checkIfInSameTree(url: any) {
  var browserUrl = window.location.pathname;
  var browserUrlParts = browserUrl.split('/');
  var urlParts = url.split('/');

  console.log(urlParts);

  if (urlParts.length <= 1) return false;

  for (let i = 0; i < urlParts.length; i++) {
    if (urlParts[i] !== browserUrlParts[i]) {
      return false;
    }
  }

  return true;
}
