import { Component, OnInit, ViewChild } from '@angular/core';
import { Chart, ChartConfiguration, ChartData, ChartType } from 'chart.js';
import Annotation from 'chartjs-plugin-annotation';
import DatalabelsPlugin from 'chartjs-plugin-datalabels';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';

import { BaseChartDirective } from 'ng2-charts';
import { RecentlyAddedModel } from 'src/app/models/Dashboard';
import { Item } from 'src/app/models/item';
import { AdminService } from 'src/app/services/admin.service';
import * as moment from 'moment';

@Component({
  selector: 'app-company-admin-dashboard',
  templateUrl: './company-admin-dashboard.component.html',
  styleUrls: ['./company-admin-dashboard.component.css']
})
export class CompanyAdminDashboardComponent {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective | undefined;

  totalDistributors:number=0;
  totalResellers:number=0;
  totalAgents:number=0;
  totalCustomers:number=0;
  chartDataDis:any=[];
  chartDataRes:any=[];
  chartDataAgn:any=[];
  chartDataCus:any=[];
  chartDataLeads:any=[];
  topPerfDist:any=[];
  topRecentDist:any=[];
  policySold:any=[];

  barChartLabel:any=[];
  barChartData1:any=[];
  barChartData2:any=[];
  colorArray:string[] = ["#EAFB86","#F7BC24","#F79824","#F45757","#EB6C99","#EF36DD","#8166AD","#345D9D","#5F36D3","#4FC3C7","#35ECB5","#81D065","#26AD85","#EDFFBA"]; 
  today = new Date();
  selectedStartDate:any = moment(this.today).add(-7, 'days').format();
  selectedEndDate:any = this.today;
  selectedDateRange:string="Past 7 Days";
  distributorDDLList:any=[];
  selectedDistributor:number=0;
  constructor(private adminSevice:AdminService) {
    Chart.register(Annotation);   
    this.bindDashboardData();
  }

  graphdata: any[] = [];
  visibleColumnData: Item[] = [];
  visibleColumnsId:any[] = [];
  graphHeader :any[]=[];

  recentlyAdded:RecentlyAddedModel[]=[];
  ngOnInit(): void {
  //   this.graphHeader=
  //   [
  //     {
  //         "key": "Hidden_Id",
  //         "type": "System.String"
  //     },
  //     {
  //         "key": "Hidden_Id1",
  //         "type": "System.String"
  //     },
  //     {
  //         "key": "Hidden_Id2",
  //         "type": "System.String"
  //     },
  //     {
  //         "key": "Rank",
  //         "type": "System.String"
  //     },
  //     {
  //         "key": "Agent",
  //         "type": "System.String"
  //     },
  //     {
  //         "key": "No. of Policy Sold",
  //         "type": "System.String"
  //     },
  //     {
  //         "key": "Amount",
  //         "type": "System.String"
  //     }
  // ];
  //   this.visibleColumnData =  this.graphHeader.map((m:any,index:number)=>{return {id:index.toString(),name:m.key,checked:true}}).filter((f:any)=>{return !f.name.startsWith('Hidden_') });
  //   this.visibleColumnsId = this.visibleColumnData.map((m:any)=>{return m.id.toString()});
  //   this.graphdata=[
  //   ['','','','1st','Sudhakar Gajja','50','₹12,000'],
  //   ['','','','2nd','Sudhakar Gajja','48','₹11,000'],
  //   ['','','','3rd','Sudhakar Gajja','45','₹15,000'],
  //   ['','','','4th','Sudhakar Gajja','40','₹11,000'],
  //   ['','','','5th','Sudhakar Gajja','38','₹16,000'] 
  //   ];

  //   this.recentlyAdded=[{
  //     imgUrl:'../../../../assets/images/user.png',
  //     name:'Sudhakar Gajja',
  //     id:'12345678',
  //     navigationUrl:''
  //   },
  //   {
  //     imgUrl:'../../../../assets/images/user.png',
  //     name:'Sudhakar Gajja',
  //     id:'12345678',
  //     navigationUrl:''
  //   },
  //   {
  //     imgUrl:'../../../../assets/images/user.png',
  //     name:'Sudhakar Gajja',
  //     id:'12345678',
  //     navigationUrl:''
  //   },
  //   {
  //     imgUrl:'../../../../assets/images/user.png',
  //     name:'Sudhakar Gajja',
  //     id:'12345678',
  //     navigationUrl:''
  //   },
  //   {
  //     imgUrl:'../../../../assets/images/user.png',
  //     name:'Sudhakar Gajja',
  //     id:'12345678',
  //     navigationUrl:''
  //   }]
  }

  bindDashboardData(){
    let obj={
      DistributorId:0,
      ResellerId:0,
      AgentId:0,
      SystemCompanyId:1,
      StartDate:moment(this.selectedStartDate).format('YYYY-MM-DD'),
      EndDate:moment(this.selectedEndDate).format('YYYY-MM-DD')
    }
    debugger;
    this.adminSevice.GetAminDashboardData(obj).then((response)=> { 
      debugger;
    if(response?.isSuccess && response?.result?.length > 0)
    {
      for(let i=0;i<response?.result?.length;i++)
      {
        if(response.result[0] != null && response.result[0] != undefined && response.result[0] != "")
        {
          switch(i)
          {
            case 0 :
            this.totalDistributors= JSON.parse(response.result[i])[0].count;
            break;
            case 1 :
            this.totalResellers= JSON.parse(response.result[i])[0].count;
            break;
            case 2 :
            this.totalAgents= JSON.parse(response.result[i])[0].count;
            break;
            case 3 :
            this.totalCustomers= JSON.parse(response.result[i])[0].count;
            break;
            case 4 :
              this.topPerfDist= JSON.parse(response.result[i]);            
            break;
            case 5 :
              this.topRecentDist= JSON.parse(response.result[i]);              
            break;
            case 6 :
              this.distributorDDLList= JSON.parse(response.result[i]);
              if(this.distributorDDLList.length > 0){
                this.selectedDistributor = this.distributorDDLList[0].id;
              }              
            break;
            // case 7 :
            //   this.chartDataRes= JSON.parse(response.result[i]);
            // break;
            // case 8 :
            //   this.chartDataAgn= JSON.parse(response.result[i]);
            // break;
            // case 9 :
            //   this.chartDataCus= JSON.parse(response.result[i]);
            // break;
            // case 10 :
            //   this.policySold= JSON.parse(response.result[i]);
            // break;
          }
          
        }
      }


    if(this.topPerfDist && this.topPerfDist.length > 0)
    {
      this.graphHeader=Object.keys(this.topPerfDist[0]).map((m:any)=>{return {key: m,type:"System.String"}});
      this.visibleColumnData =  this.graphHeader.map((m:any,index:number)=>{return {id:index.toString(),name:m.key,checked:true}}).filter((f:any)=>{return !f.name.startsWith('Hidden_') });
      this.visibleColumnsId = this.visibleColumnData.map((m:any)=>{return m.id.toString()});
      this.graphdata=this.topPerfDist.map((m:any) => {return Object.values(m)});
    }
    else
    {
      this.graphHeader = [];
      this.visibleColumnData = [];
      this.visibleColumnsId = [];
      this.graphdata = [];
    }


      // if(response.result.table1 != null && response.result.table1 != undefined && response.result.table1 != "")
      // {
      //   this.totalResellers= JSON.parse(response.result.table1)[0].count;
      // } 
      debugger;  
      this.bindAdminDashboardDistributorData();   
    }
    });
  }

  bindAdminDashboardDistributorData(){
    debugger;
    let obj={
      DistributorId:this.selectedDistributor,
      ResellerId:0,
      AgentId:0,
      SystemCompanyId:1,
      StartDate:moment(this.selectedStartDate).format('YYYY-MM-DD'),
      EndDate:moment(this.selectedEndDate).format('YYYY-MM-DD')
    }
    debugger;
    this.adminSevice.GetAminDashboardDistributorData(obj).then((response)=> { 
      debugger;
    if(response?.isSuccess && response?.result?.length > 0)
    {
      for(let i=0;i<response?.result?.length;i++)
      {
        if(response.result[0] != null && response.result[0] != undefined && response.result[0] != "")
        {
          switch(i)
          {
            case 0 :
            this.chartDataRes= JSON.parse(response.result[i]);
            break;
            case 1 :
            this.chartDataAgn= JSON.parse(response.result[i]);
            break;
            case 2 :
            this.chartDataCus= JSON.parse(response.result[i]);
            break;
            case 3 :
            this.chartDataLeads= JSON.parse(response.result[i]);
            break;
            case 4 :
              this.policySold= JSON.parse(response.result[i]);            
            break;  
          }
          
        }
      }

    if(this.policySold && this.policySold.length > 0)
    {
      this.pieChartDataSummary= {
        labels: this.policySold.map((m:any) => {return m.insurance}),
        datasets: [
          {
            data: this.policySold.map((m:any) => {return m.value}),
            backgroundColor: this.colorArray, 
            borderColor: this.colorArray,
          },
        ],
      };
    }

      this.loadChart('res');
  }})
  }

  pieChartOptionsSummary: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      
      datalabels: {
        formatter: (value: any, ctx: any) => {
          if (ctx.chart.data.labels) {
            return ctx.chart.data.labels[ctx.dataIndex];
          }
        },
      },
    },
  };
  pieChartLabelsSummary: string[] = ['Red', 'Green', 'Blue','Yellow']; // Example labels
  pieChartTypeSummary: ChartType = 'pie';
  pieChartPluginsSummary = [DatalabelsPlugin];
  pieChartDataSummary: ChartData<'pie', number[], string | string[]> = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: ['#54B66F', '#FFAD2A'], 
        borderColor: ['#54B66F', '#FFAD2A'],
      },
    ],
  };
  
  
  public pieChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: 'right',
      },
      
      datalabels: {
        formatter: (value: any, ctx: any) => {
          if (ctx.chart.data.labels) {
            return ctx.chart.data.labels[ctx.dataIndex];
          }
        },
      },
    },
  };
  public pieChartData: ChartData<'pie', number[], string | string[]> = {
    labels: [['Commission', 'Earned'], ['Commission', 'Pending']],
    datasets: [
      {
        data: [10250, 5250,5000],
        backgroundColor: ['#54B66F', '#FFAD2A'], 
        borderColor: ['#54B66F', '#FFAD2A'],
      },
    ],
  };
  pieChartLabels: string[] = ['Red', 'Green', 'Blue','Yellow']; // Example labels
  public pieChartType: ChartType = 'pie';
  public pieChartPlugins = [DatalabelsPlugin];
  

  activeTab: number = 1;
  activeTabs:number = 5;
  activeTabs1:number = 1;
  changeTab(tab: number) {
    this.activeTab = tab;
  }
  changeTabs(tab: number) {
    this.activeTabs = tab;
  }
  changeTabsone(tab: number) {
    this.activeTabs1 = tab;
  }

  //Line chart Summary
  public barChartOptionsSummary: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {
        // display: true,
        grid: {
          display: false, // Hide the x-axis grid lines
        },
                
        ticks: {
          stepSize: 50, // Set the spacing between grid lines
        },
      },
      y: {
        
        min: 0,
        
        ticks: {
          stepSize: 30, // Set the spacing between grid lines
        },
        title: {
          display: true,
          text: 'Count',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public barChartTypeSummary: ChartType = 'bar';
  public barChartPluginsSummary = [DataLabelsPlugin];
  public barChartDataSummary: ChartData<'bar'> = {
    //labels: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
    labels: this.barChartLabel,
    datasets: [
      { 
        //data: [80, 85, 100, 95, 86, 105, 90, 110, 93], 
        data: this.barChartData1,
        label: 'Previous Year(2022)', 
        backgroundColor: ['#10A0C1'], 
        borderColor: ['#10A0C1'],
        
      },
      { 
        //data: [35, 45, 40, 25, 50, 55, 56, 58, 47], 
        data: this.barChartData2,
        label: 'Current Year(2023)',
        backgroundColor: ['#FFAD2A'],
        borderColor: ['#FFAD2A']
      },        
    ],
  };


  // line charts
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {
        // display: true,
        grid: {
          display: false, // Hide the x-axis grid lines
        },
                
        ticks: {
          stepSize: 50, // Set the spacing between grid lines
        },
      },
      y: {
        
        min: 0,
        
        ticks: {
          stepSize: 30, // Set the spacing between grid lines
        },
        title: {
          display: true,
          text: '₹ (thousands)',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public barChartType: ChartType = 'bar';
  public barChartPlugins = [DataLabelsPlugin];
  public barChartData: ChartData<'bar'> = {
    //labels: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
    labels: this.barChartLabel,
    datasets: [
      { 
        //data: [80, 85, 100, 95, 86, 105, 90, 110, 93], 
        data: this.barChartData1,
        label: 'Previous Year(2022)', 
        backgroundColor: ['#10A0C1'], 
        borderColor: ['#10A0C1'],
        
      },
      { 
        //data: [35, 45, 40, 25, 50, 55, 56, 58, 47], 
        data: this.barChartData2,
        label: 'Current Year(2023)',
        backgroundColor: ['#FFAD2A'],
        borderColor: ['#FFAD2A']
      },        
    ],
  };
  // Line Charts
  private newLabel? = 'New label'; 

  public lineChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [20, 42, 40, 60, 41, 82, 62, 85],
        label: 'Series A',
        backgroundColor: '#9515181A',
        borderColor: '#10A0C1',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: '#10A0C1',
        borderWidth: 1.5,
        fill: 'origin',
      }
    ],
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
  };

  public lineChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.38,
      },
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      x: {
        grid: {
          display: false, // Hide the x-axis grid lines
        },
        ticks: {
          stepSize: 30, // Set the spacing between grid lines
        },
      },
      y: {
        position: 'left',
        grid: {
          display: false, // Hide the x-axis grid lines
        },
        ticks: {
          stepSize: 20, // Set the spacing between grid lines
        },
      },
      y1: {
        display: false,
        position: 'right',
        grid: {
          display: false,
          color: 'rgba(255,0,0,0.3)',
        },
        ticks: {
          color: 'red',
        },
      },
    },

    plugins: {
      legend: { display: false },
      annotation: {
        annotations: [
          {
            type: 'line',
            scaleID: 'x',
            // value: 'March',
            // borderColor: 'orange',
            // borderWidth: 2,
            label: {
              display: true,
              position: 'center',
              color: 'orange',
              content: 'LineAnno',
              font: {
                weight: 'bold',
              },
            },
          },
        ],
      },
    },
  };

  public lineChartType: ChartType = 'line';

  public barChartPolicyData: ChartData<'bar'> = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
    datasets: [
      { 
        data: [35, 41, 37, 28, 45, 48, 52, 53, 41], 
        label: 'Previous Year(2022)', 
        backgroundColor: ['#FEB019'], 
        borderColor: ['#FEB019'],
      },
        
    ],
  };

  public barChartPolicyOptions: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {
        
        // display: true,
        grid: {
          display: false, // Hide the x-axis grid lines
        },
               
        ticks: {
          stepSize: 20, // Set the spacing between grid lines
          
        },
      },
      y: {
        
        min: 0,
        
        ticks: {
          stepSize: 10, // Set the spacing between grid lines
        },
        title: {
          display: true,
          text: '₹ (thousands)',
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      datalabels: {
        display: false, // Hide the data labels (bar values)
      },
    },
  };

  uniqueMonthLabel = (data:any) => {
    let unique_values = data
        .map((item:any) => item.month)
        .filter(
            (value:any, index:number, current_value:any) => current_value.indexOf(value) === index
        );
    return unique_values;
  };
  uniqueYear = (data:any) => {
    let unique_values = data
        .map((item:any) => parseInt(item.year))
        .filter(
            (value:any, index:number, current_value:any) => current_value.indexOf(value) === index
        );
    return unique_values.sort();
  };

  loadChart(charFor:string){
    console.log();
    let years:number[] = [];
    let label:any=[];
    let currentYearData:any=[];
    let previousYearData:any=[];
    switch(charFor){
      case 'res':
        years = this.uniqueYear(this.chartDataDis);
        label =  this.uniqueMonthLabel(this.chartDataRes);//this.chartDataRes.map((m:any)=>{return m.month}); //Object.keys(this.chartDataDis); 
        previousYearData = this.chartDataRes.filter((f:any)=>{return f.year == years[0]}).map((m:any)=>{return  parseFloat(m.value)});//Object.values(this.chartDataDis);
        currentYearData = this.chartDataRes.filter((f:any)=>{return f.year == years[1]}).map((m:any)=>{return  parseFloat(m.value)});
        break;
      case 'agn':
        years = this.uniqueYear(this.chartDataAgn);
        label =  this.uniqueMonthLabel(this.chartDataAgn);//this.chartDataAgn.map((m:any)=>{return m.month}); //Object.keys(this.chartDataDis); 
        previousYearData = this.chartDataAgn.filter((f:any)=>{return f.year == years[0]}).map((m:any)=>{return  parseFloat(m.value)});//Object.values(this.chartDataDis);
        currentYearData = this.chartDataAgn.filter((f:any)=>{return f.year == years[1]}).map((m:any)=>{return  parseFloat(m.value)});
        break;
      case 'cus':
        years = this.uniqueYear(this.chartDataCus);
        label =  this.uniqueMonthLabel(this.chartDataCus);//this.chartDataCus.map((m:any)=>{return m.month}); //Object.keys(this.chartDataDis); 
        previousYearData = this.chartDataCus.filter((f:any)=>{return f.year == years[0]}).map((m:any)=>{return  parseFloat(m.value)});//Object.values(this.chartDataDis);
        currentYearData = this.chartDataCus.filter((f:any)=>{return f.year == years[1]}).map((m:any)=>{return  parseFloat(m.value)});
        break;
      case 'lds':
        years = this.uniqueYear(this.chartDataLeads);
        label =  this.uniqueMonthLabel(this.chartDataLeads);//this.chartDataDis.map((m:any)=>{return m.month}); //Object.keys(this.chartDataDis); 
        previousYearData = this.chartDataLeads.filter((f:any)=>{return f.year == years[0]}).map((m:any)=>{return  parseFloat(m.value)});//Object.values(this.chartDataDis);
        currentYearData = this.chartDataLeads.filter((f:any)=>{return f.year == years[1]}).map((m:any)=>{return  parseFloat(m.value)});
        break;
        
    }
    this.barChartDataSummary = {
      //labels: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
      labels: label,
      datasets: [
        { 
          //data: [80, 85, 100, 95, 86, 105, 90, 110, 93], 
          data: previousYearData,
          label: 'Previous Year('+years[0]+')', 
          backgroundColor: ['#10A0C1'], 
          borderColor: ['#10A0C1'],
        },
        { 
          //data: [35, 45, 40, 25, 50, 55, 56, 58, 47], 
          data: currentYearData,
          label: 'Current Year('+years[1]+')',
          backgroundColor: ['#FFAD2A'],
          borderColor: ['#FFAD2A']
        },        
      ],
  }
  }

  onStartDateChange(event:any){
    this.selectedStartDate = event.value;
  }

  onEndDateChange(event:any){
    this.selectedEndDate = event.value;
    setTimeout(() => {
      this.bindDashboardData();
    }, 100);
  }

  resetDateRange(){
    this.selectedStartDate = moment(this.today).add(-7, 'days').format();
    this.selectedEndDate = this.today;
  }

  setDateRange(dateRangeText:string){
    this.selectedDateRange = "Past "+ dateRangeText;
    switch(dateRangeText){
      case "7 Days" : 
      this.selectedStartDate = moment(this.today).add(-7, 'days').format();
      this.selectedEndDate = this.today;      
      break;
      case "1 Months" : 
      this.selectedStartDate = moment(this.today).add(-1, 'months').format();
      this.selectedEndDate = this.today;
      break;
      case "3 Months" : 
      this.selectedStartDate = moment(this.today).add(-3, 'months').format();
      this.selectedEndDate = this.today;
      break;
      case "6 Months" : 
      this.selectedStartDate = moment(this.today).add(-6, 'months').format();
      this.selectedEndDate = this.today;
      break;
      case "9 Months" : 
      this.selectedStartDate = moment(this.today).add(-9, 'months').format();
      this.selectedEndDate = this.today;
      break;
      case "Year" : 
      this.selectedStartDate = moment(this.today).add(-1, 'years').format();
      this.selectedEndDate = this.today;
      this.selectedDateRange = "Last " + dateRangeText;
      break;
    }
    this.bindDashboardData();
  // moment(new Date()).add(10, 'days').format('DD/MM/YYYY')
  }
}
