import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { NgxSpinnerService } from 'ngx-spinner';
import { PasswordType } from 'src/app/models/login.component.model';
import { GeneralService } from 'src/app/services/general.service';
import { LoginService } from 'src/app/services/login.service';
import swal from 'sweetalert2';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {
  loginForm: FormGroup;
  AlertMessage: string = '';
  passwordShow: boolean = false;
  IsAlert: boolean = false;
  public passwordType: string = PasswordType.pwd;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;
  constructor(private loginService: LoginService, private router: Router, private fb: FormBuilder, private spinner: NgxSpinnerService, private generalService: GeneralService) {
    this.loginForm = this.fb.group({
      "PhoneNumber": ['', Validators.required],
      "Password": ['', [Validators.required]],
    });
    sessionStorage.clear();
  }
  async submit() {
    this.spinner.show();
    if (this.loginForm.get('PhoneNumber')?.value != null && typeof (this.loginForm.get('PhoneNumber')?.value) == 'object') {
      let phone = this.loginForm.get('PhoneNumber')?.value;
      this.loginForm.get('PhoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
    }
    let obj={
      Password : this.loginForm.value.Password,
      PhoneNumber:this.loginForm.value.PhoneNumber.toString()
    }
    const res = await this.loginService.AuthenticateUser(obj);
    if (res?.isSuccess) {
      let data = res.result;
      this.generalService.setAccessToken(data.accessToken);
      this.generalService.setUniqueuserid(data.uniqueUserId);
      this.generalService.setUserType(data.userType);
      this.router.navigate(['/distributorInfo']);
    }
    else {
      swal.fire({
        position: 'center',
        icon: 'error',
        title: res?.message,
        showConfirmButton: false,
        timer: 3000,
      });
    }

    this.spinner.hide();
  }
  ShowPassword() {
    if (!this.passwordShow) {
      this.passwordType = PasswordType.txt;
      this.passwordShow = true;
    }
    else {
      this.passwordType = PasswordType.pwd;
      this.passwordShow = false;
    }
  }
  onCountryChangeNew(e: any) {
    this.loginForm.get('phoneNumber')?.setValue("");
  }
  confirmPassword() {
    
  }
}
