import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { StepperDialogComponent } from 'src/app/components/stepper-dialog/stepper-dialog.component';
import { Item } from 'src/app/models/SelectItems';
import { AdminService } from 'src/app/services/admin.service';
import { GeneralService } from 'src/app/services/general.service';
import { LookUpService } from 'src/app/services/lookup.service';
import { MenuService } from 'src/app/services/menu.service';

@Component({
  selector: 'app-agent-commissions',
  templateUrl: './agent-commissions.component.html',
  styleUrls: ['./agent-commissions.component.css']
})
export class AgentCommissionsComponent {
  @ViewChild(StepperDialogComponent) stepperDialogComponent!: StepperDialogComponent;
  graphdata: any[] = [];
  visibleColumnData: Item[] = [];
  visibleColumnsId:any[] = [];
  graphHeader :any[]=[];
  canView:boolean = false;
  canEdit:boolean = false;
  canDelete:boolean = false;
  canCreate:boolean = false;
  viewUrl:string="";
  editUrl:string="";
  createUrl:string="";
  deleteUrl:string="";
  insurancePartnersList:any=[];
  insuranceCompanyId:number=1;
  vehicleTypeId:number=1;
  year:number=2024;
  month:number=1;
  todayDate = new Date();
  currentYear = this.todayDate.getFullYear();
  selectedYear:number=this.currentYear;
  yearList:any=[];
  monthNameArray:string[]=["January","February","March","April","May","June","July","August","September","October","November","December"];
  monthList:any=[];
  productsList:any=[{name:"Scooter/Mopped",id:0},{name:"Motor Cycle",id:2},{name:"Pvt Car",id:1},{name:"Commercial Vehicle",id:3}];
  addBeneficiary() {
    this.stepperDialogComponent.show = true;
  }

  constructor(private _lookupService: LookUpService, private adminService: AdminService, private router:Router,private menuService:MenuService,private generalService: GeneralService) {
    let obj = this.router.getCurrentNavigation()?.extras?.state;
    if(obj)
    {      
      this.getPermissions(parseInt(obj['id'])??0);
    }
    this.getAgentCommissionDetails();
    this.loadFilterData();
  }

  onActionClick(data:any)
  {
    if(data)
    {
      if(data.action=="View")
      {
        this.router.navigateByUrl(this.viewUrl,{ state: { id:data.id }});
      }
      else if(data.action=="Edit")
      {
        this.router.navigateByUrl(this.editUrl,{ state: { id:data.id }});
      } 
    }
  }

  onCreateBtnClick(data:any)
  {
    this.router.navigate([this.createUrl]);
  }

  async getAgentCommissionDetails() {
    debugger;
    //let obj ={VehicleTypeId:3,Year:2025,Month:1,InsuranceCompanyId:11};
    let obj ={VehicleTypeId:this.vehicleTypeId,Year:this.year,Month:this.month,InsuranceCompanyId:this.insuranceCompanyId};
    const res = await this.adminService.GetInputCommissionListing(obj);    
    this.graphHeader = [];
    this.visibleColumnData = [];
    this.visibleColumnsId = [];
    this.graphdata =[];
    if (res != null && res.result) {
      this.graphdata = res.result.data;
      let index=3;
      let sort:string='asc';
      console.log(this.graphdata.sort( (a, b) => {
        if(sort == 'desc')
        {
          if (a[index].toString().toLowerCase() > b[index].toString().toLowerCase()) {
            return -1;
          }
          if (b[index].toString().toLowerCase() > a[index].toString().toLowerCase()) {
              return 1;
          }          
        }
        else
        {
          if (a[index].toString().toLowerCase() < b[index].toString().toLowerCase()) {
            return -1;
          }
          if (b[index].toString().toLowerCase() < a[index].toString().toLowerCase()) {
              return 1;
          }  
        }
        return index;
      }));
      this.graphHeader = res.result.header;
      this.visibleColumnData =  res.result.header.map((m:any,index:number)=>{return {id:index.toString(),name:m.key,checked:true}}).filter((f:any)=>{return !f.name.startsWith('Hidden_') });
      this.visibleColumnsId = this.visibleColumnData.map((m:any)=>{return m.id.toString()});
    }
  }

  async getPermissions(menuId:number)
  {
    let obj = {uniqueUserId:this.generalService.getUniqueuserid(),menuId:menuId};
    let res = await this.menuService.GetMenuPermissionsByUserId(obj);
    if(res && res.isSuccess)
    {
      if(res.result.userMenus.length > 0)
      {
        res.result.userMenus.forEach((element:any) => {
          if(element.permissionName.toLowerCase() =="create")
          {
            this.canCreate = true;
            this.createUrl = element.menuCommand;
          }
          else if(element.permissionName.toLowerCase() =="edit")
          {
            this.canEdit = true;
            this.editUrl = element.menuCommand;
          }
          else if(element.permissionName.toLowerCase() =="view")
          {
            this.canView = true;
            this.viewUrl = element.menuCommand;
          }
          else if(element.permissionName.toLowerCase() =="delete")
          {
            this.canDelete = true;
            this.deleteUrl = element.menuCommand;
          }
        });
      }      
    }
  }

  async loadFilterData()
  {
    for(let j=0;j<2;j++){
      let year = this.currentYear + j;
      this.yearList.push({id:year,name:year.toString()});
    }

    for(let month= 0; month <12; month++)
    {
      this.monthList.push({id:month+1,name:this.monthNameArray[month]});
    }

    let response = await this._lookupService.GetInsurancePartnersList();
      if (response?.isSuccess) {
        this.insurancePartnersList = response.result.map((m:any)=> {return {name:m.insuranceName,id:m.insuranceId}});
      }

  }
}
