<div class="main-container row p-0 m-0">
    <div class="col-2 sidebar bg-white p-0">
        <app-sidebar class="app_sidebar"></app-sidebar>
    </div>
    <div class="col-10 mainscreen bg-grey6 p-0 d-flex flex-column h-100">
        <app-appbar class="fixed-header"></app-appbar>
        <div class="w-100 pt-3 px-4 content_container">
            <ng-content></ng-content>
        </div>
        <app-footer class="app_footer"></app-footer>
    </div>
</div>