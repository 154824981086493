<app-mainscreen>
    <div class="view_box_container">
        <div class="">
            <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-8 col-12">
                    <div class="view_div">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="view_block">
                                    <ul>
                                        <li>
                                            <img src="../../../../assets/images/user.png" />
                                        </li>
                                        <li>
                                            <!-- <p><b>{{distributorFormControl['distributorFirstName'].value}} {{distributorFormControl['distributorLastName'].value}}</b></p>
                                            <p>Distributor ID : {{distributorFormControl['distributorId'].value}} </p> -->
                                        </li>
                                        <li><span>Active</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 col-12">
                                <div class="view_block">
                                    <label><img src="../../../../assets/images/document.png" /> &nbsp; Contract Status : </label>
                                     <!-- <span class="status"> {{distributorFormControl['distributorStatus'].value == '1' ? 'Active' : 'Inactive'}}</span> <br> -->
                                    <label><img src="../../../../assets/images/watch_later.png" /> &nbsp;Last Login : </label> <span>2hr Ago</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-4 col-12">
                    <div class="view_buttons">
                        <button class="edit_button"><img src="../../../../assets/images/message.png" />&nbsp; Lorem </button>
                        <app-custom-button className="mt-3" text="Edit" [width]="'auto'" [height]="'34px'" leadingIconName="edit" (click)="backBtClick()">Edit</app-custom-button> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="view_box_container mt-3">
        <h5 class="logs_day_title">16th Oct</h5>
        <div class="row">
            <div class="col-lg-1 col-md-3 col-sm-3 col-3">
                <div class="time_label">
                    <p>12:30 AM</p>
                </div>
            </div>
            <div class="col-lg-11 col-md-11 col-sm-11 col-12">
                <div class="log_details">
                    <ul>
                        <li><img src="../../../../assets/images/dark-add.png" /></li>
                        <li>
                            <p><b>New Reseller Add</b></p>
                            <p>Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>                        
                        </li>
                        <li><a>View Details</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-1 col-md-3 col-sm-3 col-3">
                <div class="time_label">
                    <p>02:30 AM</p>
                </div>
            </div>
            <div class="col-lg-11 col-md-11 col-sm-11 col-12">
                <div class="log_details">
                    <ul>
                        <li><img src="../../../../assets/images/dark-edit.png" /></li>
                        <li>
                            <p><b>Reseller Details Edited</b></p>
                            <p>Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>                        
                        </li>
                        <li><a>View Details</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-1 col-md-3 col-sm-3 col-3">
                <div class="time_label">
                    <p>02:30 AM</p>
                </div>
            </div>
            <div class="col-lg-11 col-md-11 col-sm-11 col-12">
                <div class="log_details">
                    <ul>
                        <li><img src="../../../../assets/images/spam.png" /></li>
                        <li>
                            <p><b>Agent Blocked</b></p>
                            <p>Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>                        
                        </li>
                        <li><a>View Details</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="view_box_container mt-3">
        <h5 class="logs_day_title">16th Oct</h5>
        <div class="row">
            <div class="col-lg-1 col-md-3 col-sm-3 col-3">
                <div class="time_label">
                    <p>12:30 AM</p>
                </div>
            </div>
            <div class="col-lg-11 col-md-11 col-sm-11 col-12">
                <div class="log_details">
                    <ul>
                        <li><img src="../../../../assets/images/dark-add.png" /></li>
                        <li>
                            <p><b>New Reseller Add</b></p>
                            <p>Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>                        
                        </li>
                        <li><a>View Details</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-1 col-md-3 col-sm-3 col-3">
                <div class="time_label">
                    <p>02:30 AM</p>
                </div>
            </div>
            <div class="col-lg-11 col-md-11 col-sm-11 col-12">
                <div class="log_details">
                    <ul>
                        <li><img src="../../../../assets/images/dark-edit.png" /></li>
                        <li>
                            <p><b>Reseller Details Edited</b></p>
                            <p>Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>                        
                        </li>
                        <li><a>View Details</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-1 col-md-3 col-sm-3 col-3">
                <div class="time_label">
                    <p>02:30 AM</p>
                </div>
            </div>
            <div class="col-lg-11 col-md-11 col-sm-11 col-12">
                <div class="log_details">
                    <ul>
                        <li><img src="../../../../assets/images/spam.png" /></li>
                        <li>
                            <p><b>Agent Blocked</b></p>
                            <p>Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>                        
                        </li>
                        <li><a>View Details</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="view_box_container mt-3">
        <h5 class="logs_day_title">16th Oct</h5>
        <div class="row">
            <div class="col-lg-1 col-md-3 col-sm-3 col-3">
                <div class="time_label">
                    <p>12:30 AM</p>
                </div>
            </div>
            <div class="col-lg-11 col-md-11 col-sm-11 col-12">
                <div class="log_details">
                    <ul>
                        <li><img src="../../../../assets/images/dark-add.png" /></li>
                        <li>
                            <p><b>New Reseller Add</b></p>
                            <p>Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>                        
                        </li>
                        <li><a>View Details</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-1 col-md-3 col-sm-3 col-3">
                <div class="time_label">
                    <p>02:30 AM</p>
                </div>
            </div>
            <div class="col-lg-11 col-md-11 col-sm-11 col-12">
                <div class="log_details">
                    <ul>
                        <li><img src="../../../../assets/images/dark-edit.png" /></li>
                        <li>
                            <p><b>Reseller Details Edited</b></p>
                            <p>Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>                        
                        </li>
                        <li><a>View Details</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-1 col-md-3 col-sm-3 col-3">
                <div class="time_label">
                    <p>02:30 AM</p>
                </div>
            </div>
            <div class="col-lg-11 col-md-11 col-sm-11 col-12">
                <div class="log_details">
                    <ul>
                        <li><img src="../../../../assets/images/spam.png" /></li>
                        <li>
                            <p><b>Agent Blocked</b></p>
                            <p>Worem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis.</p>                        
                        </li>
                        <li><a>View Details</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</app-mainscreen>
