
<div class="dialog-container" *ngIf="show">
    <!-- Step 1 -->
  
    <div *ngIf="currentStep === 1" class="row">
      <div class="col-md-6">
        <div class="card" style="width: 512px; height: auto; border-radius: 16px">
          <div class="card-body">
            <div style="display: flex; justify-content: space-between">
              <h3
                class="card-title"
                style="font-weight: 500; font-size: 20px; line-height: 28px"
              >
                Add Distributor Details
              </h3>
  
            </div>
  
            <form  (ngSubmit)="nextStep()">
              <div class="form-group">
                <app-text-form-field-outlined
                  title="Distributor Id"
                  placeHolder=""
                ></app-text-form-field-outlined>
              </div>
  
              <div class="form-group">
                <app-text-form-field-outlined
                  title="Distributor Name"
                  placeHolder=""
                ></app-text-form-field-outlined>
              </div>
  
              
  
              <div class="form-group row">
                <div class="col">
                  <app-text-form-field-outlined
                    title="Contact"
                    placeHolder=""
                  ></app-text-form-field-outlined>
                </div>
  
                <div class="col">
                  <app-text-form-field-outlined
                    title="Email"
                    placeHolder=""
                  ></app-text-form-field-outlined>
                </div>
              </div>
  
           
  
                <div class="mr-2 mt-3" style="display: flex; flex-direction: row; justify-content: space-between">
                  <app-custom-button
                    text="Cancel"
                    [height]="'36px'"
                  
                    (click)="cancel()"
                    [backgroundColor]="'var(--grey4)'"
                    [textColor]="'var(--white)'"
                    [hoveredBackgroundColor]="'var(--main2)'"
                  ></app-custom-button>
  
                  <app-custom-button
                    text="Submit"
                    (click)="nextStep()"
                    [height]="'36px'"
                    type="submit"
                  ></app-custom-button>
                </div>
            
            </form>
          </div>
        </div>
      </div>
    </div>
  
    
   
  </div>
  <div *ngIf="currentStep === 2" class="dialog-container">
    <div>
      <div class="card" style="width: 512px; height: auto; border-radius: 16px">
        <div
          style="
            box-shadow: 0px 0px 6px 2px rgba(var(--shadow-color-rgb), 0.1);
            border-radius: 16px;
            z-index: 1;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 30px;
          "
          class="bg-white"
        >
          <!-- green tick icon -->

          <div
            style="
              height: 80px;
              width: 80px;
              display: flex;
              align-items: center;
              justify-content: center;
              background-color: rgba(var(--green-rgb), 0.12);
              border-radius: 100px;
            "
          >
            <div
              style="
                height: 36px;
                width: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--green);
                border-radius: 100px;
                color: var(--white);
              "
            >
              <span class="material-symbols-outlined m-0 p-0"> done </span>
            </div>
          </div>

          <!-- main text -->

          <label
            class="text-grey1 mt-4"
            style="font-size: 32px; font-weight: 600"
            >Distributor Added Successfully!</label
          >

        

          <app-custom-button
            text="Close"
            [width]="'132px'"
            [height]="'35px'"
            (click)="skipStep()"
            class="mt-5"
          ></app-custom-button>
        </div>
      </div>
    </div>
  </div>