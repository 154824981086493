import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAppConfig } from '../models/AppConfig';


@Injectable()
export class AppConfig {
  static settings: IAppConfig;
  constructor(private http: HttpClient) { }
  load() {
    const jsonFile = `/assets/config.json`;
    const headersDetail = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('NoAuthorization', 'True');
    return new Promise<void>((resolve, reject) => {
      this.http.get(jsonFile, {
        headers: headersDetail
      }).toPromise().then((response: any) => {
        AppConfig.settings = (response);
        resolve();
      }).catch((response: any) => {
        reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
      });
    });
  }
}
