<app-table-view
    [visibleColumnData] = "visibleColumnData"
    [visibleColumnsId] = "visibleColumnsId"
    [graphdata]="graphdata"
    [graphHeader]="graphHeader"
    title=""
    tableBackground="var(--grey3)"
    buttonTitle=""
    [showIconButtonEnd]="true"
    [showPaginator]="true"  
    (onActionClick)="onActionClick($event)"
    [canCreate]="false"
    [canView]="true"
    [canDelete]="false"
    [canEdit]="false">
</app-table-view>
<div class="overlay" *ngIf="isLoginDetailsPopup">
    <div class="popup2 position-relative col-md-4">
      <div class="modal-header tooltip_">
        <!-- <h4 class="modal-title">Sign In</h4> -->
        <h3>Login Details </h3>
        <button type="button" class="close pull-right" aria-label="Close"  (click)="closeLoginDetailsPopup()">
          <span aria-hidden="true"></span>
       </button>
      </div>
      <div class="modal-body">
            <div class="row">
                <div class="col-md-4 text-grey3 font-13">Device Type</div>
                <div class="col-md-8 text-grey2 font-13">{{securityLogsData?.deviceType??""}}</div>
                <div class="col-md-4 text-grey3 font-13">Device Name</div>
                <div class="col-md-8 text-grey2 font-13">{{securityLogsData?.deviceName??""}}</div>
                <div class="col-md-4 text-grey3 font-13">IP Address</div>
                <div class="col-md-8 text-grey2 font-13">{{securityLogsData?.ipAddress??""}}</div>
                <div class="col-md-4 text-grey3 font-13">Time Stamp</div>
                <div class="col-md-8 text-grey2 font-13">{{securityLogsData?.loginAtStr??""}}</div>
                <div class="col-md-4 text-grey3 font-13">Result</div>
                <div class="col-md-8 text-grey2 font-13">{{securityLogsData?.result??""}}</div>
                <div class="col-md-4 text-grey3 font-13">Reason</div>
                <div class="col-md-8 text-grey2 font-13">{{securityLogsData?.reason??""}}</div>
                <!-- <div class="col-md-6 text-grey3 font-13">Device Type</div>
                <div class="col-md-6">{{securityLogsData?.deviceType??""}}</div> -->
            </div>
      </div>
    </div>
  </div>