import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { PasswordType } from 'src/app/models/login.component.model';
import { LoginService } from 'src/app/services/login.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  loginForm: FormGroup;
  AlertMessage: string = '';
  passwordShow: boolean = false;
  IsAlert: boolean = false;
  public passwordType: string = PasswordType.pwd;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;
  constructor(private loginService: LoginService, private router: Router, private fb: FormBuilder, private spinner: NgxSpinnerService,private generalService: GeneralService) {
    this.loginForm = this.fb.group({
      "PhoneNumber": ['', Validators.required],
      "Password": ['', [Validators.required]],
    });
    sessionStorage.clear();
  }
  async submit() {
    this.spinner.show();
    if (this.loginForm.get('PhoneNumber')?.value != null && typeof (this.loginForm.get('PhoneNumber')?.value) == 'object') {
      let phone = this.loginForm.get('PhoneNumber')?.value;
      this.loginForm.get('PhoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
    }
    let obj={
      Password : window.btoa(this.loginForm.value.Password),
      PhoneNumber:this.loginForm.value.PhoneNumber.toString(),
      Latitude:this.generalService.getUserLatitude(),
      Longitude: this.generalService.getUserLongitude()
    }
    const res = await this.loginService.AuthenticateUser(obj);
    if (res?.isSuccess) {
      let data = res.result;
      debugger
      if(data.isAuthenticated)
      {
        this.generalService.setAccessToken(data.accessToken);
        this.generalService.setUniqueuserid(data.uniqueUserId);
        this.generalService.setUserType(data.userType);
        this.generalService.setUserDisplayName(data.firstName + " " + data.lastName);
        this.generalService.setDataToLocalStorage('gender',data.gender);
        this.generalService.setDataToLocalStorage('displayid',data.displayId);
        if(data.userType == 2)
        {
          this.router.navigate(['/admin-dashboard']);
        }
        else if(data.userType == 3)
        {
          this.router.navigate(['/dashboard']);
        }
        else if(data.userType == 4)
        {
          this.router.navigate(['/reseller-dashboard']);
        }
        else if(data.userType == 8)
        {
          this.router.navigate(['/company-dashboard']);
        }
        
        //this.router.navigate(['/distributors']);
      }
      else
      {
        swal.fire({
          position: 'center',
          icon: 'error',
          title: data?.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
    else {
      swal.fire({
        position: 'center',
        icon: 'error',
        title: res?.message,
        showConfirmButton: false,
        timer: 3000,
      });
    }

    this.spinner.hide();
  }
  ShowPassword() {
    if (!this.passwordShow) {
      this.passwordType = PasswordType.txt;
      this.passwordShow = true;
    }
    else {
      this.passwordType = PasswordType.pwd;
      this.passwordShow = false;
    }
  }
  onCountryChangeNew(e: any) {
    this.loginForm.get('phoneNumber')?.setValue("");
  }  

  passKeyPress(event:any){
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 32) {
      return false;
    }
    return true;
  }
}

export class DataInjectService {
  public static dataToBeShared: any;
  public static totalParents: number;
  public static allParents: any;
}