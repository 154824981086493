<app-mainscreen>
    <app-simple-page-heading [breadcrumbs]="['Dashboard > Commission Input']" title="Commission Input" ></app-simple-page-heading>
    <div class="box_container mt-4 pt-2 rounded-bottom1-0">
        <div class="box-header mb-4 w-100 pl-2">
            <label class="m-0 box_title_22 pl-1"> Commission Input </label>
        </div>
        <div class="row w-100">
            <div class="col-md-11">
                <div class="row">
                    <div class="col-md-3">
                        <select class="form-select input_ text-grey3" name="insuranceCompanyId" [(ngModel)]="insuranceCompanyId">
                            <ng-container *ngFor="let item of insurancePartnersList">
                                 <option  [value]="item.id">
                                     {{item.name}} {{item.id}}
                                 </option>
                            </ng-container>
                         </select>
                         <label class="label_">Insurance Company</label>
                    </div>
                    <div class="col-md-3">
                        <select class="form-select input_ text-grey3" name="vehicleTypeId" [(ngModel)]="vehicleTypeId">
                            <ng-container *ngFor="let item of productsList">
                                 <option  [value]="item.id">
                                     {{item.name}}
                                 </option>
                            </ng-container>
                         </select>
                         <label class="label_">Product Name</label>
                    </div>
                    <div class="col-md-3">
                        <select class="form-select input_ text-grey3" name="year" [(ngModel)]="year">
                            <ng-container *ngFor="let item of yearList">
                                 <option  [value]="item.id">
                                     {{item.name}}
                                 </option>
                            </ng-container>
                         </select>
                         <label class="label_">Year</label>
                    </div>
                    <div class="col-md-3">
                        <select class="form-select input_ text-grey3" name="month" [(ngModel)]="month">
                            <ng-container *ngFor="let item of monthList">
                                 <option  [value]="item.id">
                                     {{item.name}}
                                 </option>
                            </ng-container>
                         </select>
                         <label class="label_">Month</label>
                    </div>
                </div>
            </div>
            <div class="col-md-1">
                <app-custom-button text="Filter" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="getAgentCommissionDetails()">                    
                </app-custom-button>
            </div>
        </div>
    </div>
    <app-table-view
    [visibleColumnData] = "visibleColumnData"
    [visibleColumnsId] = "visibleColumnsId"
    [graphdata]="graphdata"
    [graphHeader]="graphHeader"
    title=".."   
    tableBackground="var(--grey3)"
    buttonTitle="Create Commission Input"
    [showIconButtonEnd]="true"
    [showPaginator]="true"  
    (onActionClick)="onActionClick($event)"
    (onCreateBtnClick)="onCreateBtnClick($event)"
    [canCreate]="canCreate"
    [canView]="canView"
    [canDelete]="canDelete"
    [canEdit]="canEdit"
    [className]="'rounded-top1-0'"
  ></app-table-view>
  <app-stepper-dialog></app-stepper-dialog>
  </app-mainscreen>