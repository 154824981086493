<div class="row">
    <div class="col-md-4 fs-13">
        {{entriesInfo}}
    </div>
    <div class="paginator col-md-8">
        <span class="fs-13 mx-1">Show</span>
        <select class="fs-13 mx-1" (change)="onPageSizeChange($event)">
            <option *ngFor="let size of pageSizes" [value]="size" [selected]="size === selectedPageSize">{{ size }}</option>
        </select>
        <span class="fs-13 mx-1">Entries</span>    
        <button (click)="prevPage()" [disabled]="currentPage === 1" class="btn-prev"></button>
        <ng-container *ngFor="let page of pages">
            <button (click)="goToPage(page)" [class.active]="currentPage === page" [ngClass]="{'no-page': page == '...'}">{{ page }}</button>
        </ng-container>     
        <button (click)="nextPage()" [disabled]="currentPage === totalPages" class="btn-next"></button>  
    </div>
</div>