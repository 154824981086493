import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[DigitsOnly]'
})
export class DigitsOnly {
  // only positive integers are allowed 
  private regex: RegExp = new RegExp(/^[0-9]/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Special keys to press other than any characters
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }
    if (!event.key.match(this.regex)) {
      event.preventDefault();
    }
  }

}
