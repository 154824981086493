import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { SidebarState } from './state/sidebar.state';
import { MenuService } from 'src/app/services/menu.service';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  selectedMenuComponent: any = '';
  menuList:any=[];
  userType:string='';
  profileUrl:string='';
  changeSelectedMenuParent(title: any) {
    this.selectedMenuComponent = title;
  }

  constructor(private menuService:MenuService,private generalService: GeneralService,private store: Store<{ sidebar: SidebarState }>) {
    this.userType = this.generalService.getUserType()??'';
    this.profileUrl = this.userType =='3' ? '/distributor-profile' : this.userType =='4' ? '/reseller-profile' : '';
  }

  ngOnInit(): void {
    if(this.generalService.getUniqueuserid() != '' && this.menuList.length ==0)
    {
        this.loadMenus();
    }    
    this.store.select('sidebar').subscribe((state) => {
      this.selectedMenuComponent = state.selectedMenu;
    });
  }

  handleSwitchToggle(e: any) {
    alert(e);
  }

  async loadMenus()
  {
    let obj = {uniqueUserId:this.generalService.getUniqueuserid()};
    let res = await this.menuService.GetUserMenuIMS(obj);
    if(res && res.isSuccess)
    {
      this.menuList =res.result;
      console.log(this.menuList);
    }
  }
}
