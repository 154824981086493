import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../shared/AppConfig';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  protected _appset = AppConfig.settings;
  constructor(private http: HttpClient) { }

  async GetUserMenu(data: any) {
    const url = environment.APIUrl + 'menu/GetUserMenu';
    return this.http.post<ApiResponse>(url, data)
      .toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
  }

  async GetUserMenuIMS(data: any) {
    const url = environment.APIUrl + 'menu/GetUserMenuIMS';
    return this.http.post<ApiResponse>(url, data)
      .toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
  }

  async GetRoles() {
    const url = environment.APIUrl + 'menu/GetRoles';
    return this.http.get<ApiResponse>(url)
      .toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
  }

  async GetRolesDDL() {
    const url = environment.APIUrl + 'menu/GetRolesDDL';
    return this.http.get<ApiResponse>(url)
      .toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
  }

  async GetRolesPermissionById(data: any) {
    const url = environment.APIUrl + 'menu/GetRolesPermissionById?roleId='+data;
    return this.http.get<ApiResponse>(url)
      .toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
  }

  async GetMenuPermissionByRoleId(data: any) {
    const url = environment.APIUrl + 'menu/GetMenuPermissionByRoleId?roleId='+data;
    return this.http.get<ApiResponse>(url)
      .toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
  }

  async SaveUpdateRoleWithPermission(data: any) {
    const url = environment.APIUrl + 'menu/SaveUpdateRoleWithPermission';
    return this.http.post<ApiResponse>(url, data)
      .toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
  }

  async GetMenuPermissionsByUserId(data: any) {
    const url = environment.APIUrl + 'menu/GetMenuPermissionsByUserId';
    return this.http.post<ApiResponse>(url, data)
      .toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
  }
}
