<app-mainscreen>
    <div class="box_container">
        <div class="box_header flex-column">
            <label class="box_title_18 border_bottom_grey5 w-100 mb-0 pb-2">Commission Inputs</label>
            <div class="row w-100">
                <div class="col-12">
                  <ul class="d-flex justify-content-between align-items-center mb-0 flex-row p-3 text-center list-item-container">
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 1}">
                        <img src="/assets/svgIcon/25_.svg" class="logo" />
                        Step 1 : Select Insurers
                    </li> 
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 2}">
                        <img src="/assets/svgIcon/50_.svg" class="logo" />
                        Step 2 : Select Products & Details
                    </li> 
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 3}">
                        <img src="/assets/svgIcon/75_.svg" class="logo" />
                        Step 3 : Commission Output
                    </li> 
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 4}">
                        <img src="/assets/svgIcon/100_.svg" class="logo" />
                        Step 4 : Summary
                    </li>            
                  </ul>
                </div>
              </div> 
        </div>       
    </div>
    <div class="box_container mt-3"> 
        <div id="comprehensive" class="w-100" [ngClass]="{'d-block': activeTab == 1,'d-none' : activeTab !=1}">
            <form id="kt_account_profile_details_form" class="form" novalidate="novalidate">
                <div class="box_header pb-0">
                    <span class="box_title_18">
                        Select Insurers
                    </span>                
                    <app-custom-button text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(2)">
                    </app-custom-button>
                </div>
                <div class="p-lr-10">
                    <label class="box_title_18 border_top_grey5 w-100 mb-0 pb-2"></label>                
                    <div class="row">
                        <ng-container *ngFor="let insurer of insurancePartnersList">
                            <div class="col-2 mb-2" (click)="onSelectInsurer(insurer.value,insurer.text)">
                                <div class="card" [ngClass]="{'selected': insurer.value == insurerId}">
                                    <!-- <div class="span">{{insurer.text}}</div> -->
                                    <img class="img-fluid" title="{{insurer.text}}" alt="{{insurer.text}}" src="/assets/images/insurance_companies/{{insurer.value}}.png">
                                </div>
                            </div>
                        </ng-container>                            
                    </div>
                </div>        
            </form>
        </div>
        <div id="comprehensive" class="tab-pane w-100" [ngClass]="{'d-block': activeTab == 2,'d-none' : activeTab !=2}">
            <div class="box_header pb-0">
                <span class="box_title_18">
                    Select Products & Details
                </span>
                <div class="d-flex">
                    <app-custom-button className="btn_outline mr-2" text="Previous" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(1,true)"></app-custom-button> 
                    <app-custom-button text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(3)"></app-custom-button>
                </div>
            </div>
            <div class="p-lr-10" style="min-height: 400px;">
                <label class="box_title_18 border_top_grey5 w-100 mb-0 pb-2"></label> 
                <div class="row">
                    <ng-container *ngFor="let product of productsList">
                        <div class="col-2 mb-2 w-20" (click)="onSelectProduct(product.value,product.text)">
                            <div class="card mh-102 bg-image bg-img-width" [ngClass]="{'selected': product.value == productId}" style="background-image:url('/assets/images/insurance_product/{{product.value}}.png')">
                                <div class="span mt-auto pl-3 pb-2">{{product.text}}</div>
                            </div>
                        </div>
                    </ng-container>                            
                </div>
                <div class="row pb-2 mt-2 pt-2 border-top">
                    <div class="col-md-9">
                        <div class="row">
                            <div class="col-12 mb-3">Select Region</div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 position-relative">
                                <app-multi-dropdown  class="w-100 input_"
                                    [items]="rtoStateList" 
                                    [showSearch]="true" 
                                    [showAll]="true" 
                                    [showStatus]="false"
                                    [showError]="true" 
                                    placeholder="State(s)" 
                                    className=""
                                    fontColor="var(--grey3)"
                                    fontSize="15px"
                                    id="State"
                                    (itemChange)="onStateSelect($event)">
                                </app-multi-dropdown>
                                <label class="label_ required">State(s)</label>
                            </div>
                            <div class="col-md-4 position-relative">
                                <app-multi-dropdown  class="w-100 input_"
                                    [items]="rtoCityList" 
                                    [showSearch]="true" 
                                    [showAll]="false" 
                                    [showStatus]="false"
                                    [showError]="true" 
                                    placeholder="City(s)" 
                                    className=""
                                    fontColor="var(--grey3)"
                                    fontSize="15px"
                                    id="City"
                                    (itemChange)="onCitySelect($event)">
                                </app-multi-dropdown>
                                <label class="label_ required">City(s)</label>
                            </div>
                        </div>
                        <div class="row pb-2">
                            <div class="col-12 mb-3">Select Vehicle Data</div>
                        </div>
                        <div class="row">
                            <div class="col-md-4 position-relative">
                                <app-multi-dropdown  class="w-100 input_"
                                    [items]="makeList" 
                                    [showSearch]="true" 
                                    [showAll]="true" 
                                    [showStatus]="false"
                                    [showError]="true" 
                                    placeholder="Make(s)" 
                                    className=""
                                    fontColor="var(--grey3)"
                                    fontSize="15px"
                                    id="Make"
                                    (itemChange)="onMakeSelect($event)">
                                </app-multi-dropdown>
                                <label class="label_">Make(s)</label>
                            </div>
                            <div class="col-md-4 position-relative">
                                <app-multi-dropdown  class="w-100 input_"
                                    [items]="modelList" 
                                    [showSearch]="true" 
                                    [showAll]="false" 
                                    [showStatus]="false"
                                    [showError]="true" 
                                    placeholder="Model(s)" 
                                    className=""
                                    fontColor="var(--grey3)"
                                    fontSize="15px"
                                    id="Model"
                                    (itemChange)="onModelSelect($event)">
                                </app-multi-dropdown>
                                <label class="label_">Model(s)</label>
                            </div>
                            <div class="col-md-4 position-relative">
                                <app-multi-dropdown  class="w-100 input_"
                                    [items]="VariantList" 
                                    [showSearch]="true" 
                                    [showAll]="true" 
                                    [showStatus]="false"
                                    [showError]="true" 
                                    placeholder="Variant(s)" 
                                    className=""
                                    fontColor="var(--grey3)"
                                    fontSize="15px"
                                    id="Variant"
                                    (itemChange)="onVariantSelect($event)">
                                </app-multi-dropdown>
                                <label class="label_">Variant(s)</label>
                            </div>
                            <div class="col-md-4 position-relative">
                                <app-multi-dropdown  class="w-100 input_"
                                    [items]="fuelTypeList" 
                                    [showSearch]="true" 
                                    [showAll]="false" 
                                    [showStatus]="false"
                                    [showError]="true" 
                                    placeholder="Fuel(s)" 
                                    className=""
                                    fontColor="var(--grey3)"
                                    fontSize="15px"
                                    id="Fuel"
                                    (itemChange)="onFuelSelect($event)">
                                </app-multi-dropdown>
                                <label class="label_ required">Fuel(s)</label>
                            </div>
                            <div class="col-md-4">
                                <!-- <select class="form-select input_" (change)='onPolicyTypeChange()' name="policyType" [(ngModel)]="selectedPolicyType" placeholder="Select Policy Type">
                                   <ng-container *ngFor="let policyType of policyTypeList">
                                        <option  [value]="policyType.id">
                                            {{policyType.name}}
                                        </option>
                                   </ng-container>
                                </select> -->
                                <app-multi-dropdown  class="w-100 input_"
                                    [items]="policyTypeList" 
                                    [showSearch]="true" 
                                    [showAll]="false" 
                                    [showStatus]="false"
                                    [showError]="true" 
                                    placeholder="Policy Type(s)" 
                                    className=""
                                    fontColor="var(--grey3)"
                                    fontSize="15px"
                                    id="PolicyType"
                                    (itemChange)="onPolicyTypeSelect($event)">
                                </app-multi-dropdown>
                                <label class="label_ required">Policy Type</label>
                            </div>
                            <div class="col-md-4" *ngIf="productId == 1">
                                <app-multi-dropdown  class="w-100 input_"
                                    [items]="vehicleTypeList" 
                                    [showSearch]="true" 
                                    [showAll]="false" 
                                    [showStatus]="false"
                                    [showError]="true" 
                                    placeholder="Vehicle Type(s)" 
                                    className=""
                                    fontColor="var(--grey3)"
                                    fontSize="15px"
                                    id="VehicleType"
                                    (itemChange)="onvehicleTypeSelect($event)">
                                </app-multi-dropdown>
                                <label class="label_ required">Vehicle Type</label>
                            </div>
                            <div class="col-md-4" *ngIf="productId == 3">
                                <select class="form-select input_ text-grey3" (change)="onCommVehicleCategoryChange()"  name="vehicleategory" [(ngModel)]="selectedCommVehicleCategory">
                                   <ng-container *ngFor="let item of CommVehicleCategoryList">
                                        <option  [value]="item.id">
                                            {{item.name}}
                                        </option>
                                   </ng-container>
                                </select>
                                <label class="label_ required">Vehicle Category </label>
                            </div>
                            <div class="col-md-4" *ngIf="productId == 3 && selectedCommVehicleCategory == 'PCV'">
                                <app-multi-dropdown  class="w-100 input_"
                                    [items]="CommVehiclePCVehicleList" 
                                    [showSearch]="true" 
                                    [showAll]="false" 
                                    [showStatus]="false"
                                    [showError]="true" 
                                    placeholder="PCV(s)" 
                                    className=""
                                    fontColor="var(--grey3)"
                                    fontSize="15px"
                                    id="PCV"
                                    (itemChange)="onPCVSelect($event)">
                                </app-multi-dropdown>
                                <label class="label_ required">Passenger Carrying Vehicle</label>
                            </div>
                            <div class="col-md-4" *ngIf="productId == 3 && selectedCommVehicleCategory == 'GCV'">
                                <app-multi-dropdown  class="w-100 input_"
                                    [items]="CommVehicleGCVehicleList" 
                                    [showSearch]="true" 
                                    [showAll]="false" 
                                    [showStatus]="false"
                                    [showError]="true" 
                                    placeholder="GCV(s)" 
                                    className=""
                                    fontColor="var(--grey3)"
                                    fontSize="15px"
                                    id="GCV"
                                    (itemChange)="onGCVSelect($event)">
                                </app-multi-dropdown>
                                <label class="label_ required">Goods Carrying Vehicle </label>
                            </div>
                            <div class="col-md-4" *ngIf="productId == 3 && selectedCommVehicleCategory == 'MISC'">
                                <app-multi-dropdown  class="w-100 input_"
                                    [items]="CommVehicleMISCVehicleList" 
                                    [showSearch]="true" 
                                    [showAll]="false" 
                                    [showStatus]="false"
                                    [showError]="true" 
                                    placeholder="Miscellaneous Vehicle(s)" 
                                    className=""
                                    fontColor="var(--grey3)"
                                    fontSize="15px"
                                    id="MISC"
                                    (itemChange)="onMISCSelect($event)">
                                </app-multi-dropdown>
                                <label class="label_ required">Miscellaneous Vehicle </label>
                            </div>
                            <div class="col-md-4" *ngIf="productId != 3 || ( productId == 3 && selectedCommVehicleCategory == 'PCV')">
                                <app-multi-dropdown  class="w-100 input_"
                                    [items]="ccList" 
                                    [showSearch]="true" 
                                    [showAll]="false" 
                                    [showStatus]="false"
                                    [showError]="true" 
                                    placeholder="Cubic Capacity(s)" 
                                    className=""
                                    fontColor="var(--grey3)"
                                    fontSize="15px"
                                    id="CubicCapacity"
                                    (itemChange)="onCCSelect($event)">
                                </app-multi-dropdown>
                                <label class="label_ required">Cubic Capacity </label>
                            </div>
                            <div class="col-md-4" *ngIf="productId == 3 && selectedCommVehicleCategory == 'PCV' && selectedCommVehiclePCVehicle.indexOf('Bus') != -1">
                                <app-multi-dropdown  class="w-100 input_"
                                    [items]="CommVehiclePassCapList" 
                                    [showSearch]="true" 
                                    [showAll]="false" 
                                    [showStatus]="false"
                                    [showError]="true" 
                                    placeholder="Pass. Cap.(s)" 
                                    className=""
                                    fontColor="var(--grey3)"
                                    fontSize="15px"
                                    id="PassCap"
                                    (itemChange)="onPassCapSelect($event)">
                                </app-multi-dropdown>
                                <label class="label_ required">Passenger Capacity </label>
                            </div>
                            <div class="col-md-4">
                                <app-multi-dropdown  class="w-100 input_"
                                    [items]="ageList" 
                                    [showSearch]="true" 
                                    [showAll]="false" 
                                    [showStatus]="false"
                                    [showError]="true" 
                                    placeholder="Vehicle Age(s)" 
                                    className=""
                                    fontColor="var(--grey3)"
                                    fontSize="15px"
                                    id="VehicleAge"
                                    (itemChange)="onVehicleAgeSelect($event)">
                                </app-multi-dropdown>
                                <label class="label_ required">Vehicle Age </label>
                            </div>
                            <div class="col-md-4" *ngIf="productId == 3 && (selectedCommVehicleCategory == 'GCV' || selectedCommVehicleCategory == 'MISC')">
                                <app-multi-dropdown  class="w-100 input_"
                                    [items]="CommVehicleWeightList" 
                                    [showSearch]="true" 
                                    [showAll]="false" 
                                    [showStatus]="false"
                                    [showError]="true" 
                                    placeholder="GVW(s)" 
                                    className=""
                                    fontColor="var(--grey3)"
                                    fontSize="15px"
                                    id="GVW"
                                    (itemChange)="onVehicleWeightSelect($event)">
                                </app-multi-dropdown>
                                <label class="label_ required">Gross Vehicle Weight </label>
                            </div>

                            <div class="col-md-4">
                                <select class="form-select input_ text-grey3" name="year" (change)="onYearSelect()" [(ngModel)]="selectedYear" placeholder="Select Year">
                                   <ng-container *ngFor="let item of yearList">
                                        <option  [value]="item.id">
                                            {{item.name}}
                                        </option>
                                   </ng-container>
                                </select>
                                <label class="label_ required">Commission Effective Year </label>
                            </div>
                            <div class="col-md-4">
                                <select class="form-select input_ text-grey3" name="month" [(ngModel)]="selectedMonth" placeholder="Select Month">
                                   <ng-container *ngFor="let item of monthList">
                                        <option  [value]="item.id">
                                            {{item.name}}
                                        </option>
                                   </ng-container>
                                </select>
                                <label class="label_ required">Commission Effective Month </label>
                            </div>
                        </div>
                    </div> 
                    <div class="col-md-3 border-left">
                        <div class="row">
                            <div class="col-12 mb-3">Select Commission</div>
                        </div>
                        <div class="row">
                            <div class="col-12 position-relative">
                                <input type="number" class="form-control input_ text-grey3" placeholder=" " [(ngModel)]="commissionValue">
                                <label class="label_ required">Commission Value</label>
                                <app-toggle-switch [dot_font_size]="'17px'" [dot_height] = "'24px'" [dot_width] = "'28px'"  [dot_Cont_height]="'29px'" [dot_Cont_width]="'56px'" [dot_Cont_border_radius]="'4px'" [dot_border_radius]="'4px'" [dot_on_text]="'₹'" [dot_off_text]="'%'" [dot_Cont_bgColor_on]="'var(--grey5)'" [dot_Cont_bgColor_off]="'var(--grey5)'"  [dot_bgColor]="'var(--main)'" [data]="'COM'" (toggleChanged)="toggleChanged($event)" [switchon]="this.commissionType == 'AMT'" class="toggle-switch"></app-toggle-switch>
                            </div>
                        </div>
                    </div>                       
                </div>
            </div> 
    </div>
    <div id="comprehensive" class="tab-pane w-100" [ngClass]="{'d-block': activeTab == 3,'d-none' : activeTab !=3}">
        <div class="box_header pb-0">
            <span class="box_title_18">
                Commission Output
            </span>
            <div class="d-flex">
                <app-custom-button className="btn_outline mr-2" text="Previous" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(2,true)"></app-custom-button> 
                <app-custom-button text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(4)"></app-custom-button>
            </div>
        </div>
        <div class="p-lr-10" style="min-height: 400px;">
            <label class="box_title_18 border_top_grey5 w-100 mb-0 pb-2"></label> 
            <div class="row pb-2 mt-2 pt-2">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-4 position-relative">
                            <input type="number" class="form-control input_ text-grey3" placeholder=" " [(ngModel)]="DistributorLevelCommissionValue">
                            <label class="label_">Distributor Commission</label>
                            <app-toggle-switch [dot_font_size]="'17px'" [dot_height] = "'24px'" [dot_width] = "'28px'"  [dot_Cont_height]="'29px'" [dot_Cont_width]="'56px'" [dot_Cont_border_radius]="'4px'" [dot_border_radius]="'4px'" [dot_on_text]="'₹'" [dot_off_text]="'%'" [dot_Cont_bgColor_on]="'var(--grey5)'" [dot_Cont_bgColor_off]="'var(--grey5)'"  [dot_bgColor]="'var(--main)'" [data]="'DIS'" (toggleChanged)="toggleChanged($event)" [switchon]="this.DistributorLevelCommissionType == 'AMT'" class="toggle-switch"></app-toggle-switch>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 position-relative">
                            <input type="number" class="form-control input_ text-grey3" placeholder=" " [(ngModel)]="AgentLevel1CommissionValue">
                            <label class="label_">Agent Level 1 Commission</label>
                            <app-toggle-switch [dot_font_size]="'17px'" [dot_height] = "'24px'" [dot_width] = "'28px'"  [dot_Cont_height]="'29px'" [dot_Cont_width]="'56px'" [dot_Cont_border_radius]="'4px'" [dot_border_radius]="'4px'" [dot_on_text]="'₹'" [dot_off_text]="'%'" [dot_Cont_bgColor_on]="'var(--grey5)'" [dot_Cont_bgColor_off]="'var(--grey5)'"  [dot_bgColor]="'var(--main)'" [data]="'AG1'" (toggleChanged)="toggleChanged($event)" [switchon]="this.AgentLevel1CommissionType == 'AMT'" class="toggle-switch"></app-toggle-switch>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 position-relative">
                            <input type="number" class="form-control input_ text-grey3" placeholder=" " [(ngModel)]="AgentLevel2CommissionValue">
                            <label class="label_">Agent Level 2 Commission</label>
                            <app-toggle-switch [dot_font_size]="'17px'" [dot_height] = "'24px'" [dot_width] = "'28px'"  [dot_Cont_height]="'29px'" [dot_Cont_width]="'56px'" [dot_Cont_border_radius]="'4px'" [dot_border_radius]="'4px'" [dot_on_text]="'₹'" [dot_off_text]="'%'" [dot_Cont_bgColor_on]="'var(--grey5)'" [dot_Cont_bgColor_off]="'var(--grey5)'"  [dot_bgColor]="'var(--main)'" [data]="'AG2'" (toggleChanged)="toggleChanged($event)" [switchon]="this.AgentLevel2CommissionType == 'AMT'" class="toggle-switch"></app-toggle-switch>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 position-relative">
                            <input type="number" class="form-control input_ text-grey3" placeholder=" " [(ngModel)]="AgentLevel3CommissionValue">
                            <label class="label_">Agent Level 3 Commission</label>
                            <app-toggle-switch [dot_font_size]="'17px'" [dot_height] = "'24px'" [dot_width] = "'28px'"  [dot_Cont_height]="'29px'" [dot_Cont_width]="'56px'" [dot_Cont_border_radius]="'4px'" [dot_border_radius]="'4px'" [dot_on_text]="'₹'" [dot_off_text]="'%'" [dot_Cont_bgColor_on]="'var(--grey5)'" [dot_Cont_bgColor_off]="'var(--grey5)'"  [dot_bgColor]="'var(--main)'" [data]="'AG3'" (toggleChanged)="toggleChanged($event)" [switchon]="this.AgentLevel3CommissionType == 'AMT'" class="toggle-switch"></app-toggle-switch>
                        </div>
                    </div>

                </div>                       
            </div>
        </div> 
    </div>
        <div id="comprehensive" class="tab-pane w-100 pb-3" [ngClass]="{'d-block': activeTab == 4,'d-none' : activeTab !=4}">
            <div class="box_header pb-0">
                <span class="box_title_18">
                    Summary
                </span>
                <app-custom-button text="Edit" [width]="'auto'" [height]="'34px'" leadingIconName="edit" (click)="saveNext(1)">
                    
                </app-custom-button>
            </div>
            <div class="p-lr-10">
                <label class="box_title_18 border_top_grey5 w-100 mb-0 pb-2"></label> 
                <div class="box_container mt-2">
                    <div class="box_header row px-0">
                        <div class="col-6"><label class="box_title_14 border_bottom_grey5 w-100 mb-0 pb-2">Insurer</label> </div>
                        <div class="col-6"><label class="box_title_14 border_bottom_grey5 w-100 mb-0 pb-2">Product</label> </div> 
                    </div>
                    <div class="row w-100">
                        <div class="col-6">
                            <div class="card selected w-200 mb-2">
                                <img class="img-fluid" title="{{selectedInsurerName}}" alt="{{selectedInsurerName}}" src="/assets/images/insurance_companies/{{insurerId}}.png">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="card mh-102 selected w-200 mb-2 bg-image bg-img-width" style="background-image:url('/assets/images/insurance_product/{{productId}}.png')">
                                <div class="span mt-auto pl-3 pb-2">{{selectedProductName}}</div>
                            </div>
                        </div>
                        
                    </div>
                </div>  
                <div class="box_container mt-4 pb-2">
                    <div class="box_header">
                        <label class="box_title_14 border_bottom_grey5 w-100 mb-0 pb-2">Products & Details</label>   
                    </div>
                    <div class="row w-100">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Region Details </div>
                                <div class="col-8 label_13_grey2"> {{selectedStateCityRTO}} </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Make(s) </div>
                                <div class="col-8 label_13_grey2"> {{selectedMakes}} </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Model(s) </div>
                                <div class="col-8 label_13_grey2"> {{selectedMakeModel}} </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Variant(s) </div>
                                <div class="col-8 label_13_grey2"> {{selectedMakeModelVariant}} </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Fuel(s) </div>
                                <div class="col-8 label_13_grey2"> {{selectedFuel}} </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Policy Type(s) </div>
                                <div class="col-8 label_13_grey2"> {{selectedPolicyType}} </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Comm. Eff. Month Year</div>
                                <div class="col-8 label_13_grey2"> {{this.monthNameArray[selectedMonth-1]}} {{selectedYear}} </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Input Commission </div>
                                <div class="col-8 label_13_grey2"> {{commissionValue}} ({{commissionType == "PER" ? '%' : '₹'}}) </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Vehicle Age(s) </div>
                                <div class="col-8 label_13_grey2"> {{selectedAge}} (year)</div>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="productId == 1">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Vehicle Type(s) </div>
                                <div class="col-8 label_13_grey2"> {{selectedVehicleType}} </div>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="productId == 3">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Vehicle Category </div>
                                <div class="col-8 label_13_grey2"> {{selectedCommVehiclePCVehicle}} </div>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="productId == 3 && selectedCommVehicleCategory == 'PCV'">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Passenger Carrying Vehicle(s) </div>
                                <div class="col-8 label_13_grey2"> {{selectedCommVehiclePCVehicle}} </div>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="productId == 3 && selectedCommVehicleCategory == 'GCV'">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Goods Carrying Vehicle(s) </div>
                                <div class="col-8 label_13_grey2"> {{selectedCommVehicleGCVehicle}} </div>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="productId == 3 && selectedCommVehicleCategory == 'MISC'">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Miscellaneous Vehicle(s) </div>
                                <div class="col-8 label_13_grey2"> {{selectedCommVehicleMISCVehicle}} </div>
                            </div>
                        </div>                        
                        <div class="col-md-6" *ngIf="productId != 3 || ( productId == 3 && selectedCommVehicleCategory == 'PCV')">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Cubic Capacity(s) </div>
                                <div class="col-8 label_13_grey2"> {{selectedCC}} </div>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="productId == 3 && selectedCommVehicleCategory == 'PCV' && selectedCommVehiclePCVehicle.indexOf('Bus') != -1">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Passenger Capacity(s) </div>
                                <div class="col-8 label_13_grey2"> {{selectedCommVehiclePassCap}} </div>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="productId == 3 && (selectedCommVehicleCategory == 'GCV' || selectedCommVehicleCategory == 'MISC')">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Gross Vehicle Weight(s) </div>
                                <div class="col-8 label_13_grey2"> {{selectedCommVehicleWeight}} </div>
                            </div>
                        </div>
                    </div>
                </div>  
                <div class="box_container mt-4 pb-2">
                    <div class="box_header">
                        <label class="box_title_14 border_bottom_grey5 w-100 mb-0 pb-2">Output Commission</label>   
                    </div>
                    <div class="row w-100">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Distributor Commission </div>
                                <div class="col-8 label_13_grey2"> {{DistributorLevelCommissionValue}} ({{DistributorLevelCommissionType == "PER" ? '%' : '₹'}}) </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Agent L1 Commission </div>
                                <div class="col-8 label_13_grey2"> {{AgentLevel1CommissionValue}} ({{AgentLevel1CommissionType == "PER" ? '%' : '₹'}}) </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Agent L2 Commission </div>
                                <div class="col-8 label_13_grey2"> {{AgentLevel2CommissionValue}} ({{AgentLevel2CommissionType == "PER" ? '%' : '₹'}}) </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3"> Agent L3 Commission </div>
                                <div class="col-8 label_13_grey2"> {{AgentLevel3CommissionValue}} ({{AgentLevel3CommissionType == "PER" ? '%' : '₹'}}) </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="box_container mt-4 pb-2">
                    <div class="box_header">
                        <label class="box_title_14 border_bottom_grey5 w-100 mb-0 pb-2">Details</label>   
                    </div>
                    <div class="row w-100">
                        <div class="col-12">
                            <table class="table table-responsive tableFixHead">
                                <thead>
                                    <tr><th>Make</th>
                                        <th>Model</th>
                                        <th>Variant</th>
                                        <th *ngIf="false">Zone</th>
                                        <th>RTOState</th>
                                        <th>RTOCity</th>
                                        <th>RTO</th>
                                        <th>Age</th>
                                        <th *ngIf="productId != 3 || ( productId == 3 && selectedCommVehicleCategory == 'PCV')">CC</th>
                                        <th>VehicleType</th>
                                        <th>PolicyType</th>
                                        <th>FuelType</th>
                                        <th *ngIf="false">IDV</th>
                                        <th *ngIf="false">InsuranceCompanyId</th>
                                        <th>Month</th>
                                        <th>Year</th>
                                        <th *ngIf="false">Id</th>
                                        <th>CommissionValue  </th>
                                        <th>CommissionType</th>
                                        <th>DistributorLevelCommissionValue</th>
                                        <th>DistributorLevelCommissionType</th>                                        
                                        <th>AgentLevel1CommissionValue</th>
                                        <th>AgentLevel1CommissionType</th>
                                        <th>AgentLevel2CommissionValue</th>
                                        <th>AgentLevel2CommissionType</th>
                                        <th>AgentLevel3CommissionValue</th>
                                        <th>AgentLevel3CommissionType</th>
                                        <th *ngIf="false">Status</th>
                                        <th>Body_Type</th>
                                        <th *ngIf="productId == 3 && selectedCommVehicleCategory == 'PCV' && selectedCommVehiclePCVehicle.indexOf('Bus') != -1">Seating_Capacity</th>
                                        <th *ngIf="productId == 3 && (selectedCommVehicleCategory == 'GCV' || selectedCommVehicleCategory == 'MISC')">Grosss_Vehicle_Weight</th>
                                        <th *ngIf="productId == 1">Vehicle_Type</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of requestObjet">
                                        <td>{{item.Make}}</td>
                                        <td>{{item.Model}}</td>
                                        <td>{{item.Variant}}</td>
                                        <td *ngIf="false">{{item.Zone}}</td>
                                        <td>{{item.RTOState}}</td>
                                        <td>{{item.RTOCity}}</td>
                                        <td>{{item.RTO}}</td>
                                        <td>{{item.Age}}</td>
                                        <td *ngIf="productId != 3 || ( productId == 3 && selectedCommVehicleCategory == 'PCV')">{{item.CC}}</td>
                                        <td>{{item.VehicleType}}</td>
                                        <td>{{item.PolicyType}}</td>
                                        <td>{{item.FuelType}}</td>
                                        <td *ngIf="false">{{item.IDV}}</td>
                                        <td *ngIf="false">{{item.InsuranceCompanyId}}</td>
                                        <td>{{this.monthNameArray[(item.Month ? item.Month : 1) -1]}}</td>
                                        <td>{{item.Year}}</td>
                                        <td *ngIf="false">{{item.Id}}</td>
                                        <td>{{item.CommissionValue  }}</td>
                                        <td>{{item.CommissionType == "PER" ? '%' : '₹'}}</td>   
                                        <td>{{item.DistributorLevelCommissionValue}}</td>
                                        <td>{{item.DistributorLevelCommissionType == "PER" ? '%' : '₹'}}</td>
                                        <td>{{item.AgentLevel1CommissionValue}}</td>
                                        <td>{{item.AgentLevel1CommissionType == "PER" ? '%' : '₹'}}</td>
                                        <td>{{item.AgentLevel2CommissionValue}}</td>
                                        <td>{{item.AgentLevel2CommissionType == "PER" ? '%' : '₹'}}</td>
                                        <td>{{item.AgentLevel3CommissionValue}}</td>
                                        <td>{{item.AgentLevel3CommissionType == "PER" ? '%' : '₹'}}</td>
                                        <td *ngIf="false">{{item.Status}}</td>
                                        <td>{{item.Body_Type}}</td>
                                        <td *ngIf="productId == 3 && selectedCommVehicleCategory == 'PCV' && selectedCommVehiclePCVehicle.indexOf('Bus') != -1">{{item.Seating_Capacity}}</td>
                                        <td *ngIf="productId == 3 && (selectedCommVehicleCategory == 'GCV' || selectedCommVehicleCategory == 'MISC')">{{item.Grosss_Vehicle_Weight}}</td>
                                        <td *ngIf="productId == 1">{{item.Vehicle_Type}}</td>
                                    </tr>
                                </tbody>
                                
                            </table>
                        </div>
                    </div>
                </div>
                <app-custom-button className="mt-3" text="Confirm" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveCommissionData()"></app-custom-button>           
            </div> 
        </div>
    </div>
    </app-mainscreen>