import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginLogsComponent } from 'src/app/components/login-logs/login-logs.component';
import { DocumentDetails } from 'src/app/models/Distributor';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-agent-view',
  templateUrl: './agent-view.component.html',
  styleUrls: ['./agent-view.component.css']
})
export class AgentViewComponent {
  activeTab:number=1;
  agentForm: FormGroup;
  companyForm: FormGroup;
  id:number=0;
  documentsUploadForm:DocumentDetails[]=[];
  @ViewChild(LoginLogsComponent) loginLogsComponent!: LoginLogsComponent;
  constructor(private fb: FormBuilder, private adminService: AdminService,private router:Router){
    let obj = this.router.getCurrentNavigation()?.extras?.state;
    if(obj)
    {
      this.id = parseInt(obj['id']);
      this.getFormData(this.id);
    }
    this.agentForm = this.fb.group({
      "agentFirstName": ['', [Validators.required]],
      "agentLastName": ['', [Validators.required]],
      "agentEmail": ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      "agentMobile": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      "agentCountryCode": ['91', [Validators.required]],
      "agentStatus": ['1', [Validators.required]],
      "agentId": ['0'],
      "agentDOB": ['', [Validators.required]],
      "agentGender": ['', [Validators.required]],
      "userId": ['0'],
      "lastLogin" : ['']
    });

    this.companyForm = this.fb.group({
      "companyId": ['0'],
      "companyName": ['', [Validators.required]],
      "companyPancard": ['', [Validators.required,Validators.pattern('[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}')]],
      "companyGSTIN": ['', [Validators.required]],
      "companyLicenseNo": ['', [Validators.required]],
      "companyMobileNo": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      "companyCountryCode": ['91', [Validators.required]],
      "companyEmail": ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      "companyStreetAddress": ['', [Validators.required]],      
      "companyPincode": ['', [Validators.required,Validators.pattern('[0-9]{6}')]],
      "companyStateId": [''],
      "companyState": ['', [Validators.required]],
      "companyCityId": [''],
      "companyCity": ['', [Validators.required]],
      "companyStatus": ['1', [Validators.required]],
      "userId": ['0']
    });
  }
  get agentFormControl() {
    return this.agentForm.controls;
  }
  get companyDetailsFormControl() {
    return this.companyForm.controls;
  }
  ngOnInit(): void {
    
  }

 async  getFormData(id:number)
  {
    const res = await this.adminService.GetAgentById(id);  
      if (res != null && res.result) {

        this.agentForm.patchValue(res.result);
        this.companyForm.patchValue(res.result.companyDetails);
        this.documentsUploadForm = res.result.userDocuments;
        this.loginLogsComponent?.getLoginLogs(this.agentFormControl['userId']?.value,'');
      }    
      else
      {
        // swal.fire({
        //   position: 'center',
        //   icon: 'error',
        //   title: 'Something went wrong',
        //   showConfirmButton: false,
        //   timer: 3000,
        // });
      } 
  }
  changeTab(tab:number)
  {
    this.activeTab = tab;
  }

  backBtClick(){
    this.router.navigateByUrl('/agent-edit',{ state: { id:this.id }});
  }

  ngAfterViewInit() {
    if(this.agentFormControl['userId']?.value > 0){
      this.loginLogsComponent?.getLoginLogs(this.agentFormControl['userId'].value,'');//'4CBC43DAC4F4470BA73D3CF7C535B65C'); //this.generalService.getUniqueuserid());
    }
 }
}
