<div style="display: flex; flex-direction: column; align-items: start;">
    <label [style.opacity]="titleOpacity" style="font-size: 17px; font-weight: 500; margin: 0px;"
        class="label-text p-0 mb-1" #labelText
        [style.margin-left]="leadingIconName ? 'var(--input-field-delete-button-width)' : '0px'">
        {{title}}
        <span *ngIf="required" class="text-red m-0 p-0">*</span>
    </label>
    <label style="font-size: 13px; color: var(--grey3);" class="p-0 mb-1"
        [style.margin-right]="deleteEnabled || compulsaryKeepSpaceIfNoDeleteButton ? 'var(--input-field-delete-button-width)' : '0px'"
        [style.margin-left]="leadingIconName ? 'var(--input-field-delete-button-width)' : '0px'"
        [style.width]="leadingIconName && deleteEnabled ? 'calc(100% - 84px)' : leadingIconName || deleteEnabled || compulsaryKeepSpaceIfNoDeleteButton ? 'calc(100% - var(--input-field-delete-button-width))' : '100%'">
        {{description}}
    </label>
</div>
<div style="width: 100%; display: flex; align-items: center;" class="mt-1">
    <div style="display: flex; align-items: center; justify-content: center; width: var(--input-field-delete-button-width); transition: all 0.3s;"
        *ngIf="leadingIconName" [style.opacity]="leadingIconOpacity" [style.cursor]="leadingIconCursorStyle">
        <span *ngIf="leadingIconName" class="material-symbols-outlined text-grey4">
            {{leadingIconName}}
        </span>
    </div>
    <div [style.height]="height + 8 + 'px'"
        style="flex-grow: 1; display: flex; align-items: center; border-radius: 12px; border-style: solid; padding-right: 10px; background-color: var(--white); position: relative; overflow: hidden;"
        [style.border-width]="borderWidth+'px'" class="main-container"
        (click)="readonly?mainInput.blur() : mainInput.focus()" #mainContainer
        [style.border-color]="errorText ? 'var(--red)': borderColor" [style.background-color]="backgroundColor"
        [style.cursor]="readonly || disabled ? 'not-allowed' : 'text'">
        <!-- leading image -->
        <div [style.height]="height + 'px'" [style.width]="height + 'px'"
            style="margin: 4px; border-radius: 6px; flex-shrink: 0; background-position: center; background-size: cover;"
            [style.background-image]="'url('+leadingImageURL+')'" *ngIf="leadingImageURL"></div>
        <!-- inner leading icon with dark background -->
        <div [style.width]="height + 8 + 'px'"
            style="display: flex; align-items: center; justify-content: center; width: var(--input-field-delete-button-width); height: 100%; flex-shrink: 0; left: 0px;"
            class="innerLeadingIconWithDarkBackground" *ngIf="innerLeadingIconWithDarkBackgroundName">
            <span class="material-symbols-outlined text-white" style="font-size: 18px;">
                {{innerLeadingIconWithDarkBackgroundName}}
            </span>
        </div>
        <!-- inner leading icon -->
        <span class="material-symbols-outlined text-grey3"
            style="font-size: 18px; margin-left: 15px; margin-right: 5px;" *ngIf="innerLeadingIconName != ''">
            {{innerLeadingIconName}}
        </span>
        <!-- image picker -->
        <div [style.height]="height + 'px'" [style.width]="height + 'px'"
            style="border-radius: 10px; display: flex; align-items: center; justify-content: center; font-size: 15px; color: var(--grey5); cursor: pointer; flex-shrink: 0; margin-left: 3px; user-select: none;"
            class="bg-grey6" mat-ripple (click)="fileInput.click()" *ngIf="imagePickerEnabled">
            <input #fileInput type="file" style="display: none;" accept="image/*"
                (change)="handleInputFileInput($event.target)" />
            <div *ngIf="imagePreview"
                style="height: 100%; width: 100%; background-size: cover; background-position: center; position: absolute; top: 0px; left: 0px;"
                [style.background-image]="'url('+imagePreview+')'"></div>
            <span class="material-symbols-outlined" style="pointer-events: none;" *ngIf="!imagePreview">
                add_photo_alternate
            </span>
        </div>
        <!-- main input -->
        <input #mainInput [id]="id" [type]="type" [placeholder]="placeHolder" [value]="value" [name]="name"
            [disabled]="disabled" [required]="required" [readonly]="readonly" [maxLength]="maxLength"
            [minLength]="minLength" style="border-width: 0px; margin: 0px; padding: 0px; background-color: transparent;"
            [style.width]="innerLeadingIconWithDarkBackgroundName ? 'calc(100% - '+ height + 8 +'px)' : '100%'"
            [name]="title" [style.margin-left]="leadingImageURL || innerLeadingIconName ? '5px' : '15px'"
            (focus)="labelText.style.color = 'var(--main2)'; mainContainer.style.borderColor = 'var(--main1)'"
            (blur)="labelText.style.color = 'var(--grey3)'; mainContainer.style.borderColor = errorText ? 'var(--red)': borderColor"
            [style.cursor]="readonly || disabled ? 'not-allowed' : 'text'" (change)="handleValueChange($event)"
            (keydown)="handleValueChange($event)" (keyup)="handleValueChange($event)">
        <!-- Suffix Icon -->
        <div style="display: flex; align-items: center; justify-content: center; height: 100%; width: 30px; flex-shrink: 0; left: 0px;"
            *ngIf="suffixIconName">
            <span class="material-symbols-outlined text-grey3" style="font-size: 25px;">
                {{suffixIconName}}
            </span>
        </div>
        <!-- trailing button -->
        <app-custom-button [text]="trailingButtonTitle" height="30px" [disabled]="trailingButtonDisabled"
            [leadingIconName]="trailingButtonIconName" *ngIf="trailingButtonTitle != ''"
            (click)="trailingButtonClick.emit()" [backgroundColor]="trailingButtonColor" [width]="trailingButtonWidth"
            [hoveredBackgroundColor]="trailingButtonColorHovered"></app-custom-button>
    </div>
    <div [style.width]="height + 'px'" style="display: flex; align-items: center; justify-content: center;"
        *ngIf="deleteEnabled">
        <button mat-icon-button class="m-0 p-0 ml-2" (click)="onDelete.emit()">
            <span class="material-symbols-outlined" style="color: var(--red);">
                delete
            </span>
        </button>
    </div>
    <div style="width: var(--input-field-delete-button-width);"
        *ngIf="!deleteEnabled && compulsaryKeepSpaceIfNoDeleteButton"></div>
</div>
<label *ngIf="errorText" style="font-size: 13px; font-weight: normal; color: var(--red);" class="m-0 p-0 mt-2">
    {{errorText}}
</label>