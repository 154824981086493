<div class="login-container d-flex flex-root" id="kt_app_root">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7"
               type="ball-spin-clockwise-fade-rotating"></ngx-spinner>
</div>
<section>
  <div class="container-fluid">
    <img src="/assets/images/bg-1.png" class="bg-1" />
    <img src="/assets/images/bg-2.png" class="bg-2" />
    <img src="/assets/images/bg-3.png" class="bg-3" />
  </div>
  <div class="container h-100vh d-flex">
    <div class="row justify-content-between w-100 mx-0">
      <div class="col-lg-5 col-md-5 col-sm-5 col-12 align-self-center">
        <div class="login_div">
          <form class="form" novalidate="novalidate" id="kt_sign_in_form" [formGroup]="loginForm">
            <div class="text-center mb-11">
              <h2>Welcome to <span style="color: var(--main1);">IMS</span></h2>
              <p>Login to your Account</p>
            </div>
            <div class="form-group">
              <label class="input-label required" for="mobile">Mobile Number</label>
              <input type="number" class="form-control" name="phone" formControlName="PhoneNumber" placeholder="Enter your number" minlength="10" onKeyPress="if(this.value.length==10) return false;" onfocus="this.placeholder=''"  />
            </div>
            <div class="form-group">
              <label class="input-label required" for="password">Password</label>
              <input [type]="this.passwordType" placeholder="Enter Password" name="password" autocomplete="off" class="form-control bg-transparent" formControlName="Password" onfocus="this.placeholder=''" (keypress)="passKeyPress($event)"/>
              <i class="text-grey3" [class]="passwordShow === false ? 'fa fa-eye-slash field-icon fs-4' : 'fa fa-eye field-icon fs-4'" (click)="ShowPassword()"></i>
            </div>
            <div class="fv-row mb-8">
              
              
              <!-- <ngx-intl-tel-input class="bg-transparent form-control" [enableAutoCountrySelect]="true" [enablePlaceholder]="true" [searchCountryFlag]="false" [onlyCountries]="[CountryISO.UnitedStates, CountryISO.India]" [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]" [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.India" [phoneValidation]="true" [separateDialCode]="separateDialCode" name="phone" formControlName="PhoneNumber" [inputId]="'phone_number'" (countryChange)="onCountryChangeNew($event)" aria-hidden="true" #phoneNumber>

              </ngx-intl-tel-input> -->
            </div>
            <div class="passwords_div d-flex justify-content-between">
              <span class="d-flex align-items-center">
                <input type="checkbox" class="tb-secondary scale-1_6 mx-1"><span class="remember ml-1">Remember me</span>
              </span>              
              <a href="/forgot-password" class="link-primary forgot_password_link" >Forgot Password ?</a>
            </div>
            <div class="d-grid mb-5">
              <button class="btn btn-primary login_btn" type="submit" id="kt_sign_in_submit" [disabled]="loginForm.invalid" (click)="submit()">
                <span class="indicator-label">Sign In</span>
              </button>
            </div>
            
          </form>
          
        </div>
      </div>
      <div class="col-lg-5 col-md-5 col-sm-5 col-12 align-self-center">
        <app-carousel></app-carousel>
      </div>
    </div>
  </div>
</section>