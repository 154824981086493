import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-paginator',
  templateUrl: './custom-paginator.component.html',
  styleUrls: ['./custom-paginator.component.css']
})
export class CustomPaginatorComponent {
  @Input() currentPage: number = 0;
  @Input() totalPages: number = 0;
  @Input() pageSizes: number[] = [];
  @Input() selectedPageSize: number = 0;
  @Input() entriesInfo: string = "";
  @Output() pageChange = new EventEmitter<number>();
  @Output() pageSizeChange = new EventEmitter<number>();

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.pageChange.emit(this.currentPage + 1);
    }
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.pageChange.emit(this.currentPage - 1);
    }
  }

  onPageSizeChange(event: any) {
    this.currentPage = 1;
    this.pageSizeChange.emit(parseInt(event.target.value, 10));
    this.pageChange.emit(this.currentPage);
  }

  goToPage(page: number) {
    if (page >= 1 && page <= this.totalPages) {
      this.currentPage = page;
      this.pageChange.emit(page);
    }
  }

  get pages(): any[] {
    const numPages = this.totalPages;
    const pagesArray = [];

    for (let i = 1; i <= numPages; i++) {
      pagesArray.push(i);
    }

    return this.getPaginationGenerator(this.currentPage, pagesArray.length,2);
  }

  getPaginationGenerator(currentPageNumber: number,totalPageNumber: number, offset:number ): any[] {
    // By doing this, when we are close to the beginning or end of the pagination, two numbers are generated after/before the current page, 
    // but when we are far from these points (in the middle of the pagination), we generate only one number after/before the current page.
    const offsetNumber =
      currentPageNumber <= offset || currentPageNumber > totalPageNumber - offset ? offset : offset - 1;
    const numbersList = [];
    const numbersListWithDots:any = [];
  
    // If itemsPerPage is less than what the user selected with the Select component or if there is no page or only one page:
    if (totalPageNumber <= 1 || totalPageNumber === undefined) return [1];
  
    // Create list of numbers:
    numbersList.push(1);
    for (let i = currentPageNumber - offsetNumber; i <= currentPageNumber + offsetNumber; i++) {
      if (i < totalPageNumber && i > 1) {
        numbersList.push(i);
      }
    }
    numbersList.push(totalPageNumber);
  
    // Add three dots to the list of numbers:
    numbersList.reduce((accumulator, currentValue) => {
      if (accumulator === 1) {
        numbersListWithDots.push(accumulator);
      }
      if (currentValue - accumulator !== 1) {
        numbersListWithDots.push('...');
      }
      numbersListWithDots.push(currentValue);
  
      return currentValue;
    });
  
    return numbersListWithDots;
  };
}
