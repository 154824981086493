<div class="autocomplete-container">
    <input type="text" [ngClass]="{ 'border border-danger': showError }" class="autocomplete-input form-control {{className}}" (input)="onInput($event)" (click)="onClick()" [value]="userInput" placeholder="{{placeholder}}" (blur)="onBlur($event)" (keyup)="onKeyUp($event)" [readonly]="readonly">
    <label class="label_" [ngClass]="{'required' : isRequired}" *ngIf="labelText != undefined && labelText !=''">{{labelText}}</label>
    <Span class="text-danger error_span" *ngIf="showErrorRequired">
      {{labelText|titlecase}} is required.
    </Span>
    <Span class="text-danger error_span" *ngIf="showErrorValid">
      Provide valid {{labelText|lowercase}} is required.
    </Span>
    <ul #optionsList class="autocomplete-options" [ngClass]="{'border-1px':filteredItems.length > 0}" >
      <li *ngFor="let item of filteredItems; let i = index" [ngClass]="{ 'selected': i === selectedIndex }" (click)="onSelect(item)" class="autocomplete-option">
        {{ item.text }} {{(supportingText == true ? ' (' + item.text1 + ')' :'' )}}
      </li>
    </ul>
  </div>