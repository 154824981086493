import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.css'],
})
export class CustomButtonComponent implements OnInit {
  @Input() text: string = '';
  @Input() imageUrl: string = '';
  @Input() fontSize: string = '15px';
  @Input() fontSizeIcon: string = '18px';
  @Input() backgroundColor?: string;
  @Input() hoveredBackgroundColor?: string;
  @Input() textColor?: string;
  @Input() width: any = 'auto';
  @Input() height: any = '46px';
  @Input() leadingImageUrl: any = null;
  @Input() leadingIconName: any = null;
  @Input() disabled: boolean = false;
  @Input() className: string = '';
  @Output() onClick = new EventEmitter<void>();

  hovered: boolean = false;

  onClicked() {
    this.onClick.emit();
  }

  constructor() {}

  ngOnInit(): void {}

  handleMouseEnter() {
    this.hovered = true;
  }

  handleMouseLeave() {
    this.hovered = false;
  }
}
