import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../shared/AppConfig';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  protected _appset = AppConfig.settings;
  constructor(private http: HttpClient) {
  }

  async AcquireUserInfo(data: any) {
    const url = environment.APIUrl + 'user/AcquireUserInfo';
    return this.http.post<ApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async ManipulateUserInfo(data: any) {
    const url = environment.APIUrl + 'user/ManipulateUserInfo';
    return this.http.post<ApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async UserListing() {
    const url = environment.APIUrl + 'user/UserListing';
    return this.http.get<ApiResponse>(url, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async GetUserById(data:any) {
    const url = environment.APIUrl + 'user/GetUserById?userid='+data;
    return this.http.get<ApiResponse>(url, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }

  async SaveUpdateUserWithPermission(data: any) {
    const url = environment.APIUrl + 'user/SaveUpdateUserWithPermission';
    return this.http.post<ApiResponse>(url, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: ApiResponse) => {
      let res = new ApiResponse();
      res.isSuccess = false;
      res.message = errorRes.message;
      return res;
    });
  }
}
