import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AgentDetails, DocumentDetails } from 'src/app/models/Distributor';
import { AutoCompleteItem, Item } from 'src/app/models/SelectItems';
import { AdminService } from 'src/app/services/admin.service';
import { GeneralService } from 'src/app/services/general.service';
import { LookUpService } from 'src/app/services/lookup.service';
import swal from 'sweetalert2';
import * as moment from 'moment';
import { JsonPipe } from '@angular/common';
@Component({
  selector: 'app-agent-edit',
  templateUrl: './agent-edit.component.html',
  styleUrls: ['./agent-edit.component.css']
})
export class AgentEditComponent {
  activeTab:number=1;
  agentForm: FormGroup;
  companyForm: FormGroup;
  //documentsUploadForm:DocumentDetails[]=[];
  submittedDisForm : boolean = false;
  submittedDocForm : boolean = false;
  submittedCompForm : boolean = false;
  GenderList:any[]=[{text:"Select Gender",value:""},{text:"Female",value:"Female"},{text:"Male",value:"Male"},{text:"Other",value:"Other"}]
  stateList :AutoCompleteItem[] =[];
  districtList:AutoCompleteItem[]=[];
  pincodeList:AutoCompleteItem[]=[];
  cityList:AutoCompleteItem[]=[];
  distributorList:Item[]=[];
  resellerList:Item[]=[];
  userType:string="";
  state:string='';
  city:string='';
  pincode:string='';
  district:string='';
  languageList:Item[] = [];
  languageText:string = "";
  saveAdhaarForm: FormGroup;
  savePanForm: FormGroup;
  saveImageForm: FormGroup;
  saveEducationForm: FormGroup;
  saveBankDetailForm: FormGroup;
  maxDOB = moment(new Date()).add(-18, 'years').format('YYYY-MM-DD');
  submittedAadharForm : boolean = false;
  submittedPANForm : boolean = false;
  submittedSelfieForm : boolean = false;
  submittedQualForm : boolean = false;
  submittedBankForm : boolean = false;
  statusList = [{value:0,text : 'Initial'},{value:1,text:'Approved'},{value:2,text : 'In Progress'},{value:3,text:'Reject'}];
  activeAgentDocTab:number=1;
  agentLevelList=[{value:1,text : 'Level 1'},{value:2,text:'Level 2'},{value:3,text : 'Level 3'}];
  panDocObj:DocumentDetails={
    documentTypeName:'',
    documentBase64:'',
    documentNumber:'0',
    uniqueUserId:this.generalService.getUniqueuserid(),
    documentUniqueId:'0',
    documentTypeId:1,
    verified:1,
    documentStatus:1,
    documentDownloadName:''
  }
  gstinDocObj:DocumentDetails={
    documentTypeName:'',
    documentBase64:'',
    documentNumber:'0',
    uniqueUserId:this.generalService.getUniqueuserid(),
    documentUniqueId:'0',
    documentTypeId:7,
    verified:1,
    documentStatus:1,
    documentDownloadName:''
  }

  aadharFrontDocObj:DocumentDetails={
    documentTypeName:'',
    documentBase64:'',
    documentNumber:'0',
    uniqueUserId:this.generalService.getUniqueuserid(),
    documentUniqueId:'0',
    documentTypeId:2,
    verified:1,
    documentStatus:1,
    documentDownloadName:''
  }

  aadharBackDocObj:DocumentDetails={
    documentTypeName:'',
    documentBase64:'',
    documentNumber:'0',
    uniqueUserId:this.generalService.getUniqueuserid(),
    documentUniqueId:'0',
    documentTypeId:2,
    verified:1,
    documentStatus:1,
    documentDownloadName:''
  }

  agentPanDocObj:DocumentDetails={
    documentTypeName:'',
    documentBase64:'',
    documentNumber:'0',
    uniqueUserId:this.generalService.getUniqueuserid(),
    documentUniqueId:'0',
    documentTypeId:1,
    verified:1,
    documentStatus:1,
    documentDownloadName:''
  }

  agentSelfieDocObj:DocumentDetails={
    documentTypeName:'',
    documentBase64:'',
    documentNumber:'0',
    uniqueUserId:this.generalService.getUniqueuserid(),
    documentUniqueId:'0',
    documentTypeId:9,
    verified:1,
    documentStatus:1,
    documentDownloadName:''
  }

  agentEducDocObj:DocumentDetails={
    documentTypeName:'',
    documentBase64:'',
    documentNumber:'0',
    uniqueUserId:this.generalService.getUniqueuserid(),
    documentUniqueId:'0',
    documentTypeId:10,
    verified:1,
    documentStatus:1,
    documentDownloadName:''
  }

  agentBankDocObj:DocumentDetails={
    documentTypeName:'',
    documentBase64:'',
    documentNumber:'0',
    uniqueUserId:this.generalService.getUniqueuserid(),
    documentUniqueId:'0',
    documentTypeId:11,
    verified:1,
    documentStatus:1,
    documentDownloadName:''
  }
  
  constructor(private fb: FormBuilder, private _lookupService: LookUpService,private adminService: AdminService,private router:Router
    ,private activatedRoute:ActivatedRoute,private generalService: GeneralService) {
      this.userType = generalService.getUserType();
      let obj = this.router.getCurrentNavigation()?.extras?.state;
      if(obj)
      {
        this.getFormData(parseInt(obj['id']));
      }
    this.agentForm = this.fb.group({
      "agentFirstName": ['', [Validators.required]],
      "agentLastName": ['', [Validators.required]],
      "agentEmail": ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      "agentMobile": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      "agentCountryCode": ['91'],
      "agentStatus": ['1', [Validators.required]],
      "agentId": ['0'],
      "agentDOB": ['', [Validators.required]],
      "agentGender": ['', [Validators.required]],
      "pospCode": ['', [Validators.required]],
      "language": ['', [Validators.required]],
      "userId": ['0'],
      "resellerId": ['0', [Validators.required]],
      "distributorId": ['0', [Validators.required]],
      "levelId": [1],
    });



    if(this.userType == "2"){
      this.agentForm.get("distributorId")?.setValue("");
      this.agentForm.get("resellerId")?.setValue("");
    }
    if(this.userType == "3"){
      this.agentForm.get("resellerId")?.setValue("");
    }

    // this.companyForm = this.fb.group({
    //   "companyId": ['0'],
    //   "companyName": ['', [Validators.required]],
    //   "companyPancard": ['', [Validators.required,Validators.pattern('[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}')]],
    //   "companyGSTIN": ['', [Validators.required, Validators.pattern('^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$')]],
    //   "companyLicenseNo": ['', [Validators.required]],
    //   "companyMobileNo": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
    //   "companyCountryCode": ['91'],
    //   "companyEmail": ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
    //   "companyStreetAddress": ['', [Validators.required]],      
    //   "companyPincode": ['', [Validators.required,Validators.pattern('[0-9]{6}')]],
    //   "companyStateId": [''],
    //   "companyState": ['', [Validators.required]],
    //   "companyCityId": [''],
    //   "companyCity": ['', [Validators.required]],
    //   "companyStatus": ['1'],
    //   "userId": ['0'],
    //   'companyPANDocId': [0],
    //   'companyGSTINDocId':[0],
    //   'companyPANDocBase64': ['', [Validators.required]],
    //   'companyGSTINDocBase64': ['', [Validators.required]]
    // });

    this.companyForm = this.fb.group({
      "companyId": ['0'],
      "companyName": [''],
      "companyPancard": ['', [Validators.pattern('[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}')]],
      "companyGSTIN": ['', [Validators.pattern('^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$')]],
      "companyLicenseNo": [''],
      "companyMobileNo": ['', [Validators.pattern('[0-9]{10}')]],
      "companyCountryCode": ['91'],
      "companyEmail": ['', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      "companyStreetAddress": [''],      
      "companyPincode": ['', [Validators.pattern('[0-9]{6}')]],
      "companyStateId": [''],
      "companyState": [''],
      "companyCityId": [''],
      "companyCity": [''],
      "companyStatus": ['1'],
      "userId": ['0'],
      'companyPANDocId': [0],
      'companyGSTINDocId':[0],
      'companyPANDocBase64': [''],
      'companyGSTINDocBase64': ['']
    });

    this.saveAdhaarForm = this.fb.group({
      "aadhaarNumber": ['', [Validators.required]],
      "gender": ['Male', [Validators.required]],
      "address": ['', [Validators.required]],
      "pincode": ['', [Validators.required]],
      "city": ['', [Validators.required]],
      "state": ['', [Validators.required]],
      "pincodeId": [''],
      "aadhaarFrontUpload": ['', [Validators.required]],
      "aadhaarBackUpload": ['', [Validators.required]],
      "firstName" : ['', [Validators.required]],
      "lastName" : ['', [Validators.required]],
      "userDocuments":[],
      "status":[2],
      "userId":[0],
      "documentId":[0],
      "documentId2":[0]
    });

    this.savePanForm = this.fb.group({
      "uploadPAN": ['', [Validators.required]],
      "panNumber": ['', [Validators.required,Validators.pattern('[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}')]],
      "panName": ['',[Validators.required]],
      "dob":['',[Validators.required]],
      "userDocument":[],
      "documentId":[0],
      "status":[2],
      "userId":[0]
    });

    this.saveImageForm = this.fb.group({
      "agentSelfie": ['', [Validators.required]],
      "documentId":[0],
      "status":[2],
      "userId":[0]
    });

    this.saveEducationForm = this.fb.group({
      "qualificationID": ['', [Validators.required]],
      "uploadMarksheet": ['', [Validators.required]],
      "userDocument":[],
      "documentId":[0],
      "status":[2],
      "userId":[0]
    });

    this.saveBankDetailForm = this.fb.group({
      "accountNumber": ['', [Validators.required]],
      "confirmAccountNumber": ['', [Validators.required]],
      "accountHolderName": ['', [Validators.required]],
      "ifscCode": ['', [Validators.required]],
      "uploadPassbook": ['',[Validators.required]],
      "bankName": ['',[Validators.required]],
      "branchName": ['',[Validators.required]],
      "userDocument":[],
      "documentId":[0],
      "status":[2],
      "userId":[0]
    });

    this.getStatesByCountry('India');
    this.bindDistributorDDL();
    this.getLanguageList();
  }
  ngOnInit(): void {
  }

  get agentFormControl() {
    return this.agentForm.controls;
  }
  get companyDetailsFormControl() {
    return this.companyForm.controls;
  }
  // get uploadDocumentsFormControl() {
  //   return this.documentsUploadForm.controls;
  // }
  changeTab(tab:number)
  {
    this.activeTab = tab;
  }

  async saveNext(tab:number,isPrevious:boolean = false, save:boolean = false)
  {    
    if(tab == 1)
    {
      this.activeTab = tab; 
    }
    else if(tab == 2)
    {
      if(isPrevious == true)
      {
        this.activeTab = tab;
      }
      else
      {
        this.submittedDisForm = true;
        if(this.agentForm.valid)
        {
          if(save == true){
            let agentDetails =this.agentForm.value;
            agentDetails.updateFor = "PROF";
            const res = await this.adminService.AgentInfo(agentDetails);    
            if (res != null && res.result) {
              swal.fire({
                position: 'center',
                icon: 'success',
                title: res.result.message,
                showConfirmButton: false,
                timer: 3000,
              });
              this.activeTab = tab;
            }  
            else
            {
              swal.fire({
                position: 'center',
                icon: 'error',
                title: res.result.message,
                showConfirmButton: false,
                timer: 3000,
              });
            }
          }
          else{
            this.activeTab = tab;
          }
        }   
      }      
    }
    else if(tab == 3)
    {
      if(isPrevious == true)
      {
        this.activeTab = tab;
      }
      else
      {
        this.submittedCompForm = true;
        if(this.companyForm.valid)
        {
          if(save == true){
            let agentDetails =this.agentForm.value;
            agentDetails.companyDetails = this.companyForm.value;
            agentDetails.updateFor = "COMP";
            const res = await this.adminService.AgentInfo(agentDetails);    
            if (res != null && res.result) {
              swal.fire({
                position: 'center',
                icon: 'success',
                title: res.result.message,
                showConfirmButton: false,
                timer: 3000,
              });
              this.activeTab = tab;
            }  
            else
            {
              swal.fire({
                position: 'center',
                icon: 'error',
                title: res.result.message,
                showConfirmButton: false,
                timer: 3000,
              });
            }
          }
          else{
            this.activeTab = tab;
          }
        }   
    }
    }
    else if(tab == 4)
    {
      if(this.saveAdhaarForm.valid && this.savePanForm.valid && this.saveImageForm.valid && this.saveEducationForm.valid && this.saveBankDetailForm.valid){
        this.activeTab = tab;
      }
      else{
        let msg = ""
        if(this.saveAdhaarForm.valid == false){
          msg += "Please fill Aadhar card details";
        }
        if(this.savePanForm.valid == false){
          if(msg == ""){
            msg += "Please fill PAN card details";
          }
          else
          {
            msg += ", PAN card details"
          }          
        }
        if(this.saveImageForm.valid == false){
          if(msg == ""){
            msg += "Please fill Profile Image";
          }
          else
          {
            msg += ", Profile Image"
          }          
        }
        if(this.saveEducationForm.valid == false){
          if(msg == ""){
            msg += "Please fill Educational Qualification";
          }
          else
          {
            msg += ", Educational Qualification"
          }          
        }
        if(this.saveBankDetailForm.valid == false){
          if(msg == ""){
            msg += "Please fill Bank Details";
          }
          else
          {
            msg += ", Bank Details"
          }          
        }

        if(msg != "" ){
          msg += " form." 
          swal.fire({
            position: 'center',
            icon: 'error',
            title: msg,
            showConfirmButton: false,
            timer: 3000,
          });
        }
      }
    }
  }

  async saveAgentData()
  {
    swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Agent updated successfully.',
      showConfirmButton: false,
      timer: 3000,
    });
    this.router.navigate(['/agents']);
      // this.submittedAadharForm = true;
      // let agentDetails =this.agentForm.value;
      // agentDetails.companyDetails = this.companyForm.value;
      // //agentDetails.userDocuments = this.documentsUploadForm;
      // const res = await this.adminService.AgentInfo(agentDetails);    
      // if (res != null && res.result) {
      //   swal.fire({
      //     position: 'center',
      //     icon: 'success',
      //     title: 'Agent created successfully.',
      //     showConfirmButton: false,
      //     timer: 3000,
      //   });
      //   this.router.navigate(['/agents']);
      // }    
      // else
      // {
      //   swal.fire({
      //     position: 'center',
      //     icon: 'error',
      //     title: 'Something went wrong',
      //     showConfirmButton: false,
      //     timer: 3000,
      //   });
      // } 
  }

  async getStatesByCountry(country:string)
  {
    let response = await this._lookupService.getStatesListByCountry(country); 
    if (response?.isSuccess) {
      this.stateList = response.result.map((item:any)=> {return {text:item.name,value:item.name,id:item.name}});
    }
    console.log(this.stateList);
  }

  async getDistrictsByState(state:string)
  {
    let response = await this._lookupService.getDistrictsListByState(state); 
    if (response?.isSuccess) {
      this.districtList = response.result.map((item:any)=> {return {text:item.name,value:item.name,id:item.name}});
    }
  }
  async getPincodesByDistrict(district:string)
  {
    let response = await this._lookupService.getPincodeByDistrict(district); 
    if (response?.isSuccess) {
      //this.pincodeList = response.result.map((item:any)=> {return {text:item.pincode,value:item.pincode,text1: item.cityVillageArea, id:item.id}});
      this.cityList = response.result.map((item:any)=> {return {text:item.cityVillageArea,value:item.cityVillageArea, text1:item.pincode, id:item.id}});
    }
  }

  onStateSelection(data:AutoCompleteItem){
    this.getDistrictsByState(data.text);
    this.companyForm.get('companyState')?.setValue(data.text);
    //console.log(this.companyForm.value);
   }
 
   onDistrictSelection(data:AutoCompleteItem){
    //  this.companyForm.get('city')?.setValue(data.text);
      this.getPincodesByDistrict(data.text);
    //  console.log(this.companyForm.value); 
   }
 
   onPincodeSelection(data:AutoCompleteItem)
   {
     debugger;
     this.getDistrictsByState(data.pincodeMaster?.state?.toUpperCase()??"");
     this.getPincodesByDistrict(data.pincodeMaster?.district?.toUpperCase()??"");
    this.companyForm.get('companyPincode')?.setValue(data.text);
    this.companyForm.get('companyCity')?.setValue(data?.pincodeMaster?.district?.toUpperCase());
    this.companyForm.get('companyState')?.setValue(data?.pincodeMaster?.state?.toUpperCase());
  }

  async onPincodeChange(data:any) {
   if(data.length >= 3){
     const res = await this._lookupService.pincodeMasterDetails(data);
     if (res != null && res.result) {
       this.pincodeList = res.result.map((item:any)=> {return {text:item.pincode,value:item.pincode,text1: item.cityVillageArea, id:item.id,pincodeMaster:item}});
       //this.pincodeList = res.result.map((item:any)=> {return {text:item.pincode,value:item.pincode,text1: item.cityVillageArea, id:item.id,pincodeMaster:item}});
     }
   }    
 }
 
    onCitySelection(data:AutoCompleteItem)
    {
     this.companyForm.get('companyCity')?.setValue(data.text);
     // console.log(this.userForm.value);
    }
 
    onStateBlur(data:any)
    {
       if(data == ""){
         this.companyForm.get('companyState')?.setValue(data);
       }
    }
 
    onPincodeBlur(data:any)
    {
      this.companyForm.get('companyPincode')?.setValue(data);
    }
 
    onCityBlur(data:any)
    {
     if(data == ""){
       this.companyForm.get('companyCity')?.setValue(data);
     }
    }
 
    onDistrictBlur(data:any)
    {
    //  if(data == ""){
    //    this.companyForm.get('city')?.setValue(data);
    //  }
    }
  numberOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode >= 48 && charCode <= 57) {
      return true;
    }
    return false;
  }

  alphabetOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122)) {
      return true;
    }
    return false;
  }

  alphabetWithSpaces(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode == 32) {
      return true;
    }
    return false;
}

  alphaNumericOnly(event:any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode >= 48 && charCode <= 57) || ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))) {
      return true;
    }
    return false;
  }

  async SetFileType(baseUpload64FileData:string) {
    let format=''; 
    if (baseUpload64FileData.includes(`application/pdf`)) {
      format = 'pdf';
    }
    else if (baseUpload64FileData.includes(`image`)) {
      format = 'image';
    }
    else if (baseUpload64FileData.includes(`video`)) {
      format = 'video';
    }
    return format;
  }

  async onSelectFile(file: any) {
    debugger;
    let fileToUpload = file.target.files.item(0);
    // if (this.fileToUpload?.type.includes('image') && this.fileToUpload!.size > 500000) {
    //   this.baseUpload64FileData = '';
    //   this.documentForm.get('documentBase64')?.setValue('');
    //   swal.fire({
    //     position: 'center',
    //     icon: 'error',
    //     title: 'File size should not be greather than 500kb',
    //     showConfirmButton: false,
    //     timer: 3000,
    //   });
    // }
    // else if (this.fileToUpload?.type.includes('pdf') && this.fileToUpload!.size > 5000000) {
    //   this.baseUpload64FileData = '';
    //   this.documentForm.get('documentBase64')?.setValue('');
    //   swal.fire({
    //     position: 'center',
    //     icon: 'error',
    //     title: 'File size should not be greather than 5MB',
    //     showConfirmButton: false,
    //     timer: 3000,
    //   });
    // }
    // else if (this.fileToUpload?.type.includes('video') && this.fileToUpload!.size > 50000000) {
    //   this.baseUpload64FileData = '';
    //   this.documentForm.get('documentBase64')?.setValue('');
    //   swal.fire({
    //     position: 'center',
    //     icon: 'error',
    //     title: 'File size should not be greather than 50MB',
    //     showConfirmButton: false,
    //     timer: 3000,
    //   });
    // }
    // else {
      let reader = new FileReader();
      reader.onload = async (event: any) => {
        let baseUpload64FileData = reader.result!.toString();
        //this.documentForm.get('documentBase64')?.setValue(baseUpload64FileData);
        let fileType = await this.SetFileType(baseUpload64FileData);
        // let docObj:DocumentDetails={
        //   DocumentTypeName:fileType,
        //   DocumentBase64:baseUpload64FileData,
        //   DocumentNumber:'12346',
        //   UniqueUserId:'123456',
        //   DocumentUniqueId:'123456',
        //   DocumentTypeId:1,
        //   Verified:1,
        //   DocumentStatus:1
        // }
        // this.documentsUploadForm.push(docObj);
        // console.log(this.documentsUploadForm);
      }
      reader.readAsDataURL(fileToUpload);
    //}
  }

  pancardSelected(docObj1:DocumentDetails)
  {
      this.panDocObj =docObj1;
      this.companyForm.get('companyPANDocBase64')?.setValue(docObj1.documentBase64);
  }

  gstinSelected(docObj1:DocumentDetails)
  {
      this.gstinDocObj = docObj1;
      this.companyForm.get('companyGSTINDocBase64')?.setValue(docObj1.documentBase64);
  }

  deleteFile(docTypeId:number)
  {
    if(docTypeId == 1){
      this.companyForm.get('companyPANDocBase64')?.setValue('');
      this.panDocObj.documentBase64="";
    }
    else if(docTypeId == 7){
      this.companyForm.get('companyGSTINDocBase64')?.setValue('');
      this.gstinDocObj.documentBase64="";
    }
  }

  async getFormData(id:number)
  {
    const res = await this.adminService.GetAgentById(id);  
      if (res != null && res.result) {
        this.agentForm.patchValue(res.result);
        this.bindResellerDDL(this.agentForm.value.distributorId);
        this.companyForm.patchValue(res.result.companyDetails);
        //this.documentsUploadForm = res.result.userDocuments;
        this.state = res.result.companyDetails?.companyState??"";
        this.city =  res.result.companyDetails?.companyCity??"";
        this.pincode =  res.result.companyDetails?.companyPincode??"";

        this.panDocObj.documentBase64 = res?.result?.companyDetails?.companyPANDocBase64;
        this.panDocObj.documentDownloadName = res?.result?.companyDetails?.companyPANDocFilename;
        this.panDocObj.documentName = res?.result?.companyDetails?.companyPANDocFilename;

        this.gstinDocObj.documentBase64 = res?.result?.companyDetails?.companyGSTINDocBase64;
        this.gstinDocObj.documentDownloadName = res?.result?.companyDetails?.companyGSTINFilename;
        this.gstinDocObj.documentName = res?.result?.companyDetails?.companyGSTINFilename;

        const resAadhar = await this.adminService.GetAadharDetailsByUserId(this.agentForm.value.userId?.toString(), true);
        if (resAadhar != null && resAadhar.result) {
          this.saveAdhaarForm.patchValue(resAadhar.result);

          if(this.saveAdhaarForm != null){
            let value = this.saveAdhaarForm.get('aadhaarNumber')?.value;
            let newValue = "";
            for (let i = 0; i < value.length; i += 4) {
              newValue += value.substr(i, 4) + ' ';
            }
            this.saveAdhaarForm.get('aadhaarNumber')?.setValue(newValue);
          }

          this.aadharFrontDocObj.documentBase64 = resAadhar?.result?.aadhaarFrontUpload;  
          this.aadharFrontDocObj.documentDownloadName = resAadhar?.result?.documentFilename;
          this.aadharFrontDocObj.documentName = resAadhar?.result?.documentFilename;

          this.aadharBackDocObj.documentBase64 = resAadhar?.result?.aadhaarBackUpload;
          this.aadharBackDocObj.documentDownloadName = resAadhar?.result?.document2Filename;
          this.aadharBackDocObj.documentName = resAadhar?.result?.document2Filename;
        }

        const resPAN = await this.adminService.GetPanDetailsByUserId(this.agentForm.value.userId?.toString(), true);
        if (resPAN != null && resPAN.result) {
          this.savePanForm.patchValue(resPAN.result);

          this.agentPanDocObj.documentBase64 = resPAN?.result?.uploadPAN;  
          this.agentPanDocObj.documentDownloadName = resPAN?.result?.documentFilename;
          this.agentPanDocObj.documentName = resPAN?.result?.documentFilename;
        }

        const resSelfie = await this.adminService.GetProfileImgDetailsByUserId(this.agentForm.value.userId?.toString(), true);
        if (resSelfie != null && resSelfie.result) {
          this.saveImageForm.patchValue(resSelfie.result);

          this.agentSelfieDocObj.documentBase64 = resSelfie?.result?.agentSelfie;  
          this.agentSelfieDocObj.documentDownloadName = resSelfie?.result?.documentFilename;
          this.agentSelfieDocObj.documentName = resSelfie?.result?.documentFilename;
        }

        const resEduc = await this.adminService.GetQualficationDetailsByUserId(this.agentForm.value.userId?.toString(), true);
        if (resEduc != null && resEduc.result) {
          this.saveEducationForm.patchValue(resEduc.result);

          this.agentEducDocObj.documentBase64 = resEduc?.result?.uploadMarksheet;  
          this.agentEducDocObj.documentDownloadName = resEduc?.result?.documentFilename;
          this.agentEducDocObj.documentName = resEduc?.result?.documentFilename;
        }

        const resBank = await this.adminService.GetBankDetailsByUserId(this.agentForm.value.userId?.toString(), true);
        if (resBank != null && resBank.result) {
          this.saveBankDetailForm.patchValue(resBank.result);

          this.agentBankDocObj.documentBase64 = resBank?.result?.uploadPassbook;  
          this.agentBankDocObj.documentDownloadName = resBank?.result?.documentFilename;
          this.agentBankDocObj.documentName = resBank?.result?.documentFilename;
        }

        // this.onStateSelection(data:AutoCompleteItem) 
        // this.onDistrictSelection(data:AutoCompleteItem) 
        // this.onPincodeSelection(data:AutoCompleteItem)    
        // this.onCitySelection(data:AutoCompleteItem)

        // for(let i=0;i<this.documentsUploadForm.length;i++)
        // {
        //   if(this.documentsUploadForm[i].documentTypeId == 1)
        //   {
        //     this.panDocObj = this.documentsUploadForm[i];
        //   }
        //   else if(this.documentsUploadForm[i].documentTypeId == 7)
        //   {
        //     this.gstinDocObj = this.documentsUploadForm[i];
        //   }
        // }        
        this.setSelectedLanguage();
      }    
      else
      {
        // swal.fire({
        //   position: 'center',
        //   icon: 'error',
        //   title: 'Something went wrong',
        //   showConfirmButton: false,
        //   timer: 3000,
        // });
      } 
  }
  async bindDistributorDDL()
  {
    const res = await this.adminService.DistributorDropDown();  
    if (res != null && res.result) {
        this.distributorList = res.result.map((m:any)=>{return {id:m.id,name:m.name}});
    }     
  }

  onDistributorChange(event:any)
  {
    this.bindResellerDDL(event.target.value);
  }

  async bindResellerDDL(distributorId:any)
  {
    const res = await this.adminService.ResellerDropDownByDistributorId(distributorId);  
    if (res != null && res.result) {
        this.resellerList = res.result.map((m:any)=>{return {id:m.id,name:m.name}});
    }  
  }

  // isPANCardUploaded():boolean{
  //   return (this.panDocObj.documentBase64 != "" && this.documentsUploadForm.filter((f:any)=>{ return f.documentTypeId == 1}).length > 0)
  // }

  // isGSTNDocUploaded():boolean{
  //   return (this.gstinDocObj.documentBase64!="" && this.documentsUploadForm.filter((f:any)=>{ return f.documentTypeId == 7}).length > 0)
  // }

  async getLanguageList(){
    let response = await this._lookupService.GetLanguageDetails(); 
    if (response?.isSuccess) {
      this.languageList = response.result;
      this.setSelectedLanguage();
    }
  }

  onMultiSelectChange(selectItems: Item[]): void {
    if(selectItems && selectItems.length > 0){
      this.agentForm.get("language")?.setValue(selectItems.map((item:Item) => {return item.id}).toString());
      this.languageText = selectItems.map((item:Item) => {return item.name}).join(', ');
    }
    else
    {
      this.agentForm.get("language")?.setValue("");
      this.languageText = "";
    }
  }

  setSelectedLanguage(){
    if(this.agentForm.get("language")?.value != null && this.agentForm.get("language")?.value != undefined && this.agentForm.get("language")?.value != "" && this.agentForm.get("language")?.value != "0" && this.languageList?.length > 0)
      {
        let langList = this.agentForm.get("language")?.value.split(",");
        this.languageText = this.languageList.filter((f:any)=> {return langList.includes(f.id.toString())}).map((m:any)=>{return m.name}).join(', ');
        this.languageList = this.languageList.map((obj:Item) => {
          return {...obj, checked : langList.includes(obj?.id?.toString())}
        })
      }
  }

  get AdharFormControl() {
    return this.saveAdhaarForm.controls;
  }

  async pincodeChange(data: any) {
    if (data.length >= 3) {
      const res = await this._lookupService.pincodeMasterDetails(data)

      if (res != null && res.result) {
        this.pincodeList = res.result.map((item: any) => { return { text: item.pincode, value: item.pincode, text1: item.cityVillageArea, id: item.id, pincodeMaster: item } });
      }
    }
  }
  onAadharPincodeSelection(data: AutoCompleteItem) {
    this.getDistrictsByState(data.pincodeMaster?.state?.toUpperCase() ?? "");
    this.getPincodesByDistrict(data.pincodeMaster?.district?.toUpperCase() ?? "");
    this.saveAdhaarForm.get('pincode')?.setValue(data.pincodeMaster?.pincode);
    this.saveAdhaarForm.get('pincodeId')?.setValue(data.pincodeMaster?.pincode?.toUpperCase());
    this.saveAdhaarForm.get('city')?.setValue(data.pincodeMaster?.district?.toUpperCase());
    //this.saveAdhaarForm.get('district')?.setValue(data.pincodeMaster?.district?.toUpperCase());
    this.saveAdhaarForm.get('state')?.setValue(data.pincodeMaster?.state?.toUpperCase());
  }

  formatInput(event: any) {
    let input = event.target as HTMLInputElement;
    let value = input.value.replace(/\s+/g, ''); // Remove existing spaces
    let newValue = '';

    for (let i = 0; i < value.length; i += 4) {
      newValue += value.substr(i, 4) + ' ';
    }
    this.saveAdhaarForm.get('aadhaarNumber')?.setValue(newValue.trim());
    // if (this.saveAdhaarForm.get('aadhaarNumber')?.value?.length < 14) {
    //   { this.button1 = false; }
    // }
  }

  aadharFrontSelected(docObj1:DocumentDetails)
  {    
      this.aadharFrontDocObj = docObj1;
      this.saveAdhaarForm.get('aadhaarFrontUpload')?.setValue(docObj1.documentBase64);
  }

  aadharBackSelected(docObj1:DocumentDetails)
  {    
      this.aadharBackDocObj = docObj1;
      this.saveAdhaarForm.get('aadhaarBackUpload')?.setValue(docObj1.documentBase64);
  }

  agentPANSelected(docObj1:DocumentDetails)
  {    
      this.agentPanDocObj = docObj1;
      this.savePanForm.get('uploadPAN')?.setValue(docObj1.documentBase64);
  }

  agentSelfieSelected(docObj1:DocumentDetails)
  {    
      this.agentSelfieDocObj = docObj1;
      this.saveImageForm.get('agentSelfie')?.setValue(docObj1.documentBase64);
  }

  agentEduSelected(docObj1:DocumentDetails)
  {    
      this.agentEducDocObj = docObj1;
      this.saveEducationForm.get('uploadMarksheet')?.setValue(docObj1.documentBase64);
  }

  agentBankSelected(docObj1:DocumentDetails)
  {    
      this.agentBankDocObj = docObj1;
      this.saveBankDetailForm.get('uploadPassbook')?.setValue(docObj1.documentBase64);
  }

  deleteAgentFile(deletefor:string){
    switch(deletefor){
     case 'ADR1' : 
     this.saveAdhaarForm.get('aadhaarFrontUpload')?.setValue('');
     this.aadharFrontDocObj.documentBase64 = '';
     break;
     case 'ADR2' : 
     this.saveAdhaarForm.get('aadhaarBackUpload')?.setValue('');
     this.aadharBackDocObj.documentBase64 = '';
     break;
     case 'PAN' : 
     this.savePanForm.get('uploadPAN')?.setValue('');
     this.agentPanDocObj.documentBase64 = '';
     break;
     case 'SELF' : 
     this.saveImageForm.get('agentSelfie')?.setValue('');
     this.agentSelfieDocObj.documentBase64 = '';
     break;
     case 'QUAL' : 
     this.saveEducationForm.get('uploadMarksheet')?.setValue('');
     this.agentEducDocObj.documentBase64 = '';
     break;
     case 'BANK' : 
     this.saveBankDetailForm.get('uploadPassbook')?.setValue('');
     this.agentBankDocObj.documentBase64 = '';
     break;
    }
  }

  async saveNextAgentDoc(tab:number,isPrevious:boolean = false, save:boolean = false)
  {    
    if(tab == 1)
    {
      this.activeAgentDocTab = tab; 
    }
    else if(tab == 2)
    {
      if(isPrevious == true)
      {
        this.activeAgentDocTab = tab;
      }
      else
      {
        this.submittedAadharForm = true;
        if(this.saveAdhaarForm.valid)
        {
          if(save == true){
            let agentDetails =this.agentForm.value;
            agentDetails.updateFor = "ADAR";
            agentDetails.agentAadhar = this.saveAdhaarForm.value
            const res = await this.adminService.AgentInfo(agentDetails);    
            if (res != null && res.result) {
              swal.fire({
                position: 'center',
                icon: 'success',
                title: res.result.message,
                showConfirmButton: false,
                timer: 3000,
              });
              this.activeAgentDocTab = tab;
            }  
            else
            {
              swal.fire({
                position: 'center',
                icon: 'error',
                title: res.result.message,
                showConfirmButton: false,
                timer: 3000,
              });
            }
          }
          else{
            this.activeAgentDocTab = tab;
          }
        }   
      }      
    }
    else if(tab == 3)
    {
      if(isPrevious == true)
      {
        this.activeAgentDocTab = tab;
      }
      else
      {
        this.submittedPANForm = true;
        if(this.savePanForm.valid)
        {
          if(save == true){
            let agentDetails =this.agentForm.value;
            agentDetails.agentPAN = this.savePanForm.value;
            agentDetails.updateFor = "PANC";
            const res = await this.adminService.AgentInfo(agentDetails);    
            if (res != null && res.result) {
              swal.fire({
                position: 'center',
                icon: 'success',
                title: res.result.message,
                showConfirmButton: false,
                timer: 3000,
              });
              this.activeAgentDocTab = tab;
            }  
            else
            {
              swal.fire({
                position: 'center',
                icon: 'error',
                title: res.result.message,
                showConfirmButton: false,
                timer: 3000,
              });
            }
          }
          else{
            this.activeAgentDocTab = tab;
          }
        }   
      }
    }
    else if(tab == 4)
    {
      this.submittedSelfieForm = true;
      if(this.saveImageForm.valid)
        {
          if(save == true){
            let agentDetails =this.agentForm.value;
            agentDetails.agentImages = this.saveImageForm.value;
            agentDetails.updateFor = "SELF";
            const res = await this.adminService.AgentInfo(agentDetails);    
            if (res != null && res.result) {
              swal.fire({
                position: 'center',
                icon: 'success',
                title: res.result.message,
                showConfirmButton: false,
                timer: 3000,
              });
              this.activeAgentDocTab = tab;
            }  
            else
            {
              swal.fire({
                position: 'center',
                icon: 'error',
                title: res.result.message,
                showConfirmButton: false,
                timer: 3000,
              });
            }
          }
          else{
            this.activeAgentDocTab = tab;
          }
        }
    }
    else if(tab == 5)
    {
      this.submittedQualForm = true;
      if(this.saveEducationForm.valid)
        {
          if(save == true){
            let agentDetails =this.agentForm.value;
            agentDetails.agentQualification = this.saveEducationForm.value;
            agentDetails.updateFor = "QUAL";
            const res = await this.adminService.AgentInfo(agentDetails);    
            if (res != null && res.result) {
              swal.fire({
                position: 'center',
                icon: 'success',
                title: res.result.message,
                showConfirmButton: false,
                timer: 3000,
              });
              this.activeAgentDocTab = tab;
            }  
            else
            {
              swal.fire({
                position: 'center',
                icon: 'error',
                title: res.result.message,
                showConfirmButton: false,
                timer: 3000,
              });
            }
          }
          else{
            this.activeAgentDocTab = tab;
          }
        }
    }
    else if(tab == 6)
    {
      this.submittedBankForm = true;
      if(this.saveBankDetailForm.valid)
        {
          if(save == true){
            let agentDetails =this.agentForm.value;
            this.saveBankDetailForm.get('accountNumber')?.setValue(this.saveBankDetailForm.value?.accountNumber?.toString());
            this.saveBankDetailForm.get('confirmAccountNumber')?.setValue(this.saveBankDetailForm.value?.confirmAccountNumber?.toString());
            agentDetails.agentBank = this.saveBankDetailForm.value;
            agentDetails.updateFor = "BANK";
            const res = await this.adminService.AgentInfo(agentDetails);    
            if (res != null && res.result) {
              swal.fire({
                position: 'center',
                icon: 'success',
                title: res.result.message,
                showConfirmButton: false,
                timer: 3000,
              });
              //this.activeAgentDocTab = 1;
              this.activeTab = 4;
            }  
            else
            {
              swal.fire({
                position: 'center',
                icon: 'error',
                title: res.result.message,
                showConfirmButton: false,
                timer: 3000,
              });
            }
          }
          else{
            this.activeTab = 4;
          }
        }
    }
  }

  get PanForm() {
    return this.savePanForm.controls;
  }

  get BankDetails()
  {
    return this.saveBankDetailForm.controls;
  }

  validateNumberInput(event: KeyboardEvent): void {
    // Allow: backspace, delete, tab, escape, enter, and numbers from the numpad and numbers row
    if ([46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
         // Allow: Ctrl/cmd+A
        (event.keyCode === 65 && (event.ctrlKey === true || event.metaKey === true)) ||
         // Allow: Ctrl/cmd+C
        (event.keyCode === 67 && (event.ctrlKey === true || event.metaKey === true)) ||
         // Allow: Ctrl/cmd+X
        (event.keyCode === 88 && (event.ctrlKey === true || event.metaKey === true)) ||
         // Allow: home, end, left, right
        (event.keyCode >= 35 && event.keyCode <= 39)) {
         // let it happen, don't do anything
         return;
    }
    // Ensure that it is a number and stop the keypress if not
    if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
        event.preventDefault();
    }
  }  

  onInput(event: any): void {
    const initialValue = event.target.value;
    // Strictly replace anything not a digit.
    event.target.value = initialValue.replace(/[^0-9]/g, '');
    if (initialValue !== event.target.value) {
      event.stopPropagation();
    }
  }
}

// 1	Pan Card
// 2	Aadhaar Card
// 3	Driving License
// 4	Passport
// 5	VoterId Card
// 6	Form60
// 7	GSTIN
// 8	Policy
// 9	Profile Image
// 10	Education
// 11	Bank Details