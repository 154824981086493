<app-mainscreen>
    <app-simple-page-heading [breadcrumbs]="['Dashboard > Distributor']" title="Distributor" ></app-simple-page-heading>
  <div style="height: 30px;"></div>
    <app-table-view
    [visibleColumnData] = "visibleColumnData"
    [visibleColumnsId] = "visibleColumnsId"
    [graphdata]="graphdata"
    [graphHeader]="graphHeader"
    title="Roles"
    tableBackground="var(--grey3)"
    buttonTitle="Create Role"
   [showIconButtonEnd]="true"
    [showPaginator]="true"  
    (onActionClick)="onActionClick($event)"
    (onCreateBtnClick)="onCreateBtnClick($event)"
    [canCreate]="canCreate"
    [canView]="canView"
    [canDelete]="canDelete"
    [canEdit]="canEdit"
  ></app-table-view>
  <app-stepper-dialog></app-stepper-dialog>
  </app-mainscreen>