<app-mainscreen>
    <app-simple-page-heading [breadcrumbs]="['Dashboard > Reseller']" title="Reseller" ></app-simple-page-heading>
  <div style="height: 15px;" *ngIf="userType != '2'">    
  </div>
  <div class="box_container mt-4 pt-2 rounded-bottom1-0" *ngIf="userType == '2'">
    <div class="box-header mb-4 w-100 pl-2">
        <label class="m-0 box_title_22 pl-1"> Resellers </label>
    </div>
    <div class="row w-100">
        <div class="col-md-3 position-relative">
          <select type="text" id="distributorId" name="distributorId" placeholder=" " class="form-control input_" [(ngModel)]="distributorId">
            <option *ngFor="let dis of distributorList" [value]="dis.id">
                {{dis.name}}
            </option>
            </select>
            <label for="distributorId" class="label_">Distributor</label>
        </div>
        <div class="col-md-1">
          <app-custom-button text="Filter" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="filterResellerData()">                    
          </app-custom-button>
      </div>
    </div>
  </div>
    <app-table-view
    [visibleColumnData] = "visibleColumnData"
    [visibleColumnsId] = "visibleColumnsId"
    [graphdata]="graphdata"
    [graphHeader]="graphHeader"
    title="{{userType == '2' ? '..' : 'Resellers'}}"
    tableBackground="var(--grey3)"
    buttonTitle="Create Reseller"
   [showIconButtonEnd]="true"
    [showPaginator]="true"  
    (onActionClick)="onActionClick($event)"
    (onCreateBtnClick)="onCreateBtnClick($event)"
    [canCreate]="canCreate"
    [canView]="canView"
    [canDelete]="canDelete"
    [canEdit]="canEdit"
    className="{{userType == '2' ? 'rounded-top1-0' : ''}}"
  ></app-table-view>
  <app-stepper-dialog></app-stepper-dialog>
  <!-- <div style="height:30px"></div>
  <app-distributor-create></app-distributor-create> -->
  <!-- <app-distributor-view></app-distributor-view> -->
  <!-- <app-distributor-edit></app-distributor-edit> -->
  </app-mainscreen>