<div class="dot-cont-cls" [style.height] ="dot_Cont_height" [style.width] ="dot_Cont_width" [style.background-color] ="switchon ? dot_Cont_bgColor_on : dot_Cont_bgColor_off"
  (click)="toggleSwitch()" [ngClass]="{'toggle-disabled' : isDisabled == true}" [style.border-radius]="dot_Cont_border_radius">
  <div class="dot-cls" [style.height] ="dot_height" [style.width] ="dot_width"
  [style.background-color] ="dot_bgColor"  [style.font-size]="dot_font_size" [style.color]="dot_color" [style.border-radius]="dot_border_radius" [style.margin-left]="switchon ? dot_on_margin : dot_off_margin">{{switchon ? dot_on_text : dot_off_text}}</div>
</div>
<div class="overlay" *ngIf="isShowPopup">
  <div class="popup2 position-relative col-md-4">
    <div class="modal-header tooltip_ bg-transparent">
      <!-- <h4 class="modal-title">Sign In</h4> -->
      <h3>{{popupTitle}} </h3>
      <button type="button" class="close pull-right" aria-label="Close"  (click)="closePopup()">
        <span aria-hidden="true"></span>
     </button>
    </div>
    <div class="modal-body pt-0">
      <p class="text-grey4" *ngIf="popupSubTitle.length > 0">
        {{popupSubTitle}}
      </p>
      <textarea id="comment"  rows="5" name="comment" placeholder=" " class="form-control input_" [(ngModel)]="comment"
          [ngClass]="{'border border-danger': (formSubmitted && isCommentRequired)}"></textarea>
          <label for="address" class="label_ ta5" [ngClass]="{'required' : isCommentRequired == true }">Comment</label>
          <Span class="text-danger error_span pl-2 " *ngIf="(formSubmitted && isCommentRequired)">
              Comment is required.
          </Span>
      <div class="row">
        <div class="col-6">
          <app-custom-button className="w-100 justify-content-center" text="No" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="closePopup()"></app-custom-button> 
        </div>
        <div class="col-6">
          <app-custom-button className="w-100 justify-content-center btn_outline" text="Yes" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="doToggle()"></app-custom-button> 
        </div>
      </div>    

    </div>
  </div>
</div>