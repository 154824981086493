<div class="notification-tooltip-container">
    <span class="material-symbols-outlined mr-3 cursor-pointer" class="notification-tooltip-input {{className}}" (click)="onClick()"> notifications </span>
    <span class="position-absolute top-0 start-62 translate-middle badge rounded-pill bg-info fw-normal">{{_items.length}}</span>
    <ul #optionsList class="notification-tooltip-options" [ngClass]="{'border-1px':filteredItems.length > 0}" > 
        <div class="view_box_container rounded-bottom1-0 notification-tooltip-options-header cursor-pointer" *ngIf="filteredItems.length > 0" (click)="markAsRead(allIds,true)">
           <span class="text-grey2 font-16">Notifications({{filteredItems.length}})</span> 
           <span class="text-grey3 font-12 d-flex gap-1" *ngIf="filteredItems.length > 0"><span class="material-symbols-outlined font-18"> check_circle </span> Mark all as read</span>
        </div>       
      <li *ngFor="let item of filteredItems; let i = index" class="notification-tooltip-option" [ngClass]="{'border-bottom': i != filteredItems.length-1}">
        <span class="d-flex">
            <span class="text-grey2 font-14">{{ item.title }}</span>
        </span>
        <span class="d-flex text-grey4 font-13 justify-content-between">
            <span class="d-flex gap-2">
                <span>{{item.whenCreated}}</span>
                <span>{{item.reason}}</span>
            </span>
            <span class="cursor-pointer" (click)="markAsRead(item.id)"> Mark as read </span>
        </span>
        <span class="text-grey3 font-13">
            {{item.description}}
        </span>
      </li>
    </ul>
  </div>