<app-mainscreen>
    <div class="box_container">
        <div class="box_header flex-column">
            <label class="box_title_18 border_bottom_grey5 w-100 mb-0 pb-2">Create Agent</label>
            <div class="row w-100">
                <div class="col-12">
                  <ul class="d-flex justify-content-between align-items-center mb-0 flex-row p-3 text-center list-item-container">
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 1}" (click)="changeTab(1)">
                        <img src="/assets/svgIcon/25_.svg" class="logo" />
                        Step 1 : Representative Details
                    </li> 
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 2}" (click)="changeTab(2)">
                        <img src="/assets/svgIcon/50_.svg" class="logo" />
                        Step 2 : Company Details
                    </li> 
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 3}" (click)="changeTab(3)">
                        <img src="/assets/svgIcon/75_.svg" class="logo" />
                        Step 3 : Upload Documents
                    </li> 
                    <li class="list-item w-auto" [ngClass]="{'active': activeTab == 4}" (click)="changeTab(4)">
                        <img src="/assets/svgIcon/100_.svg" class="logo" />
                        Step 4 : Summary
                    </li>            
                  </ul>
                </div>
              </div> 
        </div>       
    </div>
    <div class="box_container mt-3 overflow-initial"> 
        <div id="comprehensive" class="w-100" [ngClass]="{'d-block': activeTab == 1,'d-none' : activeTab !=1}">
            <form id="kt_account_profile_details_form" class="form" [formGroup]="agentForm" novalidate="novalidate">
                <div class="box_header pb-0">
                    <span class="box_title_18">
                        Representative Details
                    </span>                
                    <app-custom-button text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(2)">
                    </app-custom-button>
                </div>
                <div class="p-lr-10">
                    <label class="box_title_18 border_top_grey5 w-100 mb-0 pb-2"></label>                
                        <div class="row">
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((agentFormControl['agentFirstName'].touched  || submittedDisForm) && !agentFormControl['agentFirstName'].valid)}">
                                <input type="text" id="agentFirstName" name="agentFirstName" placeholder=" " class="form-control input_" formControlName="agentFirstName" (keypress)="alphabetOnly($event)"
                                [ngClass]="{'border border-danger': ((agentFormControl['agentFirstName'].touched  || submittedDisForm) && agentFormControl['agentFirstName'].errors?.['required'])}">
                                <label for="agentFirstName" class="label_ required">First Name</label>
                                <Span class="text-danger error_span" *ngIf="((agentFormControl['agentFirstName'].touched  || submittedDisForm) && agentFormControl['agentFirstName'].errors?.['required'])">
                                    First name is required.
                                </Span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((agentFormControl['agentLastName'].touched  || submittedDisForm) && !agentFormControl['agentLastName'].valid)}">
                                <input type="text" id="agentLastName" name="agentLastName" placeholder=" " class="form-control input_" formControlName="agentLastName" (keypress)="alphabetOnly($event)"
                                [ngClass]="{'border border-danger': ((agentFormControl['agentLastName'].touched  || submittedDisForm) && !agentFormControl['agentLastName'].valid)}">
                                <label for="agentLastName" class="label_ required">Last Name</label>
                                <Span class="text-danger error_span" *ngIf="((agentFormControl['agentLastName'].touched  || submittedDisForm) && !agentFormControl['agentLastName'].valid)">
                                    Last name is required.
                                </Span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((agentFormControl['agentEmail'].touched  || submittedDisForm) && !agentFormControl['agentEmail'].valid)}">
                                <input type="text" id="agentEmail" name="agentEmail" placeholder=" " class="form-control input_" formControlName="agentEmail"
                                [ngClass]="{'border border-danger': ((agentFormControl['agentEmail'].touched  || submittedDisForm) && !agentFormControl['agentEmail'].valid)}">
                                <label for="agentEmail" class="label_ required">Email</label>
                                <Span class="text-danger error_span" *ngIf="((agentFormControl['agentEmail'].touched  || submittedDisForm) && agentFormControl['agentEmail'].errors?.['required'])">
                                    Email is required.
                                </Span>
                                <Span class="text-danger error_span" *ngIf="((agentFormControl['agentEmail'].touched  || submittedDisForm) && agentFormControl['agentEmail'].errors?.['pattern'])">
                                    Please provide valid email address.
                                </Span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((agentFormControl['agentMobile'].touched  || submittedDisForm) && !agentFormControl['agentMobile'].valid)}">
                                <input type="text" id="agentMobile" name="agentMobile" placeholder=" " class="form-control input_" formControlName="agentMobile" (keypress)="numberOnly($event)" maxlength="10" minlength="10" onKeyPress="if(this.value.length==10) return false;"
                                [ngClass]="{'border border-danger': ((agentFormControl['agentMobile'].touched  || submittedDisForm) && !agentFormControl['agentMobile'].valid)}">
                                <label for="agentMobile" class="label_ required">Mobile Number</label>
                                <Span class="text-danger error_span" *ngIf="((agentFormControl['agentMobile'].touched  || submittedDisForm) && agentFormControl['agentMobile'].errors?.['required'])">
                                    Mobile number is required.
                                </Span>
                                <Span class="text-danger error_span" *ngIf="((agentFormControl['agentMobile'].touched  || submittedDisForm) && agentFormControl['agentMobile'].errors?.['pattern'])">
                                    Please provide valid mobile number.
                                </Span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((agentFormControl['agentDOB'].touched  || submittedDisForm) && !agentFormControl['agentDOB'].valid)}">
                                <input type="date" id="agentDOB" name="agentDOB" placeholder=" " class="form-control input_" formControlName="agentDOB" [max]="maxDOB" onkeydown="return false"
                                [ngClass]="{'border border-danger': ((agentFormControl['agentDOB'].touched  || submittedDisForm) && !agentFormControl['agentDOB'].valid)}">
                                <label for="agentDOB" class="label_ required">Date of Birth</label>
                                <Span class="text-danger error_span" *ngIf="((agentFormControl['agentDOB'].touched  || submittedDisForm) && !agentFormControl['agentDOB'].valid)">
                                    Date of birth is required.
                                </Span>
                            </div>  
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((agentFormControl['agentGender'].touched  || submittedDisForm) && !agentFormControl['agentGender'].valid)}">
                                <select type="text" id="agentGender" name="agentGender" placeholder=" " class="form-control input_" formControlName="agentGender"
                                [ngClass]="{'border border-danger': ((agentFormControl['agentGender'].touched  || submittedDisForm) && !agentFormControl['agentGender'].valid)}">
                                <option *ngFor="let gen of GenderList" [value]="gen.value">
                                    {{gen.text}}
                                </option>
                                </select>
                                <label for="agentGender" class="label_ required">Gender</label>
                                <Span class="text-danger error_span" *ngIf="((agentFormControl['agentGender'].touched  || submittedDisForm) && !agentFormControl['agentGender'].valid)">
                                    Gender is required.
                                </Span>
                            </div>                          
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((agentFormControl['language'].touched  || submittedDisForm) && !agentFormControl['language'].valid)}">
                                <app-multi-dropdown class="w-100 multiselect_custom input_" [items]="languageList"
                                    [showSearch]="true" [showAll]="false" [showStatus]="false" [showError]="(agentFormControl['language'].touched || submittedDisForm) && !agentFormControl['language'].valid" (itemChange)="onMultiSelectChange($event)" 
                                    placeholder=" " id="language">
                                </app-multi-dropdown>
                                <label class="float-start required label_ms">Language</label> 
                                <Span class="text-danger error_span" *ngIf="((agentFormControl['language'].touched  || submittedDisForm) && !agentFormControl['language'].valid)">
                                    Language is required.
                                </Span>
                            </div>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((agentFormControl['pospCode'].touched  || submittedDisForm) && !agentFormControl['pospCode'].valid)}">
                                <input type="text" id="pospCode" name="pospCode" placeholder=" " class="form-control input_" formControlName="pospCode" (keypress)="alphaNumericOnly($event)" oninput="this.value = this.value.toUpperCase()"
                                [ngClass]="{'border border-danger': ((agentFormControl['pospCode'].touched  || submittedDisForm) && !agentFormControl['pospCode'].valid)}">
                                <label for="pospCode" class="label_ required">Posp Code</label>
                                <Span class="text-danger error_span" *ngIf="((agentFormControl['pospCode'].touched  || submittedDisForm) && !agentFormControl['pospCode'].valid)">
                                    Posp code is required.
                                </Span>
                            </div>
                            <ng-container>
                                <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((agentFormControl['levelId'].touched  || submittedDisForm) && !agentFormControl['levelId'].valid)}">
                                    <select type="text" id="levelId" name="levelId" placeholder=" " class="form-control input_" formControlName="levelId"
                                    [ngClass]="{'border border-danger': ((agentFormControl['levelId'].touched  || submittedDisForm) && !agentFormControl['levelId'].valid)}">
                                    <option *ngFor="let level of agentLevelList" [value]="level.value">
                                        {{level.text}}
                                    </option>
                                    </select>
                                    <label for="levelId" class="label_ required">Level</label>
                                    <Span class="text-danger error_span" *ngIf="((agentFormControl['levelId'].touched  || submittedDisForm) && !agentFormControl['levelId'].valid)">
                                        Level is required.
                                    </Span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="userType =='2'">
                                <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((agentFormControl['distributorId'].touched  || submittedDisForm) && !agentFormControl['distributorId'].valid)}">
                                    <select type="text" id="distributorId" name="distributorId" placeholder=" " class="form-control input_" formControlName="distributorId"
                                    [ngClass]="{'border border-danger': ((agentFormControl['distributorId'].touched  || submittedDisForm) && !agentFormControl['distributorId'].valid)}" (change)="onDistributorChange($event)">
                                    <option value="">Select Distributor</option>
                                    <option *ngFor="let dis of distributorList" [value]="dis.id">
                                        {{dis.name}}
                                    </option>
                                    </select>
                                    <label for="distributorId" class="label_ required">Distributor</label>
                                    <Span class="text-danger error_span" *ngIf="((agentFormControl['distributorId'].touched  || submittedDisForm) && !agentFormControl['distributorId'].valid)">
                                        Distributor is required.
                                    </Span>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="userType =='2' || userType =='3'">
                                <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((agentFormControl['resellerId'].touched  || submittedDisForm) && !agentFormControl['resellerId'].valid)}">
                                    <select type="text" id="resellerId" name="resellerId" placeholder=" " class="form-control input_" formControlName="resellerId"
                                    [ngClass]="{'border border-danger': ((agentFormControl['resellerId'].touched  || submittedDisForm) && !agentFormControl['resellerId'].valid)}">
                                    <option value="">Select Reseller</option>
                                    <option *ngFor="let dis of resellerList" [value]="dis.id">
                                        {{dis.name}}
                                    </option>
                                    </select>
                                    <label for="resellerId" class="label_ required">Reseller</label>
                                    <Span class="text-danger error_span" *ngIf="((agentFormControl['resellerId'].touched  || submittedDisForm) && !agentFormControl['resellerId'].valid)">
                                        Reseller is required.
                                    </Span>
                                </div>
                            </ng-container>
                            <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((agentFormControl['password'].touched  || submittedDisForm) && !agentFormControl['password'].valid)}">
                                <input [type]="this.passwordType" name="password" autocomplete="off" class="form-control bg-transparent input_" formControlName="password"
                                onkeypress="if(this.value.length=='16') return false" (keypress)="passKeyPress($event)" (keyup)="passKeyUp($event)">                                
                                <label for="password" class="label_ required">Password</label>
                                <Span class="text-danger error_span" *ngIf="((agentFormControl['password'].touched  || submittedDisForm) && !agentFormControl['password'].valid)">
                                    Password is required.
                                </Span>
                                <i class="text-grey3" [class]="passwordShow === false ? 'fa fa-eye-slash field-icon fs-4' : 'fa fa-eye field-icon fs-4'" (click)="ShowPassword()"></i>
                                <div class="d-flex">
                                    <div class="power-container my-2"> 
                                      <div id="power-point" [style.width.%]="passBarWidth" [style.background-color]="passBarBGColor"></div> 
                                    </div>
                                    <span class="ml-2" [style.color]="passBarBGColor">{{passBarText}}</span>
                                  </div>
                                  <Span class="text-danger error_span position-relative l-0" *ngIf="passBarText.toUpperCase() != 'STRONG' && submittedDisForm">
                                    Password must have all below mentioned characters.
                                </Span>  
                                <div class="text-main font-13">Password must have : At least 8 characters, Uppercase and lowercase character, special character, number</div>
                            </div>              
                        </div>
                </div>        
            </form>
        </div>
        <div id="comprehensive" class="tab-pane w-100" [ngClass]="{'d-block': activeTab == 2,'d-none' : activeTab !=2}">
            <div class="box_header pb-0">
                <span class="box_title_18">
                    Company Details
                </span>
                <div class="d-flex">
                    <app-custom-button className="btn_outline mr-2" text="Previous" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(1,true)"></app-custom-button> 
                    <!-- <app-custom-button className="mr-2" text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(3, false, true)"></app-custom-button> -->
                    <app-custom-button text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(3, false)"></app-custom-button>
                </div>
            </div>
            <div class="p-lr-10">
                <label class="box_title_18 border_top_grey5 w-100 mb-0 pb-2"></label>  
                <form id="kt_account_profile_details_form" class="form" [formGroup]="companyForm" novalidate="novalidate">     
                    <div class="row">
                        <div class="col-12 position-relative">
                            <label>Upload PAN Card</label>
                            <app-file-upload id="pancard_file" (deletedFile)="deleteFile($event)" (selectedFile)="pancardSelected($event)" [docObj]="panDocObj" acceptType=".png, .jpg, .jpeg"></app-file-upload>
                            <Span class="text-danger error_span" *ngIf="(submittedCompForm) &&  !this.companyForm.get('companyPANDocBase64')?.valid">
                                PAN card document is required.
                            </Span>
                        </div>
                        <div class="col-12 position-relative mt-4">
                            <label>Upload Company GSTIN</label>
                            <app-file-upload id="gstin_file" (deletedFile)="deleteFile($event)" (selectedFile)="gstinSelected($event)" [docObj]="gstinDocObj" acceptType=".png, .jpg, .jpeg"></app-file-upload>
                            <Span class="text-danger error_span" *ngIf="(submittedCompForm) && !this.companyForm.get('companyGSTINDocBase64')?.valid">
                                GSTIN document is required.
                            </Span>	
                        </div> 
                    </div> 
                    <hr class="mb-4">       
                    <div class="row">                        
                        <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyName'].touched  || submittedCompForm) && !companyDetailsFormControl['companyName'].valid)}">
                            <input type="text" id="companyName" name="companyName" placeholder=" " class="form-control input_" formControlName="companyName"
                            [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyName'].touched  || submittedCompForm) && !companyDetailsFormControl['companyName'].valid)}">
                            <label for="agentEmail" class="label_">Company Name</label>
                            <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyName'].touched  || submittedCompForm) && companyDetailsFormControl['companyName'].errors?.['required'])">
                                Company name is required.
                            </Span>
                        </div>
                        <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyGSTIN'].touched  || submittedCompForm) && !companyDetailsFormControl['companyGSTIN'].valid)}">
                            <input type="text" id="companyGSTIN" name="companyGSTIN" placeholder=" " class="form-control input_" formControlName="companyGSTIN" formControlName="companyGSTIN" (keypress)="alphaNumericOnly($event)" oninput="this.value = this.value.toUpperCase()" onkeypress="if(this.value.length == '15') return false;" 
                            [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyGSTIN'].touched  || submittedCompForm) && companyDetailsFormControl['companyGSTIN'].errors?.['required'])}">
                            <label for="companyGSTIN" class="label_">Company GSTIN</label>
                            <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyGSTIN'].touched  || submittedCompForm) && companyDetailsFormControl['companyGSTIN'].errors?.['required'])">
                                Company GSTIN is required.
                            </Span>
                            <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyGSTIN'].touched  || submittedCompForm) && companyDetailsFormControl['companyGSTIN'].errors?.['pattern'])">
                                Please provide valid GSTIN number (Eg. 22AAAAA0000A1Z5).
                            </Span>
                        </div>
                        <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyPancard'].touched  || submittedCompForm) && !companyDetailsFormControl['companyPancard'].valid)}">
                            <input type="text" id="companyPancard" name="companyPancard" placeholder=" " class="form-control input_" formControlName="companyPancard" oninput="this.value = this.value.toUpperCase()"
                            [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyPancard'].touched  || submittedCompForm) && !companyDetailsFormControl['companyPancard'].valid)}">
                            <label for="companyPancard" class="label_">Company PAN card number</label>
                            <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyPancard'].touched  || submittedCompForm) && companyDetailsFormControl['companyPancard'].errors?.['required'])">
                                Company PAN card number is required.
                            </Span>
                            <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyPancard'].touched  || submittedCompForm) && companyDetailsFormControl['companyPancard'].errors?.['pattern'])">
                                Please provide valid PAN card number.
                            </Span>
                        </div>
                        
                        <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyLicenseNo'].touched  || submittedCompForm) && !companyDetailsFormControl['companyLicenseNo'].valid)}">
                            <input type="text" id="companyLicenseNo" name="companyLicenseNo" placeholder=" " class="form-control input_" formControlName="companyLicenseNo" [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyLicenseNo'].touched  || submittedCompForm) && !companyDetailsFormControl['companyLicenseNo'].valid)}"
                            oninput="this.value = this.value.toUpperCase()">
                            <label for="companyLicenseNo" class="label_">Company License Number</label>
                            <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyLicenseNo'].touched  || submittedCompForm) && !companyDetailsFormControl['companyLicenseNo'].valid)">
                                Company license number is required.
                            </Span>
                            <span class="error_span right-12">License Number issued by IRDAI</span>
                        </div>
                        <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyMobileNo'].touched  || submittedCompForm) && !companyDetailsFormControl['companyMobileNo'].valid)}">
                            <input type="text" id="companyMobileNo" name="companyMobileNo" placeholder=" " class="form-control input_" formControlName="companyMobileNo" (keypress)="numberOnly($event)" maxlength="10" minlength="10" onKeyPress="if(this.value.length==10) return false;" [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyMobileNo'].touched  || submittedCompForm) && !companyDetailsFormControl['companyMobileNo'].valid)}">
                            <label for="companyMobileNo" class="label_">Company Mobile Number</label>
                            <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyMobileNo'].touched  || submittedCompForm) && companyDetailsFormControl['companyMobileNo'].errors?.['required'])">
                                Company mobile number is required.
                            </Span>
                            <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyMobileNo'].touched  || submittedCompForm) && companyDetailsFormControl['companyMobileNo'].errors?.['pattern'])">
                                Please provide valid company mobile number.
                            </Span>
                        </div>
                        <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyEmail'].touched  || submittedCompForm) && !companyDetailsFormControl['companyEmail'].valid)}">
                            <input type="text" id="companyEmail" name="companyEmail" placeholder=" " class="form-control input_" formControlName="companyEmail"
                            [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyEmail'].touched  || submittedCompForm) && !companyDetailsFormControl['companyEmail'].valid)}">
                            <label for="companyEmail" class="label_">Company Email</label>
                            <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyEmail'].touched  || submittedCompForm) && companyDetailsFormControl['companyEmail'].errors?.['required'])">
                                Email is required.
                            </Span>
                            <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyEmail'].touched  || submittedCompForm) && companyDetailsFormControl['companyEmail'].errors?.['pattern'])">
                                Please provide valid email address.
                            </Span>
                        </div>
                        <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyStreetAddress'].touched  || submittedCompForm) && !companyDetailsFormControl['companyStreetAddress'].valid)}">
                            <input type="text" id="companyStreetAddress" name="companyStreetAddress" placeholder=" " class="form-control input_" formControlName="companyStreetAddress"
                            [ngClass]="{'border border-danger': ((companyDetailsFormControl['companyStreetAddress'].touched  || submittedCompForm) && !companyDetailsFormControl['companyStreetAddress'].valid)}">
                            <label for="companyStreetAddress" class="label_">Street Address</label>
                            <Span class="text-danger error_span" *ngIf="((companyDetailsFormControl['companyStreetAddress'].touched  || submittedCompForm) && companyDetailsFormControl['companyStreetAddress'].errors?.['required'])">
                                Street address is required.
                            </Span>
                        </div>
                        <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyPincode'].touched  || submittedCompForm) && !companyDetailsFormControl['companyPincode'].valid)}">
                            <app-autocomplete class="w-100" [userInput]="companyDetailsFormControl['companyPincode'].value" className="input_" [items]="pincodeList" placeholder="" labelText="Pincode" (onBlurEvent)="onPincodeBlur($event)" (onKeyUpEvent)="onPincodeChange($event)" (itemSelected)="onPincodeSelection($event)" [supportingText]="true" 
                            [showError]="((companyDetailsFormControl['companyPincode'].touched  || submittedCompForm) && !companyDetailsFormControl['companyPincode'].valid)" [isRequired]="false" 
                            [showErrorRequired]="((companyDetailsFormControl['companyPincode'].touched  || submittedCompForm) && companyDetailsFormControl['companyPincode'].errors?.['required'])"></app-autocomplete> 
                        </div>
                        <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyState'].touched  || submittedCompForm) && !companyDetailsFormControl['companyState'].valid)}">
                            <app-autocomplete class="w-100" [userInput]="companyDetailsFormControl['companyState'].value" className="input_" [items]="stateList" placeholder="" labelText="State" (onBlurEvent)="onStateBlur($event)" (itemSelected)="onStateSelection($event);" 
                            [showError]="((companyDetailsFormControl['companyState'].touched  || submittedCompForm) &&!companyDetailsFormControl['companyState'].valid)" [isRequired]="false" [readonly]="'readonly'"
                            [showErrorRequired]="((companyDetailsFormControl['companyState'].touched  || submittedCompForm) &&!companyDetailsFormControl['companyState'].valid)"></app-autocomplete> 
                        </div>
                        <div class="col-md-6 position-relative" [ngClass]="{'mb-2':((companyDetailsFormControl['companyCity'].touched  || submittedCompForm) && !companyDetailsFormControl['companyCity'].valid)}">
                            <app-autocomplete class="w-100" [userInput]="companyDetailsFormControl['companyCity'].value"className="input_" [items]="districtList" placeholder="" labelText="District/City" (onBlurEvent)="onCityBlur($event)" (itemSelected)="onCitySelection($event)" [supportingText]="true" 
                            [showError]="((companyDetailsFormControl['companyCity'].touched  || submittedCompForm) &&!companyDetailsFormControl['companyCity'].valid)" [isRequired]="false" [readonly]="'readonly'"
                            [showErrorRequired]="((companyDetailsFormControl['companyCity'].touched  || submittedCompForm) &&!companyDetailsFormControl['companyCity'].valid)"></app-autocomplete> 
                        </div> 
                    </div>
                </form>
            </div>  
    </div>
    <div id="comprehensive" class="tab-pane w-100 pb-4" [ngClass]="{'d-block': activeTab == 3,'d-none' : activeTab !=3}">
        <!-- <form id="kt_account_profile_details_form" class="form"  novalidate="novalidate">
            <div class="box_header pb-0">
                <span class="box_title_18">
                    Upload Documents
                </span>
                <div class="d-flex">
                    <app-custom-button className="btn_outline mr-2" text="Previous" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(2,true)"></app-custom-button> 
                    <app-custom-button text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(4,false, true)"></app-custom-button>
                    <app-custom-button text="Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(4,false)"></app-custom-button>
                </div>
            </div>
            <div class="p-lr-10">
                <label class="box_title_18 border_top_grey5 w-100 mb-0 pb-2"></label>                
                    <div class="row">
                        <div class="col-12 position-relative">
                            <label>Upload PAN Card</label>
                            <app-file-upload id="pancard_file" (deletedFile)="deleteFile($event)" (selectedFile)="pancardSelected($event)" [docObj]="panDocObj" acceptType=".png, .jpg, .jpeg"></app-file-upload>
                            <Span class="text-danger error_span" *ngIf="(submittedCompForm) && !isPANCardUploaded()">
                                PAN card document is required.
                            </Span>
                        </div>
                        <div class="col-12 position-relative mt-4">
                            <label>Upload Company GSTIN</label>
                            <app-file-upload id="gstin_file" (deletedFile)="deleteFile($event)" (selectedFile)="gstinSelected($event)" [docObj]="gstinDocObj" acceptType=".png, .jpg, .jpeg"></app-file-upload>
                            <Span class="text-danger error_span" *ngIf="(submittedCompForm) && !isGSTNDocUploaded()">
                                GSTIN document is required.
                            </Span>	
                        </div> 
                    </div>
            </div>        
        </form> -->
        <div class="box_header pb-0">
            <span class="box_title_18">
                Agent Documents
            </span>
            <div class="d-flex">
                <app-custom-button className="btn_outline mr-2" text="Previous" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(2,true)"></app-custom-button> 
                <!-- <app-custom-button className=" mr-2" text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(4,false, true)"></app-custom-button> -->
                <app-custom-button text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNext(4,false)"></app-custom-button>
            </div>
        </div>
        <hr>

        <div class="box_container mx-3">
            <div class="box_header flex-column">
                <div class="row w-100">
                    <div class="col-12">
                      <ul class="d-flex justify-content-around pl-0 align-items-center mb-0 flex-row text-center list-item-container">
                        <li class="list-item w-auto" [ngClass]="{'active': activeAgentDocTab == 1}">
                            Aadhaar Details
                        </li> 
                        <li class="list-item w-auto" [ngClass]="{'active': activeAgentDocTab == 2}">
                            PAN Details
                        </li> 
                        <li class="list-item w-auto" [ngClass]="{'active': activeAgentDocTab == 3}">
                            Profile Image
                        </li> 
                        <li class="list-item w-auto" [ngClass]="{'active': activeAgentDocTab == 4}">
                            Education Details
                        </li> 
                        <li class="list-item w-auto" [ngClass]="{'active': activeAgentDocTab == 5}">
                            Bank Details
                        </li>            
                      </ul>
                    </div>
                  </div> 
            </div>       
        </div>
        <div class="box_container mt-2 mx-3 overflow-initial"> 
            <div id="aadhar" class="w-100" [ngClass]="{'d-block': activeAgentDocTab == 1,'d-none' : activeAgentDocTab !=1}">
                <form class="form p-lr-10" novalidate="novalidate" [formGroup]="saveAdhaarForm">
                    <div class="box_header pb-0">
                        <span class="box_title_18 pl-0">
                            Aadhaar Details
                        </span>                            
                    </div>
                    <hr class="mb-0 mt-2">
                    <div class="row">
                        <div class="col-12 position-relative my-2" [ngClass]="{'mb-3': (submittedAadharForm) && (this.saveAdhaarForm.get('aadhaarFrontUpload')?.value == null || this.saveAdhaarForm.get('aadhaarFrontUpload')?.value == undefined || this.saveAdhaarForm.get('aadhaarFrontUpload')?.value == '')}">
                            <label class="required">Upload Aadhar Front Image</label>
                            <app-file-upload id="aadhaarFrontUpload" (deletedFile)="deleteAgentFile('ADR1')" (selectedFile)="aadharFrontSelected($event)" [docObj]="aadharFrontDocObj" acceptType=".png, .jpg, .jpeg"></app-file-upload>
                            <Span class="text-danger error_span" *ngIf="(submittedAadharForm) && (this.saveAdhaarForm.get('aadhaarFrontUpload')?.value == null || this.saveAdhaarForm.get('aadhaarFrontUpload')?.value == undefined || this.saveAdhaarForm.get('aadhaarFrontUpload')?.value == '')">
                                Aadhar front image is required.
                            </Span>	
                        </div>
                        <div class="col-12 position-relative mt-2" [ngClass]="(submittedAadharForm) && (this.saveAdhaarForm.get('aadhaarBackUpload')?.value == null || this.saveAdhaarForm.get('aadhaarBackUpload')?.value == undefined || this.saveAdhaarForm.get('aadhaarBackUpload')?.value == '') ? 'mb-4' : 'mb-3'">
                            <label class="required">Upload Aadhar Back Image</label>
                            <app-file-upload id="aadhaarBackUpload" (deletedFile)="deleteAgentFile('ADR2')" (selectedFile)="aadharBackSelected($event)" [docObj]="aadharBackDocObj" acceptType=".png, .jpg, .jpeg"></app-file-upload>
                            <Span class="text-danger error_span" *ngIf="(submittedAadharForm) && (this.saveAdhaarForm.get('aadhaarBackUpload')?.value == null || this.saveAdhaarForm.get('aadhaarBackUpload')?.value == undefined || this.saveAdhaarForm.get('aadhaarBackUpload')?.value == '')">
                                Aadhar back image is required.
                            </Span>	
                        </div> 
                    </div>
                    <div class="row mt-3">
                        <div class="col-md-6" [ngClass]="{'mb-2':((AdharFormControl['aadhaarNumber'].touched  || submittedAadharForm) && (AdharFormControl['aadhaarNumber'].errors?.['required'] || AdharFormControl['aadhaarNumber'].errors?.['minlength']))}">
                            <input type="text" name="aadhaarNumber" class="form-control input_"
                                placeholder="1234 1234 1234" formControlName="aadhaarNumber"
                                onkeypress="if(this.value.length=='14') return false" (input)="formatInput($event)"
                                minlength="14"> 
                            <label for="" class="required label_">Aadhaar Card Number</label>
                            <!-- <div *ngIf="!button1">
                                <button type="submit" class="btn bt-rd btn-primary validate"
                                    (click)="adhaarValidate()">Validate</button>
                            </div>
                            <div *ngIf="button1">
                                <button type="button" class="btn bt-rd btn-primary verified"
                                    disabled>Verified</button>
                            </div> -->
                            <Span class="text-danger error_span"
                                *ngIf="((AdharFormControl['aadhaarNumber'].touched  || submittedAadharForm) && AdharFormControl['aadhaarNumber'].errors?.['required'])">
                                Aadhar number is required.
                            </Span>
                            <Span class="text-danger error_span"
                                *ngIf="((AdharFormControl['aadhaarNumber'].touched  || submittedAadharForm) && AdharFormControl['aadhaarNumber'].errors?.['minlength'])">
                                Provide valid Aadhar number.
                            </Span>
                            <!-- <Span [ngClass]="aadharCode != 200 ? 'text-danger':'text-success'"
                                *ngIf="((AdharFormControl['aadhaarNumber'].touched  || submittedAadharForm) && !AdharFormControl['aadhaarNumber'].errors?.['required']) && aadharMsg != '' && aadharCode != 0">
                                {{aadharMsg}}
                            </Span>
                            <Span [ngClass]="'text-danger'"
                                *ngIf="((submittedAadharForm) && AdharFormControl['aadhaarNumber'].value.length == 14) && aadharMsg == '' && aadharCode == 0">
                                Please verify your aadhar number
                            </Span> -->
                        </div>
    
                        <div class="col-md-6">
                            <label class="input-label required position-relative t-n12 mb-0"  for="Date">Gender</label><br>
                            <span class=" position-relative t-n12">
                                <input class="radio-button" type="radio" id="male" name="gender" value="Male"
                                formControlName="gender">
                            Male
                            &nbsp;&nbsp;
                            <input class="radio-button" type="radio" id="female" name="gender" value="Female"
                                formControlName="gender">
                            Female &nbsp;&nbsp;
                            <input class="radio-button" type="radio" id="other" name="gender" value="Other"
                                formControlName="gender">
                            Other
                            </span>
                        </div>
                        <div class="col-md-6" [ngClass]="{'mb-2':((AdharFormControl['firstName'].touched  || submittedAadharForm) && AdharFormControl['firstName'].errors?.['required'])}">                        
                            <input type="text" name="firstName" id="" class="form-control input_" placeholder=" " formControlName="firstName">
                            <label for="" class="label_ required"> First Name</label>
                            <Span class="text-danger error_span" *ngIf="((AdharFormControl['firstName'].touched  || submittedAadharForm) && AdharFormControl['firstName'].errors?.['required'])">
                                First name is required.
                            </Span>
                        </div>
                        <div class="col-md-6" [ngClass]="{'mb-2':((AdharFormControl['lastName'].touched  || submittedAadharForm) && AdharFormControl['lastName'].errors?.['required'])}">
                            <input type="text" name="lastName" id="" class="form-control input_" placeholder=" " formControlName="lastName">
                            <label for="" class="label_ required"> Last Name</label>
                            <Span class="text-danger error_span" *ngIf="((AdharFormControl['lastName'].touched  || submittedAadharForm) && AdharFormControl['lastName'].errors?.['required'])">
                                Last name is required.
                            </Span>
                        </div>
                        <div class="col-md-6" [ngClass]="{'mb-2':((AdharFormControl['address'].touched  || submittedAadharForm) && AdharFormControl['address'].errors?.['required'])}">
                            <input type="text" name="address" id="" class="form-control input_" placeholder=" " formControlName="address">
                            <label for="" class="label_ required"> Address</label>
                            <Span class="text-danger error_span" *ngIf="((AdharFormControl['address'].touched  || submittedAadharForm) && AdharFormControl['address'].errors?.['required'])">
                                Address is required.
                            </Span>
                        </div>
                        <div class="col-md-6" [ngClass]="{'mb-2':(AdharFormControl['pincode'].touched  || submittedAadharForm) && AdharFormControl['pincode'].errors?.['required']}">                        
                            <app-autocomplete  class="w-100" className="input_" (itemSelected)="onAadharPincodeSelection($event)"
                                [userInput]="AdharFormControl['pincode'].value" [items]="pincodeList"
                                [supportingText]="true" placeholder=" " labelText="PIN Code" [isRequired]="true"
                                [showErrorRequired]="(AdharFormControl['pincode'].touched  || submittedAadharForm) && AdharFormControl['pincode'].errors?.['required']"
                                (onKeyUpEvent)="pincodeChange($event)">
                            </app-autocomplete>
                            <!-- <label for="" class="label_ required"> PIN Code</label>
                            <Span class="text-danger" *ngIf="((AdharFormControl['pincode'].touched  || submittedAadharForm) && AdharFormControl['pincode'].errors?.['required'])">
                                Pincode is required.
                            </Span> -->
                        </div>
                        <div class="col-md-6" [ngClass]="{'mb-2':(AdharFormControl['city'].touched  || submittedAadharForm) && AdharFormControl['city'].errors?.['required']}">
                            <app-autocomplete class="w-100" className="input_" (itemSelected)="onCitySelection($event)"
                                [items]="districtList" placeholder=" " labelText="City"  [supportingText]="false" [isRequired]="true"
                                [userInput]="AdharFormControl['city'].value" [readonly]="'readonly'"
                                [showErrorRequired]="(AdharFormControl['city'].touched  || submittedAadharForm) && AdharFormControl['city'].errors?.['required']">
                            </app-autocomplete>
                        </div>  
                        <div class="col-md-6" [ngClass]="{'mb-2':(AdharFormControl['state'].touched  || submittedAadharForm) && AdharFormControl['state'].errors?.['required']}">
                            <app-autocomplete class="w-100" className="input_" [items]="stateList" placeholder=" "
                                (itemSelected)="onStateSelection($event)" labelText="State"  [supportingText]="false" [isRequired]="true"
                                [userInput]="AdharFormControl['state'].value" [readonly]="'readonly'"
                                [showErrorRequired]="(AdharFormControl['state'].touched  || submittedAadharForm) && AdharFormControl['state'].errors?.['required']">
                            </app-autocomplete>
                        </div>
                        <div class="col-md-6">
                            <select type="text" id="status" name="status" placeholder=" " class="form-control input_" formControlName="status"
                                    [ngClass]="{'border border-danger': ((AdharFormControl['status'].touched  || submittedDisForm) && !AdharFormControl['status'].valid)}">
                                <option *ngFor="let item of statusList" [value]="item.value">
                                    {{item.text}}
                                </option>
                            </select>
                            <label for="" class="label_ required">Status</label>
                        </div>
                    </div>
                    <hr class="mt-0 mb-2">
                    <div class="row mb-2">
                        <div class="col-12 text-right">
                            <!-- <app-custom-button className=" mr-2" text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNextAgentDoc(2,false, true)"></app-custom-button> -->
                            <app-custom-button text="Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNextAgentDoc(2,false)"></app-custom-button>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-md-12 text-right">
                            <button type="button" class="btn btn-back mr-2"
                                    [routerLink]="['/agent-profile']" (click)="exit()">Back</button>      
                            <button class="btn btn-primary xyxyxy" (click)="nextStep('pan')"
                                    [disabled]="isAadharValid()">{{isAadharUnderReview == true ? 'Next' : 'Save & Next'}}
                                </button>
                        </div>
                    </div> -->
                </form>
            </div>
            <div id="pan" class="w-100" [ngClass]="{'d-block': activeAgentDocTab == 2,'d-none' : activeAgentDocTab !=2}">
                <form class="form p-lr-10" novalidate="novalidate" [formGroup]="savePanForm">
                    <div class="box_header pb-0">
                        <span class="box_title_18 pl-0">
                            PAN Card Details
                        </span>
                    </div>
                    <hr class="mt-2 mb-0">
                    <div class="row">
                        <div class="col-12 position-relative my-2" [ngClass]="{'mb-3': (submittedPANForm) && (this.savePanForm.get('uploadPAN')?.value == null || this.savePanForm.get('uploadPAN')?.value == undefined || this.savePanForm.get('uploadPAN')?.value == '')}">
                            <label class="required">Upload PAN Card Image</label>
                            <app-file-upload id="uploadPAN" (deletedFile)="deleteAgentFile('PAN')" (selectedFile)="agentPANSelected($event)" [docObj]="agentPanDocObj" acceptType=".png, .jpg, .jpeg"></app-file-upload>
                            <Span class="text-danger error_span" *ngIf="(submittedPANForm) && (this.savePanForm.get('uploadPAN')?.value == null || this.savePanForm.get('uploadPAN')?.value == undefined || this.savePanForm.get('uploadPAN')?.value == '')">
                                PAN card image is required.
                            </Span>	
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-6" [ngClass]="{'mb-2':((PanForm['panNumber'].touched  || submittedPANForm) && !PanForm['panNumber'].valid)}">
                            <input type="text" name="panNumber" class="form-control input_" oninput="this.value = this.value.toUpperCase()"
                                formControlName="panNumber" required=required minlength="10" maxlength="10" placeholder=" ">
                                <label for="panNumber" class="label_ required">PAN card number</label>
                            <Span class="text-danger error_span" *ngIf="((PanForm['panNumber'].touched || submittedPANForm) && PanForm['panNumber'].errors?.['required'])">
                                Pan card number is required.
                            </Span>
                            <span class="text-danger error_span" *ngIf="((PanForm['panNumber'].touched || submittedPANForm) && PanForm['panNumber'].errors?.['pattern'])">
                                Please provide valid PAN card number. 
                            </span>
                        </div>
                        <div class="col-md-6" [ngClass]="{'mb-2':((PanForm['panName'].touched  || submittedPANForm) && !PanForm['panName'].valid)}">                                
                            <input type="text" name="panName" class="form-control input_" formControlName="panName" placeholder=" ">
                            <label for="" class="label_ required">Name on PAN Card</label>
                            <span class="text-danger error_span" *ngIf="((PanForm['panName'].touched || submittedPANForm)&& PanForm['panName'].errors?.['required'])">
                            Name is required. </span>
                        </div>
                        <div class="col-md-6" [ngClass]="{'mb-2' : ((PanForm['dob'].touched || submittedPANForm)&& PanForm['dob'].errors?.['required'])}">
                            <input type="date" [max]="maxDOB" onkeydown="return false" placeholder="DD-MM-YYYY" name="dob" class="form-control input_" formControlName="dob">
                            <label for="" class="label_ required">Date of Birth</label>
                            <span class="text-danger error_span" *ngIf="((PanForm['dob'].touched || submittedPANForm)&& PanForm['dob'].errors?.['required'])">
                                Date of birth is required. 
                            </span>
                        </div>
                        <div class="col-md-6">
                            <select type="text" id="status2" name="status" placeholder=" " class="form-control input_" formControlName="status"                                        >
                                <option *ngFor="let item of statusList" [value]="item.value">
                                    {{item.text}}
                                </option>
                            </select>
                            <label for="" class="label_ required">Status</label>
                        </div>
                    </div>
                    <hr class="mt-0 mb-2">
                    <div class="row mb-2">
                        <div class="col-12 text-right">
                            <app-custom-button className="btn_outline mr-2" text="Previous" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNextAgentDoc(1,true)"></app-custom-button>
                            <!-- <app-custom-button className=" mr-2" text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNextAgentDoc(3,false, true)"></app-custom-button> -->
                            <app-custom-button text="Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNextAgentDoc(3,false)"></app-custom-button>
                        </div>
                    </div>
                </form>
            </div>
            <div id="profile" class="w-100" [ngClass]="{'d-block': activeAgentDocTab == 3,'d-none' : activeAgentDocTab !=3}">
                <form class="form p-lr-10" novalidate="novalidate" [formGroup]="saveImageForm">
                    <div class="box_header pb-0">
                        <span class="box_title_18 pl-0">
                            Profile Image
                        </span>
                    </div>
                    <hr class="mt-2 mb-0">
                    <div class="row">
                        <div class="col-12 position-relative my-2" [ngClass]="{'mb-3': (submittedSelfieForm) && (this.saveImageForm.get('agentSelfie')?.value == null || this.saveImageForm.get('agentSelfie')?.value == undefined || this.saveImageForm.get('agentSelfie')?.value == '')}">
                            <label class="required">Upload Agent Picture</label>
                            <app-file-upload id="agentSelfie" (deletedFile)="deleteAgentFile('SELF')" (selectedFile)="agentSelfieSelected($event)" [docObj]="agentSelfieDocObj" acceptType=".png, .jpg, .jpeg"></app-file-upload>
                            <Span class="text-danger error_span" *ngIf="(submittedSelfieForm) && (this.saveImageForm.get('agentSelfie')?.value == null || this.saveImageForm.get('agentSelfie')?.value == undefined || this.saveImageForm.get('agentSelfie')?.value == '')">
                                Profile image is required.
                            </Span>	
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-6">
                            <select type="text" id="status2" name="status" placeholder=" " class="form-control input_" formControlName="status"                                        >
                                <option *ngFor="let item of statusList" [value]="item.value">
                                    {{item.text}}
                                </option>
                            </select>
                            <label for="" class="label_ required">Status</label>
                        </div>
                    </div>
                    <hr class="mt-0 mb-2">
                    <div class="row mb-2">
                        <div class="col-12 text-right">
                            <app-custom-button className="btn_outline mr-2" text="Previous" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNextAgentDoc(2,true)"></app-custom-button>
                            <!-- <app-custom-button className=" mr-2" text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNextAgentDoc(4,false, true)"></app-custom-button> -->
                            <app-custom-button text="Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNextAgentDoc(4,false)"></app-custom-button>
                        </div>
                    </div>
                </form>
            </div>
            <div id="education" class="w-100" [ngClass]="{'d-block': activeAgentDocTab == 4,'d-none' : activeAgentDocTab !=4}">
                <form class="form p-lr-10" novalidate="novalidate" [formGroup]="saveEducationForm">
                    <div class="box_header pb-0">
                        <span class="box_title_18 pl-0">
                            Educational Qualification
                        </span>
                    </div>
                    <hr class="mt-2 mb-0">
                    <div class="row">
                        <div class="col-12 position-relative my-2" [ngClass]="{'mb-3': (submittedQualForm) && (this.saveEducationForm.get('uploadMarksheet')?.value == null || this.saveEducationForm.get('uploadMarksheet')?.value == undefined || this.saveEducationForm.get('uploadMarksheet')?.value == '')}">
                            <label class="required">Upload Marksheet Image</label>
                            <app-file-upload id="uploadMarksheet" (deletedFile)="deleteAgentFile('QUAL')" (selectedFile)="agentEduSelected($event)" [docObj]="agentEducDocObj" acceptType=".png, .jpg, .jpeg"></app-file-upload>
                            <Span class="text-danger error_span" *ngIf="(submittedQualForm) && (this.saveEducationForm.get('uploadMarksheet')?.value == null || this.saveEducationForm.get('uploadMarksheet')?.value == undefined || this.saveEducationForm.get('uploadMarksheet')?.value == '')">
                                Marksheet image is required.
                            </Span>	
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-6" [ngClass]="{'mb-2': (submittedQualForm) && (this.saveEducationForm.get('qualificationID')?.value == null || this.saveEducationForm.get('qualificationID')?.value == undefined || this.saveEducationForm.get('qualificationID')?.value == '')}">
                            <select name="qualificationID" class="form-select input_"
                                formControlName="qualificationID">
                                <option value="1">10th</option>
                                <option value="2">12th</option>
                                <option value="3">Graduation</option>
                                <option value="4">Post-Graduation</option>
                            </select>
                            <label class="label_ required">Select Education Level</label>
                            <Span class="text-danger error_span" *ngIf="(submittedQualForm) && (this.saveEducationForm.get('qualificationID')?.value == null || this.saveEducationForm.get('qualificationID')?.value == undefined || this.saveEducationForm.get('qualificationID')?.value == '')">
                                Education level is required.
                            </Span>	
                        </div>
                        <div class="col-md-6">
                            <select type="text" id="status2" name="status" placeholder=" " class="form-control input_" formControlName="status"                                        >
                                <option *ngFor="let item of statusList" [value]="item.value">
                                    {{item.text}}
                                </option>
                            </select>
                            <label for="" class="label_ required">Status</label>
                        </div>
                    </div>
                    <hr class="mt-0 mb-2">
                    <div class="row mb-2">
                        <div class="col-12 text-right">
                            <app-custom-button className="btn_outline mr-2" text="Previous" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNextAgentDoc(3,true)"></app-custom-button>
                            <!-- <app-custom-button className=" mr-2" text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNextAgentDoc(5,false, true)"></app-custom-button> -->
                            <app-custom-button text="Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNextAgentDoc(5,false)"></app-custom-button>
                        </div>
                    </div>
                </form>
            </div>
            <div id="bank" class="w-100" [ngClass]="{'d-block': activeAgentDocTab == 5,'d-none' : activeAgentDocTab !=5}">
                <form class="form p-lr-10" novalidate="novalidate" [formGroup]="saveBankDetailForm">
                    <div class="box_header pb-0">
                        <span class="box_title_18 pl-0">
                            Link Bank Account
                        </span>
                    </div>
                    <hr class="mt-2 mb-0">
                    <div class="row">
                        <div class="col-12 position-relative my-2" [ngClass]="{'mb-3': (submittedBankForm) && (!BankDetails['uploadPassbook'].valid)}">
                            <label class="required">Cancelled Cheque / Passbook / Bank Statement</label>
                            <app-file-upload id="uploadMarksheet" (deletedFile)="deleteAgentFile('BANK')" (selectedFile)="agentBankSelected($event)" [docObj]="agentBankDocObj" acceptType=".png, .jpg, .jpeg"></app-file-upload>
                            <Span class="text-danger error_span" *ngIf="(submittedBankForm) && (!BankDetails['uploadPassbook'].valid)">
                                Cancelled Cheque / Passbook / Bank Statement image is required.
                            </Span>	
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-6" [ngClass]="{'mb-2': (BankDetails['accountNumber'].touched || submittedBankForm) && (!BankDetails['accountNumber'].valid)}">
                            <input type="password" name="accountNumber" id="" class="form-control input_" formControlName="accountNumber"  placeholder=" "  (keydown)="validateNumberInput($event)"  (input)="onInput($event)" [minlength]="9" [maxlength]="18">
                            <label for="" class="label_ required">Account Number</label>
                            <span class="text-danger error_span" *ngIf="(BankDetails['accountNumber'].touched || submittedBankForm) && (!BankDetails['accountNumber'].errors?.['required'] && this.saveBankDetailForm?.get('accountNumber')?.value?.length < 9)"> 
                                Account number must have at least 9 digits
                            </span>
                            <span class="text-danger error_span" *ngIf="(BankDetails['accountNumber'].touched || submittedBankForm) && BankDetails['accountNumber'].errors?.['required']"> 
                                Account number is required.
                            </span>
                        </div>

                        <div class="col-md-6" [ngClass]="{'mb-2': (BankDetails['confirmAccountNumber'].touched || submittedBankForm) && (!BankDetails['confirmAccountNumber'].valid || BankDetails['accountNumber'].value!=BankDetails['confirmAccountNumber'].value)}">
                            <input type="number" name="confirmAccountNumber" id="" class="form-control input_" formControlName="confirmAccountNumber" placeholder=" "  (keydown)="validateNumberInput($event)"  (input)="onInput($event)"  >
                            <label for="" class="label_ required">Confirm Account Number</label>
                            <span class="text-danger error_span"*ngIf="( BankDetails['confirmAccountNumber'].touched || submittedBankForm) && BankDetails['accountNumber'].value!=BankDetails['confirmAccountNumber'].value" > Account number must match.</span>
                        </div>

                        <div class="col-md-6" [ngClass]="{'mb-2': (BankDetails['accountHolderName'].touched || submittedBankForm) && (!BankDetails['accountHolderName'].valid)}">
                            <input type="text" name="accountHolderName" id="" class="form-control input_" formControlName="accountHolderName" placeholder=" " (keypress)="alphabetWithSpaces($event)" >
                            <label for="" class="label_ required">Account Holder Name</label>
                            <span class="text-danger error_span" *ngIf="(BankDetails['accountHolderName'].touched || submittedBankForm) && !BankDetails['accountHolderName'].valid"> 
                                Account holder name is required.
                            </span>
                        </div>
                        <div class="col-md-6" [ngClass]="{'mb-2': (BankDetails['ifscCode'].touched || submittedBankForm) && (!BankDetails['ifscCode'].valid)}">
                            <input type="text" name="ifscCode" id="" class="form-control input_" formControlName="ifscCode" placeholder=" " (keypress)="alphaNumericOnly($event)">
                            <label for="" class="label_ required">IFSC Code</label>
                            <span class="text-danger error_span" *ngIf="(BankDetails['ifscCode'].touched || submittedBankForm) && !BankDetails['ifscCode'].valid"> 
                                IFSC code is required.
                            </span>
                        </div>                            
                        <div class="col-md-6" [ngClass]="{'mb-2': (BankDetails['bankName'].touched || submittedBankForm) && (!BankDetails['bankName'].valid)}">                                
                            <input type="text" name="bankName" id="" class="form-control input_" formControlName="bankName" placeholder=" " (keypress)="alphabetWithSpaces($event)">
                            <label for="" class="label_ required">Bank Name</label>
                            <span class="text-danger error_span" *ngIf="(BankDetails['bankName'].touched || submittedBankForm) && !BankDetails['bankName'].valid"> 
                                Bank name is required.
                            </span>
                        </div>                            
                        <div class="col-md-6" [ngClass]="{'mb-2': (BankDetails['branchName'].touched || submittedBankForm) && (!BankDetails['branchName'].valid)}">
                            <input type="text" name="branchName" id="" class="form-control input_" formControlName="branchName" placeholder=" " (keypress)="alphabetWithSpaces($event)">
                            <label for="" class="label_ required">Branch Name</label>
                            <span class="text-danger error_span" *ngIf="(BankDetails['branchName'].touched || submittedBankForm) && !BankDetails['branchName'].valid"> 
                                Branch name is required.
                            </span>
                        </div>
                        <div class="col-md-6">
                            <select type="text" id="status2" name="status" placeholder=" " class="form-control input_" formControlName="status"                                        >
                                <option *ngFor="let item of statusList" [value]="item.value">
                                    {{item.text}}
                                </option>
                            </select>
                            <label for="" class="label_ required">Status</label>
                        </div>
                    </div>
                    <hr class="mt-0 mb-2">
                    <div class="row mb-2">
                        <div class="col-12 text-right">
                            <app-custom-button className="btn_outline mr-2" text="Previous" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNextAgentDoc(4,true)"></app-custom-button>
                            <!-- <app-custom-button className=" mr-2" text="Save & Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNextAgentDoc(6,false, true)"></app-custom-button> -->
                            <app-custom-button text="Next" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveNextAgentDoc(6,false)"></app-custom-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
        <div id="comprehensive" class="tab-pane w-100 pb-3" [ngClass]="{'d-block': activeTab == 4,'d-none' : activeTab !=4}">
            <div class="box_header pb-0">
                <span class="box_title_18">
                    Summary
                </span>
                <app-custom-button text="Edit" [width]="'auto'" [height]="'34px'" leadingIconName="edit" (click)="saveNext(1)">
                    
                </app-custom-button>
            </div>
            <div class="p-lr-10">
                <label class="box_title_18 border_top_grey5 w-100 mb-0 pb-2"></label> 
                <div class="box_container mt-2">
                    <div class="box_header">
                        <label class="box_title_14 border_bottom_grey5 w-100 mb-0 pb-2">1. Representative Details</label>   
                    </div>
                    <div class="row w-100">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    First Name
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{agentFormControl['agentFirstName'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Last Name
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{agentFormControl['agentLastName'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Email
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{agentFormControl['agentEmail'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Mobile Number
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{agentFormControl['agentMobile'].value}}
                                </div>
                            </div>
                        </div>
    
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Date of Birth
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{agentFormControl['agentDOB'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Gender
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{agentFormControl['agentGender'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Language
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{languageText}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Posp Code
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{agentFormControl['pospCode'].value}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>     
                <div class="box_container mt-4">
                    <div class="box_header">
                        <label class="box_title_14 border_bottom_grey5 w-100 mb-0 pb-2">2. Company Details</label>   
                    </div>
                    <div class="row w-100">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Company Name
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{companyDetailsFormControl['companyName'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Company PAN Card Number
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{companyDetailsFormControl['companyPancard'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Company License No
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{companyDetailsFormControl['companyLicenseNo'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Company GSTIN
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{companyDetailsFormControl['companyGSTIN'].value}}
                                </div>
                            </div>
                        </div>
    
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Company Contact No
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{companyDetailsFormControl['companyMobileNo'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Email ID
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{companyDetailsFormControl['companyEmail'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Street Address
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{companyDetailsFormControl['companyStreetAddress'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    State
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{companyDetailsFormControl['companyState'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    City
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{companyDetailsFormControl['companyCity'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Pincode
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{companyDetailsFormControl['companyPincode'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 d-flex mt-2">
                            <span class="d-flex flex-column w-auto pr-2">
                                <img class="img_preview mr-3" src="{{panDocObj.documentBase64}}" title="{{panDocObj.documentName}}">
                                <span class="label_13_grey3">{{panDocObj.documentName}}</span>
                            </span>
                            <span class="d-flex flex-column w-auto px-2">
                                <img class="img_preview mr-3" src="{{gstinDocObj.documentBase64}}" title="{{gstinDocObj.documentName}}">
                                <span class="label_13_grey3">{{gstinDocObj.documentName}}</span>
                            </span>
                        </div> 
                    </div>
                </div>
                <div class="box_container mt-4 pb-2">
                    <div class="box_header">
                        <label class="box_title_14 border_bottom_grey5 w-100 mb-0 pb-2">3. Agent Documents</label>   
                    </div>
                    <div class="row w-100">
                        <div class="col-12 fw-bolder border-bottom font-14">
                            Aadhar Card Details
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Aadhaar Number
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{AdharFormControl['aadhaarNumber'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Gender
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{AdharFormControl['gender'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    FirstName
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{AdharFormControl['firstName'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    LastName
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{AdharFormControl['lastName'].value}}
                                </div>
                            </div>
                        </div>                           
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Address
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{AdharFormControl['address'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Pincode
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{AdharFormControl['pincode'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    City
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{AdharFormControl['city'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    State
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{AdharFormControl['state'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 d-flex mt-2 pt-2">
                            <span class="d-flex flex-column w-auto pr-2">
                                <img class="img_preview mr-3" src="{{aadharFrontDocObj.documentBase64}}" title="{{aadharFrontDocObj.documentName}}">
                                <span class="label_13_grey3">{{aadharFrontDocObj.documentName}}</span>
                            </span>
                            <span class="d-flex flex-column w-auto px-2">
                                <img class="img_preview mr-3" src="{{aadharBackDocObj.documentBase64}}" title="{{aadharBackDocObj.documentName}}">
                                <span class="label_13_grey3">{{aadharBackDocObj.documentName}}</span>
                            </span>
                        </div> 
                    </div>
                    <div class="row w-100 border-top">
                        <div class="col-12 fw-bolder border-bottom font-14 mt-2">
                            PAN Card Details
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    PAN Number
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{PanForm['panNumber'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Name on PAN Card
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{PanForm['panName'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Date of Birth
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{PanForm['dob'].value}}
                                </div>
                            </div>
                        </div>                       
                        <div class="col-md-12 d-flex mt-2">
                            <span class="d-flex flex-column w-auto pr-2">
                                <img class="img_preview mr-3" src="{{agentPanDocObj.documentBase64}}" title="{{agentPanDocObj.documentName}}">
                                <span class="label_13_grey3">{{agentPanDocObj.documentName}}</span>
                            </span>
                        </div> 
                    </div>
                    <div class="row w-100 border-top">
                        <div class="col-12 fw-bolder border-bottom font-14 mt-2">
                            Profile Image
                        </div>                                             
                        <div class="col-md-12 d-flex mt-2">
                            <span class="d-flex flex-column w-auto pr-2">
                                <img class="img_preview mr-3" src="{{agentSelfieDocObj.documentBase64}}" title="{{agentSelfieDocObj.documentName}}">
                                <span class="label_13_grey3">{{agentSelfieDocObj.documentName}}</span>
                            </span>
                        </div> 
                    </div>
                    <div class="row w-100 border-top">
                        <div class="col-12 fw-bolder border-bottom font-14 mt-2">
                            Qualification Details
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Education Level
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{saveEducationForm.value?.qualificationID == 1 ? '10th' : saveEducationForm.value.qualificationID == 2 ? '12th' : saveEducationForm.value.qualificationID == 3 ? 'Graduation' : 'Post-Graduation'}}
                                </div>
                            </div>
                        </div>                                             
                        <div class="col-md-12 d-flex mt-2">
                            <span class="d-flex flex-column w-auto pr-2">
                                <img class="img_preview mr-3" src="{{agentEducDocObj.documentBase64}}" title="{{agentEducDocObj.documentName}}">
                                <span class="label_13_grey3">{{agentEducDocObj.documentName}}</span>
                            </span>
                        </div> 
                    </div>
                    <div class="row w-100 border-top">
                        <div class="col-12 fw-bolder border-bottom font-14 mt-2">
                            Bank Details
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Account Number
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{BankDetails['accountNumber'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Account Holder Name
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{BankDetails['accountHolderName'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    IFSC Code
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{BankDetails['ifscCode'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Bank Name
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{BankDetails['bankName'].value}}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-4 label_13_grey3">
                                    Branch Name
                                </div>
                                <div class="col-8 label_13_grey2">
                                    {{BankDetails['branchName'].value}}
                                </div>
                            </div>
                        </div>                       
                        <div class="col-md-12 d-flex mt-2">
                            <span class="d-flex flex-column w-auto pr-2">
                                <img class="img_preview mr-3" src="{{agentBankDocObj.documentBase64}}" title="{{agentBankDocObj.documentName}}">
                                <span class="label_13_grey3">{{agentBankDocObj.documentName}}</span>
                            </span>
                        </div> 
                    </div>
                </div> 
                <app-custom-button className="mt-3" text="Confirm" [width]="'auto'" [height]="'34px'" leadingIconName="" (click)="saveAgentData()"></app-custom-button>           
            </div> 
    
            
        </div>
    </div>
    </app-mainscreen>