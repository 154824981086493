import { Component, OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import swal from 'sweetalert2';


@Component({
  selector: 'app-reseller',
  templateUrl: './reseller-info.component.html',
  styleUrls: ['./reseller-info.component.css']
})
export class ResellerComponent implements OnInit {
  resellerForm!: FormGroup;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;
  distributorData: any[] = [];
  resellerData: any[] = [];

  get commissionFee(): FormArray {
    return this.resellerForm.get("commissionFee") as FormArray
  }

  newfee(): FormGroup {
    return this.fb.group({
      feeName: '',
      maxValue: '',
    })
  }


  constructor(private adminService: AdminService, private fb: FormBuilder) {
    this.resellerForm = this.fb.group({
      resellerFirstName: ['', [Validators.required]],
      resellerLastName: ['', [Validators.required]],
      resellerMobile: ['', [Validators.required]],
      resellerCountryCode: [],
      resellerEmail: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      resellerStatus: ['', ''],
      referralCode:['',[Validators.minLength(6), Validators.maxLength(10)]],
      distributorId: ['', [Validators.required]],
      resellerId: [0],
      commissionType: [],
      commissionValue: [],
      commissionFrequency: ['1'],
      commissionFee: this.fb.array([])
      
    });
  }
  ngOnInit(): void {
    this.addCommisionFee();
    this.getDistributorData();
    this.getResellerDetails();
    this.generateUniqueString();
  }

  generateUniqueString(){
      let length = 6;
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
      }
      this.resellerForm.get('referralCode')?.setValue(result); 
  }

  onCountryChangeNew(e: any) {
    this.resellerForm.get('resellerMobile')?.setValue("");
  }

  clearData() {
    this.resellerForm.reset();
  }

  async getDistributorData() {
    const res = await this.adminService.DistributorDropDown();
    if (res != null && res.result) {
      this.distributorData = res.result;
    }

  }




  async resellerEdit(resellerId: number) {
    let reseller = this.resellerData.filter((x: { resellerId: any; }) => x.resellerId == resellerId)[0];
    if (reseller.resellerId > 0) {
      this.resellerForm.patchValue(reseller);

    }
  }

  async getResellerDetails() {
    const res = await this.adminService.ResellerDetails(0);
    if (res != null && res.result) {
      this.resellerData = res.result;
    }

  }

  addCommisionFee() {
    this.commissionFee.push(this.newfee());
  }

  removeCommisionFee(i: number) {
    this.commissionFee.removeAt(i);
  }

  async resellerInfo() {
    if (this.resellerForm.get('resellerMobile')?.value != null && typeof (this.resellerForm.get('resellerMobile')?.value) == 'object') {
      let phone = this.resellerForm.get('resellerMobile')?.value;
      this.resellerForm.get('resellerMobile')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
      this.resellerForm.get('resellerCountryCode')?.setValue(phone.dialCode);
    }
    this.resellerForm.value.resellerStatus = true;
    const res = await this.adminService.ResellerInfo(this.resellerForm.value);
    if (res?.isSuccess) {
      let title = this.resellerForm.value.resellerId ? "updated" : "added"
      swal.fire({
        position: 'center',
        icon: 'success',
        title: "reseller data " + title + " successfully",
        showConfirmButton: false,
        timer: 3000,
      });
      this.getResellerDetails();
      this.clearData();
      this.generateUniqueString();
    }



  }




}
