import { Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { USerNotification } from 'src/app/models/SelectItems';
import { AutoCompleteItem } from 'src/app/models/item';

@Component({
  selector: 'app-notification-tooltip',
  templateUrl: './notification-tooltip.component.html',
  styleUrls: ['./notification-tooltip.component.css']
})
export class NotificationTooltipComponent {
  _items: USerNotification[] = [];
  filteredItems: USerNotification[] = [];
  @Input() userInput: string = '';
  selectedIndex: number = -1;
  visibleItems: number = 5; // Number of visible items in the list
  scrollPosition: number = 0; // Scroll position of the list
  itemHeight = 30;
  @Input()
  placeholder!: string;
  @Input() supportingText: boolean = false;
  @Input() showError = false;
  @Input() showErrorRequired = false;
  @Input() showErrorValid = false;
  @Input() labelText!:string;
  @Input() className!:string;
  @Input('items')
  set items(items: USerNotification[]) {
      this._items = items;
  }
  @Output() markedAsRead = new EventEmitter<any>();
  allIds:string='';
  constructor(private renderer: Renderer2,private el: ElementRef) {}

  // Close the autocomplete when clicking outside
  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    if (!this.el.nativeElement.contains(event.target)) {
      this.filteredItems = [];
    }
  }

  onClick() {
    this.filteredItems = this._items;
    this.allIds = this.filteredItems.map((m:USerNotification)=>{return m.id}).toString();
  }

  markAsRead(ids:any,isAll = false){
    if(isAll){
      this.filteredItems = [];
    }
    this.markedAsRead.emit(ids);
  }
}
