<div [style.width]="widthTable" class="box_container mh-350-jcsb {{className}}" >
  <div class="w-100">
    <div class="box_header" [style.background-color]="tableBg" [ngStyle]="{'color' : title == '..' ? tableBg : null }">
      <label class="m-0 p-0 box_title_22" *ngIf="title">
        {{ title }}
      </label>
      <ng-container  *ngIf="canCreate">
        <app-custom-button text="{{buttonTitle}}" [width]="'auto'" [height]="'34px'" leadingIconName="group_add" *ngIf="buttonTitle" (click)="createBtnClick()">
          {{ buttonTitle }}
        </app-custom-button>
      </ng-container>
      <!-- <button mat-button (click)="(buttonClick)" *ngIf="buttonTitle" class="btn btn-primary">
          {{ buttonTitle }}
      </button> -->
      <!-- <ng-container *ngIf="openBal; else buttonTemplate">
        <label class="m-0 p-0" style="font-size: 17px; font-weight: 500">
          {{ openBal }}</label>
      </ng-container> -->    
      <!-- <ng-template #buttonTemplate>
        <button mat-button (click)="(buttonClick)" *ngIf="buttonTitle" class="btn btn-primary">
           <a class="m-0 p-0" style=" text-decoration: none; color: var(--seconday-color); font-size: 17px; font-weight: bold; cursor: pointer;"></a>
            {{ buttonTitle }}
        </button>
      </ng-template> -->
    </div>
    <div class="table_filter" *ngIf="showFilters">
      <div style="width:200px">
        <app-multi-dropdown  class="w-100 multiselect_custom"
            [items]="visibleColumnData" 
            [showSearch]="true" 
            [showAll]="true" 
            [showStatus]="false"
            [showError]="false" 
            placeholder="Column(s)" 
            className="filter-control"
            fontColor="var(--grey3)"
            fontSize="15px"
            (itemChange)="onItemChange($event)">
        </app-multi-dropdown>
      </div>
      <div class="d-flex">
        <app-custom-button text="Export To Excel" [width]="'max-content'" className="mr-2" [height]="'34px'" leadingIconName="" (onClick)="exportToExcel()"></app-custom-button>
        <input type="search" class="filter-control search-icon" placeholder="Search by keyword..." [(ngModel)]="search">
      </div>
    </div>
    <div class="table-wrapper">
      <div class="loader-tbl" *ngIf="showLoader"></div>
      <table class="table-scroll">
        <thead>
          <tr *ngIf="showTableRow">
            <td
              *ngIf="showButtons || showIconButtonStart"
              style="text-align: left"
              [style.background-color]="tableBackground"
              [style.color]="textColor"
            >
              Action
            </td>
      
            <ng-container *ngFor="let i of graphHeader; let even = even; let ind = index">      
              <td *ngIf="visibleColumnsId.includes(ind.toString())"
                style="text-align: center"          
                [style.text-align]="i.type == 'number' || i.type == 'statistics' ? 'center' : 'start'"
                [style.background-color]="tableBackground"
                [style.color]="textColor"
                class="sort_ {{sortOrder}}" [ngClass]="{'active': sortedIndex == ind }"
                (click)="sort(ind)"
              >
              {{ i.key }}
              </td>
            </ng-container>
            <!-- Add the header for actions -->
            <!-- *ngIf="showButtons || showIconButtonEnd" -->
            <td
            *ngIf="filteredData.length > 0 && (canView || canDelete || canEdit)"
              style="text-align: center"
              [style.background-color]="tableBackground"
              [style.color]="textColor"
            >
              Action
            </td>
          </tr>
        </thead>        
        <tbody>
        <!-- <ng-container > -->
          <tr *ngFor="let i of filteredData.slice(((pageIndex-1) * pageSize),(((pageIndex-1) * pageSize)+pageSize))">
            <app-custom-button
              *ngIf="showIconButtonStart"
              text=""
              imageUrl="assets/icons/eye.svg"
              backgroundColor="transparent"
              (click)="handleClickView(i)"
              hoveredBackgroundColor="transparent"
            ></app-custom-button>
      
            <ng-container *ngFor="let j of i; let even = even; let ind = index"  >
              <td [style.background-color]="openBal && even ? '#EEE' : 'inherit'"  *ngIf="visibleColumnsId.includes(ind.toString())">
                {{ j }}        
              </td>
            </ng-container>
            
            <!-- Add buttons for actions -->
      
            <td *ngIf="filteredData.length > 0 && (canView || canDelete || canEdit)" class="text-center">
              <!-- <app-custom-button
                *ngIf="showIconButtonEnd"
                text="{{i[0]}}"
                [width]="'45px'"
                [height]="'45px'"
                leadingIconName="edit"
                class="mr-2"
                (click)="handleClick('Approve', i[0])"
              >
              </app-custom-button> -->
              <span class="material-symbols-outlined mx-1 icon" *ngIf="canView" (click)="handleClick('View', i[0], i[1], i[2])">
                visibility
              </span>
              <span class="material-symbols-outlined mx-1 icon" *ngIf="canEdit" (click)="handleClick('Edit', i[0], i[1], i[2])">
                edit
              </span>
              <span class="material-symbols-outlined mx-1 icon" *ngIf="canDelete" (click)="handleClick('Reject', i[0], i[1], i[2])">
                delete
              </span>
              <span class="material-symbols-outlined mx-1 icon" *ngIf="canDowanload" (click)="handleClick('Download', i[0], i[1], i[2])">
                download
              </span>
            <!-- <div style="width: 10px;"></div>
              <app-custom-button
                *ngIf="showIconButtonEnd"
                text=""
                [width]="'45px'"
                [height]="'45px'"
                class="mr-2"
                className="text-danger"
                leadingIconName="delete"
                (click)="handleClick('Reject', i)"
              ></app-custom-button>
              <div style="width: 10px;"></div>
              <app-custom-button
                *ngIf="!showIconButtonEnd"
                text="View"
                [width]="'80px'"
                [height]="'30px'"
                (click)="handleClick('View', i)"
              ></app-custom-button>
              <div style="width: 10px;"></div>
              <app-custom-button
                *ngIf="showIconButtonEnd"
                text=""
                [width]="'45px'"
                [height]="'45px'"
                leadingIconName="visibility"
                
                (click)="handleClickView(i)"
                hoveredBackgroundColor="transparent"
              ></app-custom-button> -->
            </td>
          </tr>
        <!-- </ng-container> -->
          <!-- <tr *ngIf="openBal"
                  style="background-color: var(--grey3); color: var(--white); font-size: 14px; font-weight: 500;">
      
                  <td colspan="2">Total</td>
      
                  <td colspan="2">Credit: {{totalCredit}}</td>
      
                  <td colspan="2">Debit: {{totalDebit}}</td>
      
                  <td colspan="4">Closing Balance: {{closingBalance}}</td>
      
              </tr>
      
                -->
            <tr
              class="col-12"
              *ngIf="openBal"
              style="
                background-color: var(--grey3);
                color: var(--white);
                font-size: 14px;
                font-weight: 500;
              "  >
              <td class="col-4">Total</td>
              <td class="col-3">Credit: {{ totalCredit }}</td>
              <td class="col-3">Debit: {{ totalDebit }}</td>
              <td class="col-4">Closing Balance: {{ closingBalance }}</td>
            </tr>
        </tbody>
      </table>
    </div>

  </div>
  <app-custom-paginator
  [currentPage]="pageIndex"
  [pageSizes]="pageSizeOptions"
  [totalPages]="getTotalPages()"
  [selectedPageSize] ="pageSize"
  (pageChange)="onPageChange($event)"
  (pageSizeChange)="onPageSizeChange($event)"
  [entriesInfo]="'Showing '+ ((pageIndex < 2 ? (filteredData.length > 0 ? pageIndex : 0) : ((pageIndex-1) * pageSize) + 1)) + ' to ' + (filteredData.length < (pageIndex * pageSize) ? filteredData.length : (pageIndex * pageSize)) + ' of ' + filteredData.length + ' Entries'"
  class="w-100 p-2 border_top_grey3" 
  *ngIf="showPaginator"
  ></app-custom-paginator>
</div>
