import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginLogsComponent } from 'src/app/components/login-logs/login-logs.component';
import { DocumentDetails } from 'src/app/models/Distributor';
import { AdminService } from 'src/app/services/admin.service';

@Component({
  selector: 'app-reseller-view',
  templateUrl: './reseller-view.component.html',
  styleUrls: ['./reseller-view.component.css']
})
export class ResellerViewComponent  implements AfterViewInit {
  activeTab:number=1;
  resellerForm: FormGroup;
  companyForm: FormGroup;
  id:number=0;
  documentsUploadForm:DocumentDetails[]=[];
  @ViewChild(LoginLogsComponent) loginLogsComponent!: LoginLogsComponent;
  constructor(private fb: FormBuilder, private adminService: AdminService,private router:Router){
    let obj = this.router.getCurrentNavigation()?.extras?.state;
    if(obj)
    {
      this.id = parseInt(obj['id']);
      this.getFormData(this.id);
    }
    this.resellerForm = this.fb.group({
      "resellerFirstName": ['', [Validators.required]],
      "resellerLastName": ['', [Validators.required]],
      "resellerEmail": ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      "resellerMobile": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      "resellerCountryCode": ['91', [Validators.required]],
      "resellerStatus": ['1', [Validators.required]],
      "resellerId": ['0'],
      "resellerDOB": ['', [Validators.required]],
      "resellerGender": ['', [Validators.required]],
      "userId": ['0'],
      "lastLogin":['']
    });

    this.companyForm = this.fb.group({
      "companyId": ['0'],
      "companyName": ['', [Validators.required]],
      "companyPancard": ['', [Validators.required,Validators.pattern('[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}')]],
      "companyGSTIN": ['', [Validators.required]],
      "companyLicenseNo": ['', [Validators.required]],
      "companyMobileNo": ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      "companyCountryCode": ['91', [Validators.required]],
      "companyEmail": ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      "companyStreetAddress": ['', [Validators.required]],      
      "companyPincode": ['', [Validators.required,Validators.pattern('[0-9]{6}')]],
      "companyStateId": [''],
      "companyState": ['', [Validators.required]],
      "companyCityId": [''],
      "companyCity": ['', [Validators.required]],
      "companyStatus": ['1', [Validators.required]],
      "userId": ['0']
    });
  }
  get resellerFormControl() {
    return this.resellerForm.controls;
  }
  get companyDetailsFormControl() {
    return this.companyForm.controls;
  }
  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    if(this.resellerFormControl['userId'].value > 0){
      this.loginLogsComponent?.getLoginLogs(this.resellerFormControl['userId'].value,'');//'4CBC43DAC4F4470BA73D3CF7C535B65C'); //this.generalService.getUniqueuserid());
    }
  }

 async  getFormData(id:number)
  {
    const res = await this.adminService.GetResellerById(id);  
      if (res != null && res.result) {

        this.resellerForm.patchValue(res.result);
        this.companyForm.patchValue(res.result.companyDetails);
        this.documentsUploadForm = res.result.userDocuments;
        this.loginLogsComponent?.getLoginLogs(this.resellerFormControl['userId'].value,'');//'4CBC43DAC4F4470BA73D3CF7C535B65C'); //this.generalService.getUniqueuserid());
      }    
      else
      {
        // swal.fire({
        //   position: 'center',
        //   icon: 'error',
        //   title: 'Something went wrong',
        //   showConfirmButton: false,
        //   timer: 3000,
        // });
      } 
  }
  changeTab(tab:number)
  {
    this.activeTab = tab;
  }

  backBtClick(){
    this.router.navigateByUrl('/reseller-edit',{ state: { id:this.id }});
  }
}
