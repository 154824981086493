import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../shared/AppConfig';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DistributorService {
  protected _appset = AppConfig.settings;
  constructor(private http: HttpClient) { }

  async DistributorInfo(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}distributor/DistributorInfo`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });

  }

  async ResellerInfo(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}reseller/ResellerInfo`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });

  }

  async GetInsurancePartners() {
    return this.http.get<ApiResponse>(`${environment.APIUrl}distributor/GetInsurancePartners`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async ManageDistributorSettings(data: any) {
    return this.http.post<ApiResponse>(`${environment.APIUrl}distributor/ManageDistributorSettings`, data, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

  async GetDistributorSettings(distributorId: number) {
    return this.http.get<ApiResponse>(`${environment.APIUrl}distributor/GetDistributorSettings?distributorId=${distributorId}`, {
      headers: new HttpHeaders()
        .set('Content-Type', 'application/json')
    }).toPromise().catch(async (errorRes: any) => {
      return errorRes;
    });
  }

}
