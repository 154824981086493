<div class="row">
    <div class="col-md-6">
        <div class="file-drop" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)" (drop)="onDrop($event)">
            <img src="/assets/svgIcon/cloud.svg" class="" />
            <label class="custom-file-dnd {{LableclassName}}">Drop file here or </label> 
            <input type="file" id="{{id}}" class="file_uploader" (change)="onFileSelected($event)" accept="{{acceptType}}" #fileInput multiple>
        </div>
    </div>
    <div class="col-md-6">
        <div class="file_preview" *ngIf="(docObj != null && docObj != undefined && docObj.documentBase64 != '')">
            <div class="d-flex align-items-center">
                <img class="img_preview mr-3" src="{{docObj.documentBase64}}">
                {{ docObj.documentName }}                
                <a *ngIf="docObj.documentBase64 != null && docObj.documentBase64 != undefined && docObj.documentBase64 != ''" class="cursor-pointer d-flex text-decoration-none" target="_blank" [href]="docObj.documentBase64" download="{{docObj.documentDownloadName}}">
                    <span class="material-symbols-outlined ml-2 icon cursor-pointer text-grey1" title="download file">
                        download
                      </span>
                </a>
            </div>
            <span class="material-symbols-outlined mr-2 icon cursor-pointer text-danger" title="Remove file" (click)="removeDocument(docObj.documentTypeId??0)">
                delete
              </span>
        </div>
    </div>
</div>