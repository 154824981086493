import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.css']
})
export class LogsComponent {
  constructor (public router: Router) {}

  backBtClick(){
    this.router.navigateByUrl('/distributor-edit');
  }
}
